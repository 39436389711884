<template>
  <header>
    <el-row class="tac">
      <el-col :span="12">
        <div class="cehiu"></div>
        <img :src='`https://btmswaterbg.sdbina.com/api/v1/projectLogo?token=${token}`' alt="">
        <el-menu
        active-text-color="#fff"
        unique-opened
        router
        :default-active="activePath"
        >
        <el-menu-item :index="item.path" v-for="item in noChildren"
        :key="item.id" @click="saveNavState('/' + item.path)">
        <template>
        <i :class="item.icon" style="margin-right: 20px;"></i>
        <span slot="title" style="font-size: 1rem;">{{item.authName}}</span>
        <!-- <i class="iconfont icon-dian" style="margin-left: 0px" v-show="icons"></i> -->
        </template>
      </el-menu-item>
        <el-submenu :index="'/' + item.path" v-for=" item in hasChilren "
         :key="item.id">
            <template slot="title">
              <i :class="item.icon" style="margin-right: 20px;"></i>
              <span style="font-size: 1rem;">{{item.authName}}</span>
              <!-- <i class="iconfont icon-dian" style="margin-left: 40px" v-show="icons"></i> -->
            </template>
          <!-- 二级菜单 -->
            <el-menu-item :index="'/' + subItem.path" v-for="subItem in item.childern"
            :key="subItem.id" @click="saveNavState('/' + item.path)">
              <template>
                <!-- 图表 -->
                <i class=""></i>
                <!-- 文本 -->
                <span style="letter-spacing: 1px;">{{subItem.authName}}</span>
                <!-- <i class="iconfont icon-dian" style="margin-left: 40px" v-show="icons"></i> -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </header>
</template>
<script>
export default {
  data () {
    return {
      activePath: '',
      // 左侧点的隐藏和显示
      // icons: false,
      token: null,
      // 左侧按钮
      menulist: [
        {
          id: 1,
          icon: 'iconfont icon-xiangmushouye1',
          authName: '项目首页',
          path: 'users'
        },
        {
          id: 4,
          icon: 'iconfont icon-caiwubaobiao',
          authName: '账务报表',
          path: 'h',
          childern: [
            {
              id: 301,
              authName: '充值记录',
              path: 'details'
            },
            {
              id: 302,
              authName: '账单流水',
              path: 'summary'
            },
            {
              id: 303,
              authName: '售水日报',
              path: 'sshuiyuebao'
            },
            {
              id: 304,
              authName: '售水月报',
              path: 'sshuiyuebaos'
            },
            {
              id: 305,
              authName: '售水年报',
              path: 'ssuinianbao'
            }
          ]
        },
        {
          id: 201,
          icon: 'iconfont icon-caiwuguanli',
          authName: '交款退款',
          path: 'accountMent'
        },
        {
          id: 2,
          icon: 'iconfont icon-jichuziliao',
          authName: '基础资料',
          path: 'a',
          childern: [
            {
              id: 101,
              authName: '用水类型',
              path: 'waterType'
            },
            {
              id: 102,
              authName: '位置信息',
              path: 'positionTion'
            },
            {
              id: 106,
              authName: '表具类型',
              path: 'Gaugetype'
            },
            {
              id: 103,
              authName: '水表档案',
              path: 'waterMeterFile'
            },
            {
              id: 105,
              authName: '阀控策略',
              path: 'strategy'
            }
          ]
        },
        {
          id: 901,
          icon: 'iconfont icon-shuibiaoguanli',
          authName: '抄收与设置',
          path: 'Parameter'
        },
        {
          id: 7,
          icon: 'iconfont icon-xitongguanli',
          authName: '系统管理',
          path: 'e',
          childern: [
            {
              id: 601,
              authName: '账户管理',
              path: 'userGement'
            },
            {
              id: 602,
              authName: '全局配置',
              path: 'globalRation'
            },
            {
              id: 603,
              authName: '预警推送',
              path: 'Alertpush'
            }
            // {
            //   id: 502,
            //   authName: '历史信息',
            //   path: 'dailyMption'
            // }
          ]
        }
      ]
    }
  },
  created () {
    this.token = this.$store.state.print.goodsForeNo.token
    this.setCurrentRoute()
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  watch: {
    $route () {
      this.setCurrentRoute()
    }
  },
  methods: {
    // 保存链接的激活状态
    saveNavState (activePath) {
      console.log('dwdhwdh')
      this.show = !this.show
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    setCurrentRoute () {
      this.defaultActive = this.$route.name
    }
  },
  computed: {
    noChildren () {
      return this.menulist.filter((item) => !item.childern)
    },
    hasChilren () {
      return this.menulist.filter((item) => item.childern)
    }
  }
}
</script>
<style lang="less" >
.el-col{
  .cehiu{
      width: 5px;
      height: 100%;
      background-color: #987AFF;
      position: fixed;
      left: 15%;
      z-index: 9999;
    }
    img{
      width: 60%;
      height: 100px;
      margin-top: 35px;
    }
  .el-menu {
  width: 100%;
  background-color: #F5F9FC;
  color: #656d92;
  cursor: pointer;
  border: 0;
  .el-submenu{
    .el-menu-item{
      padding: 0;
      .span{
        font-size: 1rem;
      }
    }
  }
  .iconfont.icon-caiwubaobiao{
    font-size: 26px;
  }
  .iconfont.icon-xiangmushouye1{
    font-size: 22px;
  }
}
}
.el-row{
  .el-col{
  width: 99%;
}
}
.icd{
  width: 25px;
  height: 24px;
  box-shadow: 0px 2px 2px 0px rgba(26, 25, 26, 0.5);
  border-radius: 4px;
  border: solid 1px #e9e9e9;
}
// .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
//   display: none;
// }
// .el-submenu>.el-submenu__title .el-submenu__icon-arrow{
//   display: none;
// }
.el-menu-item.is-active {
      background-color: #ECF0F1 !important;
      border-radius: 0px 10px 10px 0px;
      color: #fff;
      span {
        color: #987AFF !important;
      }
      i{color: #fff;}
}

</style>
