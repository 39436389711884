<template>
  <div class="T">
    <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>用户档案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input placeholder="请输入关键字" v-model.trim="cxyts.search_content" size="small"></el-input>
        </el-form-item>
        <el-form-item label="小区:" style="margin-left: 30px">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:" style="margin-left: 30px">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:" style="margin-left: 30px">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:" style="margin-left: 30px">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
        <el-checkbox v-model="cxyts.insufficient_balance">余额不足</el-checkbox>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button type="primary" @click="pay_search" size="small">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="shuaxin" size="small">重 置</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 30px">
          <el-button type="primary" @click="userAss" size="small">换表记录</el-button>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="userAdd" size="small">添加信息</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 内容区域 -->
    <div class="navto">
      <div class="num" v-for="item in parData " :key='item.id'>
        <div @click="userDer(item)">
        <div class="num1">
          <img src="@/views/basicData/userProfile/img/yhu.png" alt="">
          <span>{{item.pay_id}}</span>
          <!-- <el-button type="primary" size="mini">上线</el-button> -->
          <el-button type="primary" size="mini">{{item.meter_id === null  ? '未绑定' :item.is_use !== null ? '已绑定' : '已失效'}}</el-button>
        </div>
        <div class="numsop">
          <span>{{item.name}}</span>
        </div>
        <div class="num2">
          <div class="num-1">
            <i class="iconfont icon-dizhi"></i>
            <span class="nui"></span><span>{{item.address}}</span>
          </div>
          <div class="nummane">
            <span>余额:</span>&nbsp;
            <span style="color: #744dfe">{{item.balance}}</span>&nbsp;
            <span>元</span>
          </div>
        </div>
        </div>
        <div class="numbtn"></div>
          <div class="numbtns">
          <el-button plain @click="wartBin(item)" ref="Bion" v-show="item.meter_id == null">绑定水表</el-button>
          <el-button disabled class="btnbang" v-show="item.meter_id !== null">绑定水表</el-button>
          <el-button plain @click="open(item)" v-show="item.meter_id !== null">解绑水表</el-button>
          <el-button disabled class="btnbang" v-show="item.meter_id == null">解绑水表</el-button>
          <el-button plain @click="binDing(item)" v-show="item.meter_id !== null">换表</el-button>
          <el-button disabled class="btnbang" v-show="item.meter_id == null" >换表</el-button>
        </div>
      </div>

    </div>
    <!-- 分页 -->
    <div class="feye">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>

    <!-- 暂无数据 -->
    <div class="wuju" v-show="zanwu">暂无数据</div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      // 暂无数据
      zanwu: false,
      // 添加
      bushuData: {
        address: null,
        location: null,
        meter: null,
        name: null,
        pay_id: null,
        phone: null
      },
      // 主体数据
      parData: [{
        address: null,
        balance: null,
        meter_id: null,
        meter_status: null,
        meter_type: null,
        name: null,
        pay_id: null,
        phone: null,
        water_type: null
      }],
      famlis: {
        project_id: null,
        token: null,
        pagesize: 20,
        pagenum: 1,
        search_content: null,
        location_id: null,
        insufficient_balance: null
      },
      total: null,
      userBmfi: {
        project_id: null,
        token: null,
        pay_id: null
      },
      project_id: null,
      userfima: {
        project_id: null,
        pay_id: null,
        token: null,
        meter_id: 0
      },
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      zaisns: [{
        id: 1,
        name: null
      }],
      cxyts: {
        project_id: null,
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null,
        insufficient_balance: null,
        room_id: null
      }
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.userBmfi.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userBmfi.token = this.$store.state.print.goodsForeNo.token
    this.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userData()
    // console.log(this.$refs)
    console.log(this.$route.query.obj, '添加obj')
    this.bushuData = this.$route.query.obj
    this.parData = this.bushuData
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
  },
  methods: {
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
      // }
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.cxyts.room_id = null
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    // 查询
    pay_search () {
      this.cxyts.project_id = this.$store.state.print.goodsForeNo.project_id
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.Czhixios(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 绑定
    wartBin (item) {
      console.log(item, 'row')
      this.$router.push({ path: '/userBin', query: { project_id: this.project_id, obj: item } })
    },
    // 换表
    binDing (item) {
      console.log(item, '换表')
      this.$router.push({ path: '/userBinDing', query: { project_id: this.project_id, obj: item } })
    },
    userAss () {
      console.log('换表记录')
      this.$router.push('/tableRecord')
    },
    userAdd () {
      console.log('添加')
      this.$router.push('/userAdd')
    },
    // 主体数据userProfile.userData
    userData () {
      this.$api.waterMeter.meterdatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.parData = res.data.data
          this.total = res.data.total
          if (this.total === 0) {
            this.zanwu = true
          }
        }
      })
    },
    // 跳转详情
    userDer (item) {
      console.log(item, '详情')
      this.$router.push({ path: '/userDetails', query: { project_id: this.project_id, obj: item } })
    },
    // 解绑
    open (item) {
      console.log(item, 'item')
      this.userfima.pay_id = item.pay_id
      this.userfima.project_id = this.$store.state.print.goodsForeNo.project_id
      this.userfima.token = this.$store.state.print.goodsForeNo.token
      this.$confirm('此操作将永久解除绑定此水表, 是否继续?', '解绑水表', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.userProfile.userBdifis(this.userfima).then((res) => {
          console.log(res)
        })
        this.$message({
          type: 'success',
          message: '解绑成功!'
        })
        this.userData()
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解绑'
        })
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.famlis.insufficient_balance = this.cxyts.insufficient_balance
      this.userData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.famlis.insufficient_balance = this.cxyts.insufficient_balance
      this.userData()
    }
  }
}

</script>

<style lang="less" scoped>
.T{
    padding: 0;
}
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  // width: 900px;
  padding: 20px 0px 0px 74px;
  display: flex;
  // border: 1px solid #474747;
  .el-form {
    display: flex;
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    .el-form-item{
      margin-bottom: 9px;
      display: flex;
      .ilo{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .ili{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-select{
      width: 150px;
    }
    }
  }
}
// 主体区域
.navto {
display: flex;
flex-wrap: wrap;
padding: 0px 0px 50px 57px;
.num {
    margin-top: 13px;
    margin-left: 17px;
    width: 368px;
    height: 253px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 1px rgba(116, 77, 254, 0.5);
    border-radius: 7px;
    border: solid 2px #c6c6c6;
    line-height: 31px;
  .iconfont {
    font-size: 30px;
    color: #744dfe;
  }
  .num1 {
      padding: 10px 10px;
      display: flex;
      align-items: center;
    span {
      margin-left: 20px;
      font-family: PingFangSC-Heavy;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 31px;
      letter-spacing: 2px;
      color: #474747;
    }
    .el-button {
      float: right;
      margin-left: 30px;
      background-color: #F5F5F5;
      color: #9FA2AE;
      border: 1px solid #DDD5FC;
    }
  }
  .numsop{
    font-family: PingFangSC-Heavy;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 2px;
    color: #656d92;
    padding-left: 60px;
  }
  .num2 {
    .num-1{
        padding: 0px 10px;
        .nui{
          margin-left: 19px;
        }
        span{
          font-family: MiSans-Normal;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 2px;
          color: #656d92;
        }
    }
    .nummane{
      line-height: 50px;
      padding-left: 60px;
      font-family: MiSans-Normal;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 2px;
      color: #656d92;
    }
    .num-2{
        text-align: center;
        padding: 10px 0px;
        color: #9194AF;
    }
    .num-3{
        padding: 3px 0px;
        span{
            padding: 0px 45px;
        }
    }
  }
  .numbtn{
      margin-top: 15px;
         border-top: 1px solid #DED6FF;
         padding: 5 px 0px;
         display: flex;
         justify-content: space-between;
     }
     .numbtns{
       margin-top: 10px;
        display: flex;
         justify-content: space-between;
       .el-button{
             width: 33%;
             border: none;
             border-right: 1px solid #DED6FF;
             font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 2px;
            // color: #474747;
         }
        .btnbang{
          background-color: #909399;
          color: #fff;
        }
         .el-button:nth-child(3){
             border-right: none;
         }
     }
}
}
// 分页
.feye{
  background-color: rgb(247, 245, 245);
  width: 1542px;
  height: 40px;
  position: fixed;
  right: 20px;
  bottom: 0;
}
// 暂无数据
.wuju{
  margin-left: 40%;
  margin-top: 15%;
}
</style>
