<template>
<div>
  <!-- 面包屑 -->
  <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userProfile' }">用户档案</el-breadcrumb-item>
        <el-breadcrumb-item>档案详情</el-breadcrumb-item>
      </el-breadcrumb>
  </div>

  <!-- 主体外层盒子 -->
  <div class="flima">
    <!-- 左边 -->
    <div class="fam1">
      <!-- 档案详情文字 -->
      <div class="famtext">
        <div class="famtexts">档案详情</div>
        <div class="famtextss">
          <span>水表编号</span>
          <div class="famdi"><span>{{userfamDrte.meter_id}}</span></div>
        </div>
      </div>
      <!-- 详情信息 -->
      <div class="famation">
        <div class="ation-1">详情信息</div>
        <!-- 内容 -->
        <div class="ation-2">
          <!-- 用水类型div -->
          <div class="ations">
            <div class="ationsetms">
              <span>缴费编号:</span>&nbsp;&nbsp;
              <span>{{userfamDrte.pay_id}}</span>
            </div>
            <div class="ationsetms">
            &nbsp;&nbsp;&nbsp; <span>用户名:</span>&nbsp;&nbsp;
              <span>{{userfamDrte.name}}</span>
            </div>
            <div class="ationsetms">
              <span>联系方式:</span>&nbsp;&nbsp;
              <span>{{userfamDrte.phone}}</span>
            </div>
            <div class="ationsetms">
              <span>位置信息:</span>&nbsp;
              <span>{{userfamDrte.address}}</span>
            </div>
            <div class="ationsetms">
                <span>水表编号:</span>&nbsp;&nbsp;
                <span>{{userfamDrte.meter_id}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button size="mini" @click="fileDeta">详 情</el-button>
            </div>
            <div class="ationsetms">
              <span>账户余额:</span>&nbsp;&nbsp;
              <span style="color: #987AFF">{{userfamDrte.balance}}</span>&nbsp;&nbsp;
              <span>元</span>
            </div>
            <div class="ationsetms">
              <span>用户状态:</span>&nbsp;&nbsp;
              <span>{{userfamDrte.active ? '启用' : '停用'}}</span>
            </div>
          </div>
          <!-- 线 -->
          <div class="ationx"/>
          <!-- 保存 -->
          <div class="ationxb">
             <el-button size="mini" @click="nabcx" ><i class="el-icon-edit-outline" />修 改</el-button>
            <el-button size="mini" @click="qiytiy" ><i class="el-icon-edit-outline" />{{userfamDrte.active ? '停用' : '启用'}}</el-button>
            <!-- <el-switch v-model="userfamDrte.active" active-color="#13ce66" inactive-color="#ff4949" change="qiytiy"></el-switch>{{userfamDrte.active}} -->
          </div>
        </div>
      </div>
    </div>

    <!-- 右边 -->
    <div class="fam2">
       <div class="ilo" @click="userAss">
                <span>返回</span>
                <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
            </div>
      <div class="famont">
        <!-- 点击切换 -->
        <div class="fambtn">
          <span style="cursor:pointer; color: #987AFF" @click="Rec" ref="meting">充值记录</span>
          <span style="margin-left: 20px; color: #494949; cursor:pointer" @click="Ref" ref="offvale">退款记录</span>
          <span style="margin-left: 20px; color: #494949; cursor:pointer" @click="Rey" ref="ttuuy">扣款记录</span>
        </div>
        <!-- table数据 -->
        <div class="tab">
          <Recs v-show="Rect"/>
          <Refs v-show="Reft"/>
          <Reys v-show="Reyt" />
        </div>
      </div>
    </div>
  </div>
  <!-- 修改 -->
  <div class="userDletas">
  <el-dialog
  title="修改"
  :visible.sync="dialogVisible"
  width="27%">
  <el-form :v-model="userfaPsos">
    <el-form-item label="用户名:">
      <el-input v-model.trim="userfaPsos.name" size="small" placeholder="请输入姓名"></el-input>
    </el-form-item>
    <el-form-item label="联系方式:" style="margin-left: 20px;">
      <el-input v-model.trim="userfaPsos.phone" size="small" placeholder="请输入联系方式"></el-input>
    </el-form-item>
    <el-form-item label="小区:" style="margin-left: 15px;">
      <el-select v-model="userfaPsos.quarters_id" placeholder="请选择小区" size="small"
      @change="selectQuarters(userfaPsos.quarters_id)">
        <el-option
          v-for="item in quarters"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="楼栋:"  style="margin-left: 45px;">
      <el-select v-model="userfaPsos.Buildings_id" placeholder="请选择小区" size="small"
      @change="selectBuildings(userfaPsos.Buildings_id)">
        <el-option
          v-for="item in Buildings"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="单元:" style="margin-left: 15px;">
      <el-select v-model="userfaPsos.units_id" placeholder="请选择小区" size="small"
      @change="selectunit(userfaPsos.units_id)">
        <el-option
          v-for="item in units"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="房间:" style="margin-left: 45px;">
      <el-select v-model="userfaPsos.room_id" placeholder="请选择小区" size="small"
      @change="selectroom(userfaPsos.room_id)">
        <el-option
          v-for="item in rooms"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogQueDing">确 定</el-button>
  </span>
</el-dialog>
</div>
  </div>
</template>
<script>
import Recs from '@/views/basicData/userProfile/recs.vue'
import Refs from '@/views/basicData/userProfile/refs.vue'
import Reys from '@/views/basicData/userProfile/reys.vue'
export default {
  components: { Recs, Refs, Reys },
  inject: ['reload'],
  data () {
    return {
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      rooms: [{
        id: null,
        name: '123'
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      // ----------------------
      dialogVisible: false,
      userfamDrte: {
        active: null,
        Buildings_id: null,
        name: null,
        phone: null,
        room_id: null
      },
      actives: null,
      // 充值记录
      Rect: true,
      // 退款记录
      Reft: false,
      // 扣款记录
      Reyt: false,
      // 修改
      userfaPsos: {
        project_id: null,
        token: null,
        pay_id: null,
        name: null,
        address: null,
        phone: null,
        active: null,
        room_id: null
      },
      famlis: {
        project_id: null,
        token: null
      },
      recsPayid: null,
      meterid: null,
      project_id: null,
      userDleate: {
        project_id: null,
        token: null,
        pay_id: null
      },
      metlis: {
        project_id: null,
        token: null,
        meter_id: null
      },
      meteData: {}
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    // console.log(this.$router.currentRoute.query.obj, 'obj')
    this.userfamDrte = this.$router.currentRoute.query.obj
    this.recsPayid = this.$router.currentRoute.query.obj.pay_id
    this.meterid = this.$router.currentRoute.query.obj.meter_id
    this.project_id = this.$store.state.print.goodsForeNo.project_id
    console.log(this.userfamDrte, 'userfamDrte')
    this.fanam()
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
  },
  methods: {
    fanam () {
      console.log(this.userfamDrte.meter_id, '123')
      this.metlis.project_id = this.$store.state.print.goodsForeNo.project_id
      this.metlis.token = this.$store.state.print.goodsForeNo.token
      this.metlis.meter_id = this.userfamDrte.meter_id
      this.$api.waterMeter.meterdatas(this.metlis).then((res) => {
        console.log(res, 'dw')
        this.meteData = res.data.data
        console.log(this.meteData)
      })
    },
    userAss () {
      this.$router.push('/userProfile')
    },
    //   详情按钮跳转
    fileDeta () {
      this.$router.push({ path: '/fileDetails', query: { project_id: this.project_id, obj: this.meteData } })
    },
    // 启用停用按钮
    qiytiy () {
      this.userfamDrte.active = !this.userfamDrte.active
      this.userfaPsos.project_id = this.$store.state.print.goodsForeNo.project_id
      this.userfaPsos.pay_id = this.userfamDrte.pay_id
      this.userfaPsos.token = this.$store.state.print.goodsForeNo.token
      this.userfaPsos.active = this.userfamDrte.active
      this.$api.userProfile.waterXiugai(this.userfaPsos).then((res) => {
        if (res.data.status === 'success') {
          this.userfamDrte.active = res.data.data.active
          console.log(this.userfamDrte.active, ' this.userfamDrte.active')
        }
      })
    },
    // 修改小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.userfaPsos.Buildings_id = null
      this.userfaPsos.room_id = null
      this.userfaPsos.units_id = null
      this.Building()
      this.room()
      this.unit()
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.userfaPsos.room_id = null
      this.userfaPsos.units_id = null
      this.unit()
      this.room()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.userfaPsos.room_id = null
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.rooms = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, 'val4')
      this.userfaPsos.room_id = val
      this.room()
    },
    //   修改
    nabcx () {
      this.dialogVisible = true
    },
    // 修改确定
    dialogQueDing () {
      this.userfaPsos.project_id = this.$store.state.print.goodsForeNo.project_id
      this.userfaPsos.pay_id = this.userfamDrte.pay_id
      this.userfaPsos.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.waterXiugai(this.userfaPsos).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.userfamDrte = res.data.data
          this.dialogVisible = false
          this.fanam()
          this.$notify({
            title: '成功',
            message: '修改信息成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 充值记录
    Rec () {
      this.$refs.meting.style.color = '#987AFF'
      this.$refs.offvale.style.color = '#494949'
      this.$refs.ttuuy.style.color = '#494949'
      this.Rect = true
      this.Reft = false
      this.Reyt = false
    },
    // 退费记录
    Ref () {
      this.$refs.offvale.style.color = '#987AFF'
      this.$refs.meting.style.color = '#494949'
      this.$refs.ttuuy.style.color = '#494949'
      this.Reft = true
      this.Rect = false
      this.Reyt = false
    },
    // 扣款记录
    Rey () {
      this.$refs.ttuuy.style.color = '#987AFF'
      this.$refs.meting.style.color = '#494949'
      this.$refs.offvale.style.color = '#494949'
      this.Reyt = true
      this.Reft = false
      this.Rect = false
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 主体外层盒子
.flima{
  display: flex;

  // 左边
  .fam1{
    width: 558px;
    height: 790px;

    // 搜索
    .suso {
      padding: 25px 0px 0px 74px;
      display: flex;
      .el-form {
        display: flex;
        .el-input {
          width: 300px;
        }
        .el-button {
          margin-left: 10px;
          background-color: #744dfe;
          border: 0px;
        }
        .el-form-item{
          margin-bottom: 9px;
          .ili{
          cursor: pointer;
          margin-left: 1057px;
          width: 102px;
          height: 33px;
          border-radius: 4px;
          border: solid 1px #977aff;
          font-size: 22px;
          color: #656d92;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        }
      }
    }

    // 档案详情文字
    .famtext{
      padding: 20px 0px 0px 74px;
      // width: 208px;
      // height: 75px;
      font-family: MiSans-Medium;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 60px;
      letter-spacing: 1px;
      color: #494949;
      // border: 1px solid;
      .famtexts{
        font-family: MiSans-Medium;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #494949;
      }
      .famtextss{
        display: flex;
        font-family: MiSans-Medium;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 1px;
        color: #494949;
        .famdi{
          margin-top: 10px;
          margin-left: 37px;
          width: 198px;
          height: 34px;
          font-size: 18px;
          font-family: PingFangSC-Heavy;
          background-color: #977aff;
          display: flex;
          justify-content: center;
          align-items: center;
          .span{
            font-family: PingFangSC-Heavy;
            font-weight: bold;
            font-stretch: normal;
            line-height: 9px;
            letter-spacing: 1px;
            color: #474747;
          }
        }
      }
    }

    // 详情信息
    .famation{
      padding: 36px 0px 0px 74px;
      .ation-1{
        margin-bottom: 30px;
        font-family: MiSans-Normal;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 69px;
        letter-spacing: 1px;
        color: #494949;
      }

      .ation-2{
        width: 358px;
      height: 400px;
      border-radius: 8px;
      border: solid 2px #977aff;
      // 主
        .ations{
          margin: 25px 0px 0px 30px;
          // width: 301px;
          // height: 236px;
          font-family: MiSans-Normal;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 1px;
          color: #494949;
          // border: 1px solid;
          .ationsetms{
            display: flex;
            .el-button{
                height: 30px;
                border: 1px solid #b6a2fe;
                background-color: #ebebeb;
                color: #9699a9;
            }
            .ations-sele{
              .el-select{
                width: 150px;
              }
            }
          }
       }
      //  线
        .ationx{
         margin: 18px 0px 0px 32px;
         width: 278px;
        height: 2px;
        background-color: #cabbff;
       }
      //  注
       .ationxz{
        margin: 10px 0px 0px 32px;
        width: 106px;
        height: 36px;
        font-family: MiSans-Normal;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #7b7b7b;
       }
       // 保存
       .ationxb{
         margin-top: 20px;
         .el-button{
           float: right;
           margin-right: 50px;
           box-shadow: 2px 1px 3px 0px rgba(26, 25, 26, 0.5);
           border-radius: 5px;
           border: solid 1px #744dfe;
           color: #515151;
           i{
             margin-right: 10px;
           }
         }
         .el-button:nth-child(3){
           background-color: #656d92;
           color: #fff;
         }
       }
      }
    }
  }

  // 右边
  .fam2{
    width: 1088px;
    height: 790px;
    .ilo{
      margin-top: 20px;
          cursor: pointer;
            margin-left: 900px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 27px;
        height: 23px;
      }
    }
    // 里面盒子
    .famont{
      margin: 20px 0px 0px 70px;
      width: 959px;
      height: 700px;
      // 按钮切换
      .fambtn{
        padding-left: 20px;
        font-family: MiSans-Medium;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #8362fe;
      }
      // table数据
      .tab{
        padding: 30px 0px 0px 0px;
    }
    }
  }
}
// 删除
.userDletas{
  .el-dialog__body{
    padding: 0px;
  }
  .el-form{
    display: flex;
    flex-wrap: wrap;
    .el-form-item{
      display: flex;
      .el-input{
      width: 160px;
      }
      .el-select{
        width: 160px;
      }
    }
  }
}
</style>
