<template>
  <div>
    <!-- 解绑水表 -->
    <div class="numf">
      <el-form v-model="LadderData" label-width="80px" class="demo-form-inline">
        <el-form-item
          label="表具编码 : "
          prop="meter_id"
        >
          <el-input
            v-model.trim="LadderData.meter_id"
            size="small"
            :disabled="true"
            placeholder="请输入表具编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="型号 : ">
          <el-select
            v-model="LadderData.meter_type_id"
            placeholder="请选择用水类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in meteData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用水类型 : ">
          <el-select
            v-model="LadderData.water_type_id"
            placeholder="请选择用水类型"
            clearable
            size="small"
          >
            <el-option
              v-for="item in withdown"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="水表状态 : " >
          <el-select
            v-model="LadderData.is_use"
            placeholder="请选择水表状态"
            clearable
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input
            v-model="LadderData.username"
            placeholder="请输入姓名"
            size="small"
          ></el-input> </el-form-item
        >
        <el-form-item label="联系电话:">
          <el-input
            v-model.trim="LadderData.phone"
            placeholder="请输入电话"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件号码:">
          <el-input
            v-model.trim="LadderData.card_number"
            placeholder="请输入证件号码"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item>
        </el-form-item>
        <div class="ioxo">
          <el-form-item label="小区:">
            <el-select
              v-model="LadderData.quarters_id"
              placeholder="请选择小区"
              size="small"
              @change="selectQuarters(LadderData.quarters_id)"
            >
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button size="mini" icon="el-icon-plus" @click="btnquars" />
        </div>
        <div class="ioxo">
          <el-form-item label="楼栋:">
            <el-select
              v-model="LadderData.Buildings_id"
              placeholder="请选择楼栋"
              size="small"
              @change="selectBuildings(LadderData.Buildings_id)"
            >
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button size="mini" icon="el-icon-plus" @click="btnBuils" />
        </div>
        <div class="ioxo">
          <el-form-item label="单元:">
            <el-select
              v-model="LadderData.units_id"
              placeholder="请选择单元"
              size="small"
              @change="selectunit(LadderData.units_id)"
            >
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button size="mini" icon="el-icon-plus" @click="btnunits" />
        </div>
        <div class="ioxo">
          <el-form-item label="房间:">
            <el-select
              v-model="LadderData.room_id"
              placeholder="请选择房间"
              size="small"
              @change="selectroom(LadderData.room_id)"
            >
              <el-option
                v-for="item in rooms"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button size="mini" icon="el-icon-plus" @click="btnrooms" />
        </div>
      </el-form>
      <div class="sssscccc">注：如果不选择位置则保存之前的位置</div>
        <div slot="footer" class="preservationBtn">
    <el-button type="primary" size="small" @click="waterLadder"> <i class="el-icon-document"/>保 存</el-button>
  </div>
    </div>
    <!-- 添加弹框 -->
    <div>
      <!-- 添加小区弹框 -->
      <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogQuarters" :append-to-body='true'>
          <el-form>
            <el-form-item label="小区名称: " :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model.trim="addition.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="btnQuarters"
              ><i class="el-icon-document" />保存</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- 添加楼栋弹框 -->
      <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogBuildings" :append-to-body='true'>
          <el-form>
            <el-form-item label="楼栋名称 : " :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model.trim="addBuildings.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="btnBuildings"
              ><i class="el-icon-document" />保存</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- 添加单元弹框 -->
      <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogUnits" :append-to-body='true'>
          <el-form>
            <el-form-item label="单元名称 : " :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model.trim="adddialogUnits.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="btndialogUnits"
              ><i class="el-icon-document" />保存</el-button
            >
          </div>
        </el-dialog>
      </div>
      <!-- 添加房间弹框 -->
      <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogrooms" :append-to-body='true'>
          <el-form>
            <el-form-item label="房间名称 : " :label-width="formLabelWidth">
              <el-input
                autocomplete="off"
                v-model="adddialogrooms.name"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="small" @click="btndialogrooms"
              ><i class="el-icon-document" />保存</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faMsg: {
      type: Object,
      default: () => {}
    }
  },
  inject: ['reload'],
  data () {
    return {
      valsvsu: {
        room_id: null
      },
      formLabelWidth: '90px',
      value: null,
      // 下拉框
      // 获取小区
      dialogQuarters: false,
      quarters: [],
      // 获取楼栋
      dialogBuildings: false,
      Buildings: [],
      // 获取单元
      dialogUnits: false,
      units: [],
      // 获取房间
      dialogrooms: false,
      rooms: [],
      // 添加小区
      addition: {
        token: null,
        name: null
      },
      // 添加楼栋
      addBuildings: {
        token: null,
        community_id: null,
        name: null
      },
      // 添加单元
      adddialogUnits: {
        token: null,
        building_id: null,
        name: null
      },
      // 添加房间
      adddialogrooms: {
        token: null,
        unit_id: null,
        name: null
      },

      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      // 添加
      LadderData: {
        token: null,
        meter_id: null,
        is_use: null,
        enable_trace: null,
        water_type_id: null,
        meter_type: null,
        username: null,
        phone: null,
        card_number: null,
        room_id: null
      },
      // 型号下拉框
      meteData: [
        {
          id: null,
          name: null
        }
      ],
      // 用水类型下拉框
      withdown: [
        {
          id: null,
          name: null
        }
      ],
      // 水表状态下拉框
      famlis: {
        token: null
      },
      options: [
        {
          id: 0,
          name: '停用'
        },
        {
          id: 1,
          name: '启用'
        }
      ]
    }
  },
  // props: ['faMsg'],
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    // 添加
    this.LadderData.token = this.$store.state.print.goodsForeNo.token
    // 下拉框
    this.waterdown()
    this.basData()
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
    console.log(this.faMsg, '子组件接收到点击父组件修改按钮')
    this.LadderData.room_id = null
  },
  methods: {
    // 获取型号数据
    basData () {
      this.$api.waterMeter.meterTypeGet(this.famlis).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.meteData = res.data.meter_type_list
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 添加的用水类型下拉框
    waterdown () {
      this.$api.waterMeter.waterdowns(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '用水类型')
          this.withdown = res.data.data
        }
      })
    },
    // 添加
    waterLadder () {
      console.log('这个是修改的保存按钮')
      this.LadderData.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.postmeterTable(this.LadderData).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          this.reload()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
      this.dialogwate = false
    },
    // -----------------------------------------------------------------------------
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.LadderData.Buildings_id = null
      this.LadderData.room_id = null
      this.LadderData.units_id = null
      this.Building()
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.LadderData.room_id = null
      this.LadderData.units_id = null
      this.unit()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.LadderData.room_id = null
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res, '获取房间')
        this.rooms = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
    },
    // 添加小区按钮
    btnquars () {
      this.dialogQuarters = true
    },
    // 添加小区保存按钮
    btnQuarters () {
      this.addition.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.putcommunities(this.addition).then((res) => {
        console.log(res, '添加小区')
        if (res.data.status === 'success') {
          this.residential()
          this.$notify({
            title: '成功',
            message: '添加小区成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        this.dialogQuarters = false
        this.addition.name = null
      })
    },
    // 添加楼栋按钮
    btnBuils () {
      this.dialogBuildings = true
      console.log('楼栋添加')
    },
    // 添加楼栋保存按钮
    btnBuildings () {
      this.addBuildings.token = this.$store.state.print.goodsForeNo.token
      this.addBuildings.community_id = this.munity
      this.$api.userProfile.putbuildings(this.addBuildings).then((res) => {
        console.log(res, '添加楼栋')
        if (res.data.status === 'success') {
          this.Building()
          this.$notify({
            title: '成功',
            message: '添加楼栋成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        this.dialogBuildings = false
        this.addBuildings.name = null
      })
    },
    // 添加单元按钮
    btnunits () {
      this.dialogUnits = true
    },
    // 添加单元保存按钮
    btndialogUnits () {
      this.adddialogUnits.token = this.$store.state.print.goodsForeNo.token
      this.adddialogUnits.building_id = this.unis
      this.$api.userProfile.putunits(this.adddialogUnits).then((res) => {
        console.log(res, '添加单元')
        if (res.data.status === 'success') {
          this.unit()
          this.$notify({
            title: '成功',
            message: '添加单元成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        this.dialogUnits = false
        this.adddialogUnits.name = null
      })
    },
    // 添加房间按钮
    btnrooms () {
      this.dialogrooms = true
    },
    // 添加房间保存按钮
    btndialogrooms () {
      this.adddialogrooms.token = this.$store.state.print.goodsForeNo.token
      this.adddialogrooms.unit_id = this.rom
      this.$api.userProfile.putrooms(this.adddialogrooms).then((res) => {
        console.log(res, '添加房间')
        if (res.data.status === 'success') {
          this.room()
          this.$notify({
            title: '成功',
            message: '添加楼房间成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        this.dialogrooms = false
        this.adddialogrooms.name = null
      })
    }
  },
  watch: {
    faMsg: {
      handler (val) {
        if (!val) return
        this.LadderData = val
        // this.LadderData.room_id = null
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.ioxo{
  display: flex;
  .el-button {
    margin-top: 4px;
    margin-left: 16px;
    background-color: #987aff;
    height: 35px;
    color: #fff;
    }
}
.preservationBtn{
.el-button{
  background-color: #987aff;
  border: 1px solid #987aff;
  color: #fff;
}
}
.sssscccc{
      margin-left: 30px;
      color: brown;
    }

// 添加提示
.locationAdd {
  .el-dialog {
    .el-input {
      width: 240px;
    }
    .el-button {
      background-color: #fff;
      border: 1px solid #744dfe;
      color: #000;
      border-radius: 7px;
      i {
        margin-right: 10px;
      }
    }
  }
}

//   // 移动端
//   @media (max-width: 768px) {
//   .el-dialog__wrapper{
//   ::v-deep .el-dialog{
//     width: 92%;
//       }
//     }
//     }
</style>
