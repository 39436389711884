<template>
    <div><div id="myChart" :style="{width: '100%', height: '300px', padding: 0, margin: 0,}"></div>
    </div>
</template>
<script>
export default {
  props: ['yDataArrs'],
  data () {
    return {
      xDataArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      // yDataArr: []
    }
  },
  mounted () {
    console.log(this.yDataArrs, '这是按月份的数据')
    this.drawLine()
  },
  watch: {
    yDataArrs: {
      handler () {
        this.drawLine()
      },
      deep: true
    }
  },
  created () {
    // console.log(this.yDataArrs, '这是按月份的数据')
    // var that = this
    // that.$nextTick(function () {
    //   that.yDataArr = that.yDataArrs
    // })
  },
  methods: {
    drawLine () {
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      //   const colors = ['#744DFE', '#744DFE', '#744DFE', '#744DFE']
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis',
          //   triggerOn: 'click',
          //   formatter: '{b}月的数据是{c}'
          formatter: function (arg) {
            // console.log(arg)
            return arg[0].name + '月的数据是：' + arg[0].data
          }
        },
        grid: {
          top: '10%',
          right: '5%',
          bottom: '30%'
        },
        xAxis: {
          data: this.xDataArr
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // lineStyle: {
            //   color: colors[0]
            // }
          }
        },
        series: [{
          name: '用水',
          type: 'bar',
          data: this.yDataArrs,
          itemStyle: {
            barWidth: 10,
            color: '#744DFE',
            barBorderRadius: 20
          },
          barWidth: 10
        }]
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
