<template>
    <div>
        <el-card>
    <el-table :data="tableData" size=mini highlight-current-row v-loading="loading" :header-cell-style = "{background:'#987AFF'}" height='600' element-loading-text="拼命加载中">
    <el-table-column type="index" width="100" />
    <el-table-column property="pay_id" label="缴费编号" width="80" />
    <el-table-column property="meter_id" label="水表编号" width="180" :show-overflow-tooltip="true" />
    <el-table-column property="username" label="用户姓名" width="100" />
    <el-table-column property="create_time" label="时间" width="190" :show-overflow-tooltip="true"  />
    <el-table-column property="amount" label="本次金额" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="address" label="用户地址" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="flow_use" label="结算水量" width="150" />
    <el-table-column property="current_sum_flow" label="表码数" width="150" />
    <el-table-column property="aft_money" label="余额" width="150" />
    <el-table-column property="type" label="账单类型" width="100" />
    <el-table-column property="is_reversed" label="状态"/>
    <el-table-column property="note" label="备注" width="130" :show-overflow-tooltip="true" />
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="watefamls.pagenum"
      :page-sizes="[10, 30, 50, 70, 90, 100]"
      :page-size="watefamls.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
    </div>
</template>

<script>
export default {
  data () {
    return {
    // table数据
      tableData: [{
        meterId: null,
        sumFlow: null,
        unit: null,
        insertTime: null,
        valveStatus: null
      }],
      // tab数据加载转圈
      loading: false,
      total: 0,
      watefamls: {
        meter_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      }
    }
  },
  created () {
    this.watefamls.token = this.$store.state.print.goodsForeNo.token
    console.log(this.$parent.meterMaid, '获取父组件的表号')
    this.watefamls.meter_id = this.$parent.meterMaid
    this.metnData()
  },
  methods: {
    metnData () {
      this.$api.finaciStates.detabills(this.watefamls).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '抄表记录')
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.watefamls.pagesize = newSize
      this.metnData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.watefamls.pagenum = newPage
      this.metnData()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
</style>
