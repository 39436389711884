<template>
    <div>
        <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userProfile' }">用户档案</el-breadcrumb-item>
        <el-breadcrumb-item>换表记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 主体区域 -->
    <div class="num">
        <!-- 搜索 -->
        <div class="suso">
        <el-form>
            <el-form-item>
            <el-input placeholder="请搜索" v-model.trim="cxuanDatas.search_content"></el-input>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="cxuntab">查询</el-button>
            </el-form-item>
            <el-form-item>
            <div class="ilo" @click="userAss">
                <span>返回</span>
                <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
            </div>
            </el-form-item>
        </el-form>
        </div>
        <div class="nums">
            <div class="numst" v-for=" item in fiamData " :key="item.id">
                <div class="numst1">
                    <span>缴费编号:</span>&nbsp;<span>{{item.pay_id}}</span>&nbsp;
                    <span>用户姓名:</span>&nbsp;<span>{{item.username}}</span>&nbsp;
                    <span>联系方式:</span>&nbsp;<span>{{item.phone}}</span>&nbsp;
                    <span>日期:</span>&nbsp;<span>{{item.create_time}}</span>
                </div>
                <div class="numst2">
                    <div>新表信息</div>
                    <div class="ntext">
                        <span>表具编号</span>
                        <span>表码数</span>
                        <span>水表类型</span>
                        <!-- <span style="margin-left: 60px;">型号</span> -->
                    </div>
                    <div class="nexts">
                        <span style="margin-left: 20px;">{{item.new_meter_id}}</span>
                        <span style="margin-left: 90px;">{{item.new_meter_sum_flow}}</span>
                        <span style="margin-left: 110px;">{{item.new_meter_type}}</span>
                        <!-- <span style="margin-left: 35px;">NB-lot</span> -->
                    </div>
                </div>
                <div class="numst2">
                    <div>旧表信息</div>
                    <div class="ntext">
                        <span>表具编号</span>
                        <span>表码数</span>
                        <span>水表类型</span>
                        <!-- <span style="margin-left: 60px;">型号</span> -->
                    </div>
                    <div class="nexts">
                        <span style="margin-left: 20px;">{{item.old_meter_id}}</span>
                        <span style="margin-left: 75px;">{{item.old_meter_sum_flow}}</span>
                        <span style="margin-left: 110px;">{{item.old_meter_type}}</span>
                        <!-- <span style="margin-left: 35px;">NB-lot</span> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 没有数据显示 -->
        <div class="zanwu" v-show="shuju">暂无数据</div>
    </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      shuju: false,
      famlis: {
        project_id: null,
        token: null
      },
      fiamData: [],
      cxuanDatas: {
        project_id: null,
        token: null,
        search_content: null
      }
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.basiData()
  },
  methods: {
    // 查询
    cxuntab () {
      this.cxuanDatas.project_id = this.$store.state.print.goodsForeNo.project_id
      this.cxuanDatas.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.hbcxuns(this.cxuanDatas).then((res) => {
        console.log(res, '查询')
        this.fiamData = res.data.data
      })
    },
    basiData () {
      this.$api.userProfile.userBisiDatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res)
          this.fiamData = res.data.data
          this.total = res.data.total
          if (this.total === 0) {
            this.shuju = true
          }
        }
      })
    },
    userAss () {
      this.$router.push('/userProfile')
    }
  }
}
</script>

<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 主体区域
.num{
    margin: 10px 0px 0px 7px;
    }
    // 搜索
.suso {
  width: 900px;
  margin-top: 15px;
  margin-left: 71px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      width: 300px;
    }
    .sele {
      margin-left: 50px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    .el-form-item{
      margin-bottom: 9px;
      .ilo{
          cursor: pointer;
            margin-left: 1017px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 27px;
        height: 23px;
      }
    }
      .ili{
      cursor: pointer;
      margin-left: 50px;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    }
  }
}
.nums{
    // margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
    .numst{
        margin-top: 20px;
        margin-left: 71px;
        width: 691px;
        height: 340px;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px 1px rgba(116, 77, 254, 0.5);
        border-radius: 7px;
        border: solid 2px #c6c6c6;
        .numst1{
            margin: 17px 0px 0px 20px;
            width: 95%;
            height: 18px;
            font-family: MiSans-Regular;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 9px;
            letter-spacing: 1px;
            color: #656d92;
            border-bottom: 2px solid #977aff;
            span{
                font-family: MiSans-Regular;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 1px;
                color: #6c7396;
            }
        }
        .numst2{
            margin: 22px 0px 0px 20px;
            width: 500px;
            height: 84px;
            font-family: MiSans-Regular;
            font-size: 20px;
            // font-weight: bold;
            font-stretch: normal;
            line-height: 31px;
            letter-spacing: 2px;
            color: #977aff;
            .ntext{
                width: 517px;
                height: 29px;
                background-color: #c3b2ff;
                border-radius: 15px 15px 0px 0px;
                display: flex;
                justify-content: space-around;
                span{
                    font-family: MiSans-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    color: #ffffff;
                }
            }
            .nexts{
              font-family: MiSans-Regular;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 1px;
              color: #6c7396;
            }
        }
    }
}
.zanwu{
  margin-top: 20%;
  margin-left: 50%;
}
</style>
