<template>
    <div>
        <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userProfile' }">用户档案</el-breadcrumb-item>
        <el-breadcrumb-item>换表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 主体区域 -->
    <div class="num">
    <!-- 解绑水表 -->
    <div class="numa">
        <div class="numj">解绑水表<div class="vi" /></div>
        <div class="numx" />
        <div class="numf">
            <el-form label-width="80px" class="demo-form-inline">
                <el-form-item label="姓名:">
                    <el-input v-model="sfalData.name" size="small" disabled></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="联系电话:">
                    <el-input v-model="sfalData.phone" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="位置信息:">
                    <el-input v-model="sfalData.address" size="small" disabled style="width: 550px"></el-input>
                </el-form-item>
                <!-- <div class="nvi"></div> -->
                <div class="dnvtu">旧表信息</div>
                <el-form-item label="水表编号:">
                    <el-input v-model="sfalData.meter_id" size="small" disabled></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="表具类型:">
                    <el-input v-model="sfalData.meter_type" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="用水类型:">
                    <el-input v-model="sfalData.water_type" size="small" disabled></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="水表状态:">
                    <el-input  v-model="sfalData.meter_status" size="small" disabled></el-input>
                </el-form-item>
                <div class="dnvtu">新表信息</div>
                <el-form-item label="水表编号:">
                    <el-input v-model="faimdBms.meter_id" size="small" disabled></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="表具类型:">
                    <el-input v-model="faimdBms.meter_type" size="small" disabled></el-input>
                </el-form-item>
                <el-form-item label="用水类型:">
                    <el-input v-model="faimdBms.water_type_name" size="small" disabled></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="水表状态:">
                    <el-input v-model="faimdBms.is_use" size="small" disabled></el-input>
                </el-form-item>
                <!-- <div class="numbtn">
                    <el-button><i class="el-icon-circle-check" />确认解绑</el-button>
                </div> -->
            </el-form>
        </div>
    </div>
    <!-- 绑定水表 -->
    <div class="numb">
        <div class="numj">绑定水表<div class="vi" />
        <div class="ilo" @click="userAss">
                <span>返回</span>
                <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
            </div>
            </div>
        <div class="numx" />
        <div class="numbfai">
          <el-input size="small" placeholder="请搜索" v-model.trim="tabfams.search_content"></el-input>
          <el-button size="small" @click="sosuobtn">搜索</el-button>
        </div>
        <div>
        <el-card>
    <el-table :data="tableData" size=mini highlight-current-row v-loading="loading" :header-cell-style = "{background:'#987AFF'}" @current-change="handleCurrentChange" height='550' element-loading-text="拼命加载中">
    <el-table-column type="index" width="100" />
    <el-table-column  property="meter_id" label="表具编号" width="170" />
    <el-table-column property="water_type_name" label="用水类型" width="140" :show-overflow-tooltip="true" />
    <el-table-column property="is_use" label="使用状态" width="140" :show-overflow-tooltip="true">
      <template slot-scope="{row: {is_use}}">
        <span v-if="+is_use === 0">未启用</span>
        <span v-else>启用</span>
      </template>
    </el-table-column>
    <el-table-column property="name" label="操作" width="150" >
        <template slot-scope="scope">
        <el-button size="mini" @click="fisdBtn(scope.row)">绑定</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
    </div>
    <div class="numbtn">
        <el-button style="small" @click="bisiNabto"><i class="el-icon-document" />保存</el-button>
    </div>
    </div>
    </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      faimdBms: {
        is_use: null,
        project_id: null,
        token: null,
        meter_id: null,
        new_meter_id: null
      },
      sfalData: null,
      // table数据
      tableData: [{
        project_id: null,
        not_bind: null,
        meter_id: null,
        water_type: null,
        is_use: null
      }],
      // tab
      tabfams: {
        project_id: null,
        token: null,
        not_bind: 1,
        search_content: null
      },
      // tab数据加载转圈
      loading: false
    }
  },
  created () {
    this.tabfams.project_id = this.$store.state.print.goodsForeNo.project_id
    this.tabfams.token = this.$store.state.print.goodsForeNo.token
    console.log(this.$route.query.obj)
    this.sfalData = this.$route.query.obj
    if (this.sfalData.meter_status === true) {
      this.sfalData.meter_status = '已启用'
    } else if (this.sfalData.meter_status === false) {
      this.sfalData.meter_status = '未启用'
    } else {
      this.sfalData.meter_status = '未绑定'
    }
    this.userDate()
  },
  methods: {
    // 搜索
    sosuobtn () {
      this.$api.userProfile.userTabs(this.tabfams).then((res) => {
        console.log(res, '搜索')
        this.userDate()
      })
    },
    // 保存
    bisiNabto () {
      this.faimdBms.project_id = this.$store.state.print.goodsForeNo.project_id
      this.faimdBms.token = this.$store.state.print.goodsForeNo.token
      this.faimdBms.pay_id = this.$route.query.obj.pay_id
      this.$api.userProfile.userBisNaim(this.faimdBms).then((res) => {
        if (res.data.status === 'success') {
          console.log(res)
          this.$notify({
            title: '成功',
            message: '换表成功',
            type: 'success'
          })
          this.$router.push('/userProfile')
          this.userDate()
        }
      })
    },
    // 绑定
    fisdBtn (row) {
      console.log(row, 'rowee')
      this.faimdBms = row
      this.faimdBms.meter_id = row.meter_id
      this.faimdBms.new_meter_id = row.meter_id
      if (row.is_use === true) {
        this.faimdBms.is_use = '已启用'
      } else {
        this.faimdBms.is_use = '未启用'
      }
    },
    // table
    userDate () {
      this.$api.userProfile.userTabs(this.tabfams).then((res) => {
        console.log(res)
        this.tableData = res.data.data
      })
    },
    userAss () {
      this.$router.push('/userProfile')
    },
    // tab组件
    handleCurrentChange (val) {
    }
  }
}
</script>

<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 主题区域
.num{
    display: flex;
}
// 解绑水表
.numa{
    margin: 37px 0px 0px 73px;
    width: 701px;
    height: 500px;
    // border-right: 1px solid;
    .numj{
        // width: 133px;
        // height: 30px;
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
        display: flex;
        align-items: center;
        .vi{
        z-index: 9999;
        width: 6px;
        height: 23px;
        background-color: #744dfe;
        margin-left: 15px;
        }
    }
    .numx{
        margin-top: 23px;
        width: 701px;
        height: 2px;
        background-color: #dad2f4;
    }
    .numf{
        margin-top: 34px;
        .el-form{
            display: flex;
            flex-wrap: wrap;
            .el-form-item{
                .el-input{
                    width: 220px;
                }
            }
            .nvi{
                margin-bottom: 20px;
                width: 647px;
                height: 3px;
                background-color: #656d92;
            }
            .dnvtu{
              padding: 0px 0px 20px 5px;
              width: 500px;
              color: #744dfe;
            }
        }
        .numbtn{
                margin-left: 530px;
                .el-button{
                    border: 1px solid #744dfe;
                    i{
                        margin-right: 10px;
                        font-size: 15px;
                    }
                }
            }
    }
}
// 绑定水表
.numb{
    margin-left: 50px;
    margin-top: 30px;
    width: 702px;
    // height: 500px;
    // border: 1px solid;
    .numj{
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
        display: flex;
        align-items: center;
        .vi{
        z-index: 9999;
        width: 6px;
        height: 23px;
        background-color: #744dfe;
        margin-left: 15px;
        }
        .ilo{
          cursor: pointer;
            margin-left: 450px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 27px;
        height: 23px;
      }
    }
    }
    .numx{
        margin-top: 17px;
        margin-bottom: 8px;
        width: 701px;
        height: 2px;
        background-color: #dad2f4;
    }
    .numbfai{
      display: flex;
      padding: 10px 0px;
      .el-input{
        width: 300px;
        border: 1px solid #744dfe;
        border-radius: 6px;
      }
      .el-button{
        margin-left: 20px;
      }
    }
    .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    .numbtn{
        float: right;
        margin-top: 27px;
        .el-button{
            i{
                margin-right: 10px;
            }
        }
    }
}
</style>
