<template>
    <div>
    <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>用水类型</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 阶梯 -->
    <div class="ladder">
      <i class="iconfont icon-jietitu1"></i>
      <span style="font-size: 22px; color: #656d92">用水类型管理</span>
      <span style="margin-left: 42px; color: #7a82a6"> </span>
      <el-button style="float:right;" size="mini" @click="dialogFormVisible"><i class="iconfont icon-jiahao"></i>新增用水类型</el-button>
    </div>
    <div class="move">
      <el-button @click="dialogFormVisible"><i class="iconfont icon-jiahao"></i>新增用水类型</el-button>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row v-loading="loading" :header-cell-style = "{background:'#987AFF'}" height='500' element-loading-text="拼命加载中">
    <el-table-column type="index" />
    <el-table-column property="name" label="用水类型"  />
    <el-table-column property="fee_type" label="阶梯类型">
      <template slot-scope="scope">
        <span v-if="scope.row.fee_type === 'fixed'">固定</span>
        <span v-if="scope.row.fee_type === 'dynamic'">阶梯</span>
      </template>
    </el-table-column>
    <el-table-column property="fixed_monthly_fee" label="月固定费" />
    <el-table-column property="fixed_monthly_free_flow" label="月含水量" />
    <el-table-column property="valve" label="操作" width="200">
      <template slot-scope="scope">
        <el-button size="mini" @click="opens(scope.row)">删除</el-button>
        <el-button size="mini" @click="Xiugai(scope.row)">查看</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
  <!-- 添加 -->
  <div class="cycle">
    <el-dialog title="新增阶梯" :visible.sync="dialogVisible">
      <div class="spans"/>
      <div class="name-input">名称：<el-input size='small' v-model.trim="LadderData.name" placeholder="用水类型"></el-input></div>
      <div class="Fixed-fee">
        <span>月固定费:</span>&nbsp;&nbsp;
        <el-input size='mini'  v-model.trim="LadderData.fixed_monthly_fee"></el-input>&nbsp;
        <span>元</span>&nbsp;&nbsp;&nbsp;
        <span>含水量:</span>&nbsp;&nbsp;
        <el-input size='mini' v-model.trim="LadderData.fixed_monthly_free_flow"></el-input>&nbsp;
        <span>方</span>
      </div>
      <div class="zhu">注: 如果输入0元则是0元</div>
      <div class="tabs-tab">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="平价" name="first" :disabled = Par>
          <div class="tabs-width">
          <el-input size='mini'  v-model.trim="LadderData.step1_price" :disabled="AtPar"></el-input>&nbsp;&nbsp;&nbsp;
          <span>元/方</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="月阶梯" name="second" :disabled = Moon>
          <el-form :model="LadderData"  >

        <el-form-item label="阶梯一 : " :label-width="formLabelWidth" :required="true">
          <el-input v-model.trim="LadderData.step1_limit" size="small" placeholder="请输入阶段一"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step1_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯二 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step2_limit" size="small" placeholder="请输入阶段二"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step2_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯三 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step3_limit" size="small" placeholder="请输入阶段三"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step3_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯四 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step4_limit" size="small" placeholder="请输入阶段四"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step4_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯五 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step5_limit" size="small" placeholder="请输入阶段五"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step5_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="年阶梯" name="third" :disabled = Annual>
          <el-form :model="LadderData">

        <el-form-item label="阶梯一 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step1_limit" size="small" placeholder="请输入阶段一"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step1_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯二 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step2_limit" size="small" placeholder="请输入阶段二"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step2_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯三 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step3_limit" size="small" placeholder="请输入阶段三"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step3_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯四 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step4_limit" size="small" placeholder="请输入阶段四"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step4_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>

        <el-form-item label="阶梯五 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step5_limit" size="small" placeholder="请输入阶段五"></el-input>&nbsp;
          <span>方</span>
        </el-form-item>
        <el-form-item label="价格 : " :label-width="formLabelWidth">
          <el-input v-model.trim="LadderData.step5_price" size="small" placeholder="请输入价格"></el-input>&nbsp;
          <span>元</span>
        </el-form-item>
          </el-form>
        </el-tab-pane>
        </el-tabs>
        <div class="zhu">注: 如要添加平价，需要点击平价后则可进行输入。</div>
      </div>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="mini" @click="Reselection"> <i class="el-icon-document"/>重 选</el-button>
    <el-button type="primary" size="mini" @click="addLadder"> <i class="el-icon-document"/>保 存</el-button>
  </div>
    </el-dialog>
  </div>
  <!-- 查看 -->
  <div class="cycle">
    <el-dialog title="查看详情" :visible.sync="XialogGisible" @close='closeDialog'>
      <div class="spans"/>
      <div class="name-input">名称：<el-input size='small' v-model="XadderData.name"  :disabled="disabled"></el-input></div>
      <div class="Fixed-fee">
        <span>月固定费:</span>&nbsp;&nbsp;
        <el-input size='mini' v-model="XadderData.fixed_monthly_fee" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>&nbsp;&nbsp;&nbsp;
        <span>含水量:</span>&nbsp;&nbsp;
        <el-input size='mini' v-model="XadderData.fixed_monthly_free_flow" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </div>
      <div class="zhu">注: 如果输入0元则是0元</div>
      <div class="tabs-tab">
        <el-tabs v-model="activeSee" type="card" @tab-click="modifyClick">
        <el-tab-pane label="平价" name="first" :disabled = Par>
          <div class="tabs-width">
          <el-input size='mini' v-model="XadderData.step1_price" :disabled="AtPar"></el-input>&nbsp;&nbsp;&nbsp;
          <span>元/方</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="月阶梯" name="second" :disabled = Moon>
          <el-form :model="XadderData">
      <el-form-item label="阶梯一 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step1_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step1_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯二 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step2_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step2_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯三 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step3_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step3_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯四 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step4_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step4_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯五 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step5_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step5_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="年阶梯" name="third" :disabled = Annual>
          <el-form :model="XadderData">
      <el-form-item label="阶梯一 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step1_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step1_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯二 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step2_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step2_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯三 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step3_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step3_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯四 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step4_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step4_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>

      <el-form-item label="阶梯五 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step5_limit" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>方</span>
      </el-form-item>
      <el-form-item label="价格 : " :label-width="formLabelWidth">
        <el-input v-model.trim="XadderData.step5_price" size="small" :disabled="disabled"></el-input>&nbsp;
        <span>元</span>
      </el-form-item>
          </el-form>
        </el-tab-pane>
        </el-tabs>
        <div class="zhu">注: 如要修改平价/月阶梯或年阶梯，需要在点击修改前选择好要修改的平价/月阶梯或年阶梯则可进行修改。</div>
      </div>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="mini" @click="modify"> <i class="el-icon-document"/>修 改</el-button>
    <el-button type="primary" size="mini" @click="XddGadder" v-show="Modifyhidden"> <i class="el-icon-document"/>保 存</el-button>
    <el-button type="primary" size="mini" @click="closebtn" v-show="close">关闭</el-button>
  </div>
</el-dialog>
</div>
    </div>
</template>
<script>
export default {
  inject: ['reload'], //  此处引入在app中定义的reload方法
  data () {
    return {
      AtPar: true,
      Par: false,
      Moon: false,
      Annual: false,
      close: true,
      Modifyhidden: false,
      disabled: true,
      ww: null,
      activeName: 'first',
      activeSee: null,
      // 修改
      XialogGisible: false,
      // 删除
      water: {
        project_id: null,
        token: null,
        water_type_id: null
      },
      // 阶段周期下拉框
      options: [{
        id: 1,
        value: '年阶'
      }, {
        id: 0,
        value: '月阶'
      }],
      // 下拉框
      value: null,
      // 添加
      LadderData: {
        project_id: null,
        token: null,
        name: null,
        step1_limit: null,
        step1_price: null,
        step2_limit: null,
        step2_price: null,
        step3_limit: null,
        step3_price: null,
        step4_limit: null,
        step4_price: null,
        step5_limit: null,
        step5_price: null
      },
      // 修改
      XadderData: {
        project_id: null,
        water_type_id: null,
        token: null,
        name: null,
        step1_limit: null,
        step1_price: null,
        step2_limit: null,
        step2_price: null,
        step3_limit: null,
        step3_price: null,
        step4_limit: null,
        step4_price: null,
        step5_limit: null,
        step5_price: null
      },
      // 添加弹框默认是否打开
      dialogVisible: false,
      // 添加的宽度
      formLabelWidth: '80px',
      famlis: {
        project_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 20
      },
      // tab
      tableData: [
        {
          cycle: null,
          id: null,
          name: null,
          step1_limit: null,
          step1_price: null,
          step2_limit: null,
          step2_price: null,
          step3_limit: null,
          step3_price: null,
          step4_limit: null,
          step4_price: null,
          step5_limit: null,
          step5_price: null
        }
      ],
      // tab数据加载转圈
      loading: true,
      total: 0,
      modifytabs: null
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    // 添加
    this.LadderData.project_id = this.$store.state.print.goodsForeNo.project_id
    this.LadderData.token = this.$store.state.print.goodsForeNo.token
    // 删除
    this.water.project_id = this.$store.state.print.goodsForeNo.project_id
    this.water.token = this.$store.state.print.goodsForeNo.token
    this.tabdata()
  },
  methods: {
    // tabs 添加
    handleClick (tab, event) {
      console.log(tab.label)
      if (tab.label === '平价') {
        this.Moon = true
        this.Annual = true
        this.LadderData.fee_type = 'fixed'
        this.AtPar = false
      }
      if (tab.label === '月阶梯') {
        this.Par = true
        this.Annual = true
        this.LadderData.fee_type = 'dynamic'
        this.LadderData.cycle = '月'
      }
      if (tab.label === '年阶梯') {
        this.Moon = true
        this.Par = true
        this.LadderData.fee_type = 'dynamic'
        this.LadderData.cycle = '年'
      }
    },
    // 查看
    modifyClick (tab) {
      console.log(tab)
      this.modifytabs = tab.label
      console.log(this.modifytabs)
    },
    // 查看
    Xiugai (row) {
      console.log(row, '修改')
      this.XadderData = row
      if (this.XadderData.fee_type === 'fixed') {
        this.activeSee = 'first'
        this.XadderData.fee_type = 'fixed'
        this.XadderData.cycle = null
        this.Moon = true
        this.Annual = true
        this.Par = false
      }
      if (this.XadderData.fee_type === 'dynamic' && this.XadderData.cycle === '月') {
        this.activeSee = 'second'
        this.XadderData.fee_type = 'dynamic'
        this.XadderData.cycle = '月'
        this.Par = true
        this.Annual = true
        this.Moon = false
      }
      if (this.XadderData.fee_type === 'dynamic' && this.XadderData.cycle === '年') {
        this.activeSee = 'third'
        this.XadderData.fee_type = 'dynamic'
        this.XadderData.cycle = '年'
        this.Par = true
        this.Moon = true
        this.Annual = false
      }
      this.XadderData.water_type_id = row.id
      this.XialogGisible = true
    },
    closebtn () {
      this.XialogGisible = false
    },
    // 修改
    modify () {
      this.Moon = false
      this.Annual = false
      this.Par = false
      this.disabled = false
      this.Modifyhidden = true
      this.close = false
      this.AtPar = false
      if (this.modifytabs === '平价') {
        this.Moon = true
        this.Annual = true
        this.XadderData.fee_type = 'fixed'
        this.XadderData.cycle = null
      }
      if (this.modifytabs === '月阶梯') {
        this.Par = true
        this.Annual = true
        this.XadderData.fee_type = 'dynamic'
        this.XadderData.cycle = '月'
      }
      if (this.modifytabs === '年阶梯') {
        this.Moon = true
        this.Par = true
        this.XadderData.fee_type = 'dynamic'
        this.XadderData.cycle = '年'
      }
    },
    closeDialog () {
      console.log('qqqq')
      this.reload()
    },
    // 修改保存
    XddGadder () {
      this.XadderData.project_id = this.$store.state.print.goodsForeNo.project_id
      this.XadderData.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterType.xaterGelets(this.XadderData).then((res) => {
        if (res.data.status === 'success') {
          this.tabdata()
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          // 刷新页面 -----缺点刷线页面有延迟体验不好  -----优点就这一条代码
          // window.location.reload()
          // 刷新页面 -----缺点还需要在app页面中写代码 当前组件中return也需要引入  -----优点刷线页面没有延迟
          this.reload()
          this.XialogGisible = false
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 重选
    Reselection () {
      this.Par = false
      this.Moon = false
      this.Annual = false
    },
    // 添加保存
    addLadder () {
      this.dialogVisible = false
      this.$api.waterType.ladderadds(this.LadderData).then((res) => {
        console.log(res, '新增')
        if (res.data.status === 'success') {
          this.tabdata()
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          // 刷新页面 -----缺点刷线页面有延迟体验不好  -----优点就这一条代码
          // window.location.reload()
          // 刷新页面 -----缺点还需要在app页面中写代码 当前组件中return也需要引入  -----优点刷线页面没有延迟
          this.reload()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 添加
    dialogFormVisible () {
      this.dialogVisible = true
    },
    // tab数据
    tabdata () {
      this.$api.waterType.tabdatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          this.tableData = res.data.data
          console.log(this.tableData)
          this.total = res.data.total
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },

    // tab删除
    opens (row) {
      console.log(row, 'index')
      this.water.water_type_id = row.id
      console.log(this.water.water_type_id, 'this.water.water_type_id')
      this.$confirm('此操作将永久删除该阶梯, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.waterType.waterDelets(this.water).then((res) => {
          console.log(res, '删除')
          if (res.data.status === 'success') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.tabdata()
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          } else if (res.data.message === '用户在别处登录') {
            this.$router.push('/', this.fasinull)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.tabdata()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.tabdata()
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 阶梯
.ladder{
  // width: 1500px;
  padding: 20px 30px 30px 74px;
  i{
    color: #744dfe;
    font-size: 27px;
  }
  .el-button{
    border: 1px solid #744dfe;
    font-size: 25px;
    color: #575757;
    i{
      font-size: 27px;
      color: #744dfe;
      margin-right: 10px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        .el-pagination{
          margin-top: 5px;
          margin-bottom: 5px;
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加
.el-dialog{
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .name-input{
        display: flex;
        align-items: center;
        .el-input{
          width: 160px;
        }
      }
      .Fixed-fee{
        margin-top: 20px;
        display: flex;
        align-items: center;
        .el-input{
          width: 50px;
        }
      }
      .zhu{
        margin-top: 10px;
        color: brown;
      }
      .tabs-tab{
        margin-top: 20px;
      }
      .tabs-width{
        .el-input{
          width: 60px;
        }
      }
      .el-form{
          display: flex;
          flex-wrap: wrap;
        .el-form-item{
          .el-input{
            width: 70px;
          }
        }
      }

      .dialog-footer{
        .el-button{
          background-color: #fff;
          border: 1px solid #744dfe;
          color: #575757;
          border-radius: 4px;
          font-size: 14px;
          span{
            i{
              font-size: 14px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 23%;
    }
  }
  }
    .move{
      padding: 10px 10px;
        .el-button{
          background-color: #fff;
          border: 1px solid #744dfe;
          color: #575757;
          border-radius: 4px;
          font-size: 14px;
          span{
            i{
              font-size: 14px;
              margin-right: 6px;
            }
          }
        }
    }
    @media (min-width: 768px) {
    .move{
      display: none;
    }
    }
    // 移动端
    @media (max-width: 768px) {
    .Gnv{
      display: none;
    }
    .ladder{
      display: none;
    }
    .tab{
      padding: 10px 10px;
    }
    .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 94%;
    }
  }
  }
 ::v-deep .el-message-box{
    width: 300px;
  }
    }
</style>
