import * as lonig from './lonig' // 登录
import * as users from './ProjTace/users' // 项目首页
import * as waterType from './ProjTace/waterType' // 用水类型
import * as position from './ProjTace/position' // 位置信息
import * as waterMeter from './ProjTace/waterMeter' // 水表档案
import * as userProfile from './ProjTace/userProfile' // 用户档案
// -------------------------------------------------------------------
import * as accountMent from './financia/accountMent' // 财务管理
import * as valveMent from './valveControl/valveMent'
import * as finaciStates from './financia/finaciStates' // 充值记录
import * as userGement from './gement/userGement'
import * as Location from './location'
export default {
  lonig,
  // ----------基础资料
  users,
  waterType,
  position,
  waterMeter,
  userProfile,
  // ----------财务
  accountMent,
  valveMent,
  finaciStates,
  userGement,
  Location
}
