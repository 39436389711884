<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>阀控管理</el-breadcrumb-item>
        <el-breadcrumb-item>指令记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input v-model="instrcasi.meter_id" placeholder="请输入缴费编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="lnstrbun" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 980px">
          <div class="ilo" @click="userAss">
                <span>返回</span>
                <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
            </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}" v-loading="loading" height='500'>
    <el-table-column type="index" width="40" />
    <el-table-column property="meter_id" label="缴费编号" width="300" />
    <el-table-column property="command_type" label="下发类型" width="250" :show-overflow-tooltip="true" />
    <el-table-column property="data_frame" label="数据帧" width="300" />
    <el-table-column property="status" label="状态" width="250" />
    <el-table-column property="send_time" label="时间"  :show-overflow-tooltip="true" width="386" />
  </el-table>
  <!-- 分页 -->
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>

    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      formLabelWidth: '100px',
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null,
        pagenum: 1,
        pagesize: 20
      },
      tableSize: null,
      instrcasi: {
        project_id: null,
        token: null,
        meter_id: null
      },
      total: null
    }
  },
  created () {
    // console.log(this.$store.state.print.goodsForeNo.id, '当前登录的id')
    // tab
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    // 查询
    lnstrbun () {
      console.log('kk')
      this.instrcasi.project_id = this.$store.state.print.goodsForeNo.project_id
      this.instrcasi.token = this.$store.state.print.goodsForeNo.token
      this.$api.valveMent.valaecasi(this.instrcasi).then((res) => {
        console.log(res)
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 返回
    userAss () {
      this.$router.push('/valveMent')
    },
    // table数据
    tabData () {
      this.$api.valveMent.valaezhis(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    handleSizeChange (newSize) {
      console.log(newSize, 'newsize')
      this.famlis.pagesize = newSize
      this.tabData()
    },
    handleCurrentChange (newPage) {
      console.log(newPage, 'newpage')
      this.famlis.pagenum = newPage
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #fff;
      color: #484848;
      border: 1px solid #744dfe;
    }
    .sele {
      width: 400px;
      margin-left: 50px;
    }
    .ilo{
          cursor: pointer;
            margin-left: 70px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 27px;
        height: 23px;
      }
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加提示
.locationAdd{
  .el-dialog{
      .el-input{
        width: 240px;
      }
      .el-button{
        background-color: #fff;
        border: 1px solid #744dfe;
        color: #000;
        border-radius: 7px;
        i{
          margin-right: 10px;
        }
      }
  }
}

// 缴费提示
  .el-dialog{
    .el-dialog__header{
      span.dialog__title{
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
    }
    }
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .diaonum{
        margin-left: 100px;
        // width: 324px;
        height: 123px;
        font-family: XinPingHeiTC-W2;
        font-size: 19px;
        // font-weight: bold;
        font-stretch: normal;
        line-height: 45px;
        letter-spacing: 4px;
        color: #484848;
        // border: 1px solid;
        .diaonsums{
          font-family: XinPingHeiTC-W2;
          font-size: 19px;
          // font-weight: bold;
          letter-spacing: 10px;
          color: #484848;
          span{
            font-family: XinPingHeiTC-W2;
            font-size: 19px;
            // font-weight: bold;
            letter-spacing: 4px;
            color: #484848;
          }
        }
      }
      .diaoinput{
        margin: 20px 0px 0px 100px;
        display: flex;
        align-items: center;
        span{
          font-family: BENMOJingyuan;
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 1px;
          color: #744dfe;
        }
        .el-input{
          width: 100px;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
      .dialog-footer{
        .el-button{
          font-size: 20px;
          i{
            margin-right: 10px;
            font-size: 24px;
          }
        }
      }
  }
</style>
