import request from '../utils/request'

// 小区的添加
export function putcommunities (data) {
  return request({
    method: 'put',
    url: 'communities',
    data
  })
}

// 小区的修改
export function postcommunities (data) {
  return request({
    method: 'POST',
    url: 'communities',
    data
  })
}

// 小区的删除
export function deletecommunities (data) {
  return request({
    method: 'DELETE',
    url: 'communities',
    data
  })
}

// 楼栋的添加
export function putbuildings (data) {
  return request({
    method: 'put',
    url: 'buildings',
    data
  })
}

// 楼栋的修改
export function postbuildings (data) {
  return request({
    method: 'POST',
    url: 'buildings',
    data
  })
}

// 楼栋的删除
export function deletebuildings (data) {
  return request({
    method: 'DELETE',
    url: 'buildings',
    data
  })
}

// 单元的添加
export function putunits (data) {
  return request({
    method: 'put',
    url: 'units',
    data
  })
}

// 单元的修改
export function postunits (data) {
  return request({
    method: 'POST',
    url: 'units',
    data
  })
}

// 单元的删除
export function deleteunits (data) {
  return request({
    method: 'DELETE',
    url: 'units',
    data
  })
}

// 房间的添加
export function putrooms (data) {
  return request({
    method: 'put',
    url: 'rooms',
    data
  })
}

// 房间的修改
export function postrooms (data) {
  return request({
    method: 'POST',
    url: 'rooms',
    data
  })
}

// 房间的删除
export function deleterooms (data) {
  return request({
    method: 'DELETE',
    url: 'rooms',
    data
  })
}
