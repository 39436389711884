<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>账务报表</el-breadcrumb-item>
        <el-breadcrumb-item>充值记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form style="display: flex;">
        <Details :getname="getname" @getValue="getUser" @start="getstart" @end="getend" @transfer="getransfer" @Building="getBuilding" @unit="getUnit" @room="getRoom"
        @ParData="commitData" @buildingData="buildData" @unitData="uniData" @roomData="rooData"></Details>
        <el-button @click="quyuDaochu">区域导出</el-button>
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='500'>
    <el-table-column type="index" width="60" />
    <el-table-column property="pay_id" label="缴费编号" />
    <el-table-column property="meter_id" label="水表编号"/>
    <el-table-column property="username" label="用户姓名" />
    <el-table-column property="address" label="地址" :show-overflow-tooltip="true" />
    <el-table-column property="create_time" label="时间"  />
    <el-table-column property="amount" label="交/退款金额"/>
    <el-table-column property="type" label="账单类型" width="100" />
    <el-table-column property="is_reversed" label="状态"/>
    <el-table-column property="note" label="备注" :show-overflow-tooltip="true" />
    <el-table-column property="note" label="操作">
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)">打印</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    <!-- 打印凭据 -->
    <div class="cycle">
    <el-dialog
      title="充值凭证"
      :visible.sync="dayinpingz"
      >
      <div class="spans"/>
      <div id="printMe">
      <div class="pingzs">充值凭证</div>
      <div class="dayinum">
        <div>订单编号:{{dayin.id}}</div>
        <div>收款日期:{{dayin.create_time}}</div>
      </div>
      <div class="dayininput">
        <div class="inputs">
          <span>缴费编号:{{dayin.pay_id}}</span>
          <span>设备编号:{{dayin.meter_id}}</span>
        </div>
        <div class="inputs">
          <span>用户名:{{dayin.username}}</span>
          <span>当前表码:{{dayin.current_sum_flow}}</span>
        </div>
        <div class="inputs">
          <span style="width: 100%">地址信息:{{dayin.address}}</span>
        </div>
        <div class="inputs">
          <span>充值金额:{{dayin.amount}}</span>
          <span>账户余额:{{dayin.aft_money}}</span>
        </div>
      </div>
      <div class="dayincao">操作人员:{{dayin.operate_user}}</div>
      </div>
      <span slot="footer" class="dayinbtn">
        <span class="spaois">手机可以截个图作为凭证,</span>
        <el-button class="spanbtn" type="info" size="mini" v-print="printObj">打印</el-button>
        <el-button type="info" size="mini" @click="prinqxio">取消</el-button>
      </span>
      </el-dialog>
    </div>
    </div>
</template>
<script>
import Details from '../../../components/Details'
import { dataConversionUtil } from '@/utils/data2Excel'
export default {
  components: {
    Details
  },
  inject: ['reload'],
  data () {
    return {
      printObj: {
        id: 'printMe', // 打印区域 Dom ID
        popTitle: '',
        extraCss: 'https://www.google.com,https://www.google.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printMe { height: auto !important; } <style>' //  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      },
      dayinpingz: false,
      dayin: {},
      tabls: [{
        search_time: null,
        pay_sum: null,
        return_sum: null,
        amount_result: null
      }],
      // dialogVisible: false,
      ficsData: {},
      formLabelWidth: '100px',
      // 下拉框
      seldata: [],
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      total: 0,
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 20,
        search_content: null,
        start_time: null,
        end_time: null
      },
      tableSize: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      time: [],
      value: [],
      cxyts: {
        project_id: null,
        token: null,
        search_content: null,
        start_time: null,
        end_time: null
      },
      // 充值汇总查询
      chizhixun: {
        project_id: null,
        token: null,
        start_time: null,
        end_time: null
      },
      // 区域导出
      qudaoCans: {
        token: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        pagesize: null,
        start_time: null,
        end_time: null,
        pagenum: 1
      }
    }
  },
  created () {
    // console.log(this.$store.state.print.goodsForeNo.id, '当前登录的id')
    // tab
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
    console.log(this.tableData, 'this.tableData')
  },
  methods: {
    // table数据
    tabData () {
      this.$api.finaciStates.detailsDates(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.total = res.data.total
          this.qudaoCans.pagesize = res.data.total
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/')
        }
      })
    },
    // 区域导出
    quyuDaochu () {
      this.qudaoCans.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.qudaoCans).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '充值记录区域导出数据')
          var data = res.data.data
          var tableHeader = [
            ['序号', '缴费编号', '水表编号', '用户姓名', '地址', '时间', '交/退款金额', '账单类型', '状态', '备注']
          ]
          var dataList = []
          data.forEach((item, index) => {
            dataList.push([index + 1, item.pay_id, item.meter_id, item.username, item.address, item.create_time, item.amount, item.type, item.is_reversed, item.note])
          })
          dataConversionUtil.dataToExcel('充值记录区域信息表', tableHeader, dataList)
          this.$message.success('导出成功!')
          this.qudaoCans.community_id = null
          this.qudaoCans.building_id = null
          this.qudaoCans.unit_id = null
          this.qudaoCans.room_id = null
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
    // tableData 数据
    getname (name) {
      console.log('App收到了子组件传过来的数据', name)
      this.tableData = name.data
      this.total = name.total
    },
    // 输入框
    getUser (val) {
      console.log('子组件传过来的input输入的内容', val)
      this.TableNo = val
    },
    // 时间日期----开始
    getstart (val) {
      console.log('子组件传过来的开始选择的内容', val)
      this.start = val
      this.qudaoCans.start_time = val
    },
    // 结束
    getend (val) {
      console.log('子组件传过来的解释选择的内容', val)
      this.end = val
      this.qudaoCans.end_time = val
    },
    getransfer (val) {
      console.log('?', val)
      this.ransfer = val
      this.qudaoCans.community_id = val
    },
    // 小区数据
    commitData (val) {
      console.log(val, '小区数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    getBuilding (val) {
      console.log('楼栋子组件传递过来的值：', val)
      this.Building = val
      this.qudaoCans.building_id = val
    },
    // 楼栋数据
    buildData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    getUnit (val) {
      console.log('单元子组件传递过来的值：', val)
      this.Unit = val
      this.qudaoCans.unit_id = val
    },
    // 单元数据
    uniData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    getRoom (val) {
      console.log('房间子组件传递过来的值：', val)
      this.Room = val
      this.qudaoCans.room_id = val
    },
    // 房间数据
    rooData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    prinqxio () {
      this.dayinpingz = false
    },
    // 打印
    ficsati (row) {
      console.log(row, '打印')
      this.dayin = row
      this.dayinpingz = true
    },
    // 充值汇总
    // cyyxun () {
    //   this.chizhixun.project_id = this.$store.state.print.goodsForeNo.project_id
    //   this.chizhixun.token = this.$store.state.print.goodsForeNo.token
    //   console.log(this.value)
    //   this.chizhixun.start_time = this.value[0]
    //   this.chizhixun.end_time = this.value[1]
    //   this.$api.finaciStates.MoneyStatisticals(this.chizhixun).then((res) => {
    //     if (res.data.status === 'success') {
    //       console.log(res, 'res')
    //       this.tabls = res.data.data
    //       // this.total = res.data.total
    //       // this.loading = false
    //     }
    //   })
    // },
    // 查询
    // pay_search () {
    //   console.log(this.time, '123')
    //   console.log(this.time[0], 'start_time ')
    //   this.cxyts.start_time = this.time[0]
    //   this.cxyts.end_time = this.time[1]
    //   this.cxyts.project_id = this.$store.state.print.goodsForeNo.project_id
    //   this.cxyts.token = this.$store.state.print.goodsForeNo.token
    //   this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
    //     console.log(res, '查询')
    //     this.tableData = res.data.data
    //     this.total = res.data.total
    //   })
    // },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.TableNo
      this.famlis.start_time = this.start
      this.famlis.end_time = this.end
      this.famlis.community_id = this.ransfer
      this.famlis.building_id = this.Building
      this.famlis.unit_id = this.Unit
      this.famlis.room_id = this.Room
      this.tabData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.TableNo
      this.famlis.start_time = this.start
      this.famlis.end_time = this.end
      this.famlis.community_id = this.ransfer
      this.famlis.building_id = this.Building
      this.famlis.unit_id = this.Unit
      this.famlis.room_id = this.Room
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-input {
      border-radius: 6px;
      width: 250px;
    }
    .el-button {
      background-color: #744dfe;
      color: #fff;
      border: 1px solid #744dfe;
      margin-bottom: 10px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
  // 充值汇总
.el-dialog{
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .el-form{
          display: flex;
          flex-wrap: wrap;
          .el-form-item{
            .el-button{
              background-color: #744dfe;
              color: #fff;
            }
          }
        }
      }

    // <!-- table表格 -->
    .tabs{
      width: 100%;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
     // 充值打印
  #printMe{
    border: 1px solid;
    padding: 10px 0px;
  .pingzs{
    width: 90%;
    height: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dayinum{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dayininput{
    width: 96%;
    height: 130px;
    border: 1px solid;
    line-height: 32px;
    margin: 10px auto;
  }
  .inputs{
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    span{
      width: 50%;
    }
  }
  .dayincao{
    margin-left: 400px;
    width: 200px;
  }
  .dayinbtn{
    button{
      font-size: 14px;
    }
  }
  }
  .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 30%;
    }
    }
    }
  // 移动端
  @media (min-width: 768px) {
      .spaois{
        display: none;
      }
    }
  @media (max-width: 768px) {
    .spaois{
      font-size: 12px;
      color: brown;
    }
    .spanbtn{
      display: none;
    }
    .Gnv{
        display: none;
      }
      .suso{
        padding: 10px 10px 0px 10px;
      }
      .tab{
        padding: 10px 10px 0px 10px;
      }
      .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 100%;
    }
    }
    }
    #printMe{
    .dayincao{
    margin-left:200px ;
    width: 200px;
  }
}
  }
</style>
