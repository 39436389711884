<template>
    <div>
        <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>表具类型</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 添加按钮 -->
    <div class="Tin">
      <el-button @click= 'residentialVisible = true'>添加</el-button>
    </div>
    <!-- 表具类型 -->
    <div class="Gaunt">
        <div class="Kuills" v-for="item in meteData " :key='item.id'>
            <div class="GunKilText">型号：{{item.name}}</div>
            <div class="xian"></div>
            <div class="GunKilBtn">
                <el-button size="small" @click="XiuGuiBtn(item)">修改</el-button>
                <el-button size="small" @click="SaiCuaiBtn(item)">删除</el-button>
            </div>
        </div>
    </div>
    <!-- 添加弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="residentialVisible">
  <el-form>
    <el-form-item label="型号 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model="Tinbou.meter_type_name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="GinBaous"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 修改弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="XiuGaiVisible">
  <el-form>
    <el-form-item label="型号 : " :label-width="formLabelWidth" :required="true">
      <el-input v-model="Xinbou.meter_type_name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="XinBaous"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
  </div>
    </div>
</template>
<script>

export default ({
  data () {
    return {
      // 数据
      famlis: {
        token: null
      },
      meteData: [],
      //   添加弹框
      residentialVisible: false,
      formLabelWidth: '60px',
      Tinbou: {
        token: null,
        meter_type_name: null
      },
      //   修改
      XiuGaiVisible: false,
      Xinbou: {
        token: null,
        meter_type_id: null,
        meter_type_name: null
      },
      // 删除
      ScuiChui: {
        token: null,
        meter_type_id: null
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.basData()
  },
  methods: {
    // 获取数据
    basData () {
      this.$api.waterMeter.meterTypeGet(this.famlis).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.meteData = res.data.meter_type_list
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 添加按钮
    GinBaous () {
      this.Tinbou.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterTypePut(this.Tinbou).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.basData()
          this.residentialVisible = false
          this.Tinbou.meter_type_name = null
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 修改按钮
    XiuGuiBtn (item) {
      console.log(item, '修改数据')
      this.Xinbou.meter_type_id = item.id
      this.XiuGaiVisible = true
    },
    // 修改保存
    XinBaous () {
      this.Xinbou.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterTypePost(this.Xinbou).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.basData()
          this.XiuGaiVisible = false
          this.Xinbou.meter_type_name = null
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 删除
    SaiCuaiBtn (item) {
      console.log(item, '删除数据')
      this.ScuiChui.token = this.$store.state.print.goodsForeNo.token
      this.ScuiChui.meter_type_id = item.id
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.waterMeter.meterTypeDel(this.ScuiChui).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            this.basData()
            this.$message({
              message: res.data.message,
              type: 'success'
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
})
</script>
<style lang="less" scoped>
    /* // 面包屑 */
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 添加按钮
.Tin{
        padding: 20px 60px;
        .el-button{
            background-color: #987AFF;
            color: #fff;
        }
    }
// 表具类型
.Gaunt{
    padding: 0px 40px;
    display: flex;
    flex-wrap: wrap;
    .Kuills{
        margin: 20px 20px;
        width: 200px;
        border: 1px solid #987AFF;
        .GunKilText{
            margin: 10px 20px;
            display: flex;
            color: #656d92;
        }
        .xian{
            border: 1px solid #656d92;
        }
        .GunKilBtn{
            margin: 10px 20px;
            .el-button{
                background-color: #987AFF;
                color: #ffffff;
            }
        }
    }
}
.locationAdd{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 20%;
    }
  }
  }
 // 移动端
 @media (max-width: 768px) {
  .Gnv{
    display: none;
  }
  .Tin{
    padding: 10px 10px;
  }
  .Gaunt{
    padding:0px;
  }
  .locationAdd{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 94%;
    }
  }
  }
 }
</style>
