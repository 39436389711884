import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from './api'
import '@/assets/fontsto/iconfont.css'
import '@/assets/fonthome/iconfont.css'
import '@/assets/fontYsu/iconfont.css'
import * as echarts from 'echarts'
import Print from 'vue-print-nb'

// this.famlis.token = this.$store.state.print.goodsForeNo.token

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     console.log(sessionStorage, 'sessionStorage.getItem')
//     next()
//     if (sessionStorage.getItem('token')) {
//     } else {
//       next({
//         path: '/'
//       })
//     }
//   } else {
//     next()
//   }
// })

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Print)
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
