import request from '../../utils/request'

// 水表档案的tab数据 get请求  id是拼接式使用
export function meterdatas (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// 水表档案的添加
export function meterdatat (data) {
  return request({
    method: 'put',
    url: 'meterTable',
    data
  })
}

// 用水类型的下拉框
export function waterdowns (data) {
  return request({
    method: 'get',
    url: 'waterType',
    params: data
  })
}

// 水表档案的保存
export function meterBchits (data) {
  return request({
    method: 'post',
    url: 'meterTable',
    data
  })
}

// 详情/抄表记录
export function wateDatas (data) {
  return request({
    method: 'post',
    url: 'waterDataChanged',
    data
  })
}

// 详情/开关阀记录
export function wateDatass (data) {
  return request({
    method: 'get',
    url: 'deviceCommand',
    params: data
  })
}

// 查询
export function cateXatas (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// 参数设置数据显示和查询
export function getmeterTable (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// 阀门控制
export function putdeviceCommand (data) {
  return request({
    method: 'put',
    url: 'deviceCommand',
    data
  })
}

// 查询设置进程
export function getdeviceCommand (data) {
  return request({
    method: 'get',
    url: 'deviceCommand',
    params: data
  })
}

// 查询设置进程
export function getdeviceFrozenData (data) {
  return request({
    method: 'post',
    url: 'deviceFrozenData',
    data
  })
}

// 表具类型》》》》获取
export function meterTypeGet (data) {
  return request({
    method: 'get',
    url: 'meterType',
    params: data
  })
}

// 表具类型》》》》添加
export function meterTypePut (data) {
  return request({
    method: 'put',
    url: 'meterType',
    data
  })
}

// 表具类型》》》》修改
export function meterTypePost (data) {
  return request({
    method: 'post',
    url: 'meterType',
    data
  })
}

// 表具类型》》》》删除
export function meterTypeDel (data) {
  return request({
    method: 'DELETE',
    url: 'meterType',
    data
  })
}

// 获取阀控策略白名单
export function closeValveServerWhiteListGet (data) {
  return request({
    method: 'get',
    url: 'closeValveServerWhiteList',
    params: data
  })
}

// 添加阀控策略白名单
export function closeValveServerWhiteListPut (data) {
  return request({
    method: 'put',
    url: 'closeValveServerWhiteList',
    data
  })
}

// 从阀控策略白名单删除表号
export function closeValveServerWhiteListDel (data) {
  return request({
    method: 'DELETE',
    url: 'closeValveServerWhiteList',
    data
  })
}

// 查询时段流量
export function BasedTraffic (data) {
  return request({
    method: 'GET',
    url: 'meterTimeBasedTraffic',
    params: data
  })
}
// 手动录入表码数
export function meterSetFlow (data) {
  return request({
    method: 'post',
    url: 'meterSetFlow',
    data
  })
}
