import request from '../../utils/request'

// 用户档案的数据
export function userData (data) {
  return request({
    method: 'get',
    url: 'userTable',
    params: data
  })
}

// 添加
export function userMfty (data) {
  return request({
    method: 'put',
    url: 'userTable',
    data
  })
}

// 添加下拉框
export function userfimas (data) {
  return request({
    method: 'get',
    url: 'locationTable',
    params: data
  })
}

// 添加tab
export function userTabs (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// ------------------------------
// 绑定水表+解绑
export function userBdifis (data) {
  return request({
    method: 'post',
    url: 'userTable',
    data
  })
}

// 换表记录
export function userBisiDatas (data) {
  return request({
    method: 'get',
    url: 'userMeterChange',
    params: data
  })
}

// 换表
export function userBisNaim (data) {
  return request({
    method: 'post',
    url: 'userMeterChange',
    data
  })
}

// 详情》充值记录
export function userPays (data) {
  return request({
    method: 'get',
    url: 'userPayment',
    params: data
  })
}

// 详情》退款记录
export function userRefs (data) {
  return request({
    method: 'get',
    url: 'userRefund',
    params: data
  })
}

// 详情》扣款记录
export function waterChargesRecord (data) {
  return request({
    method: 'post',
    url: 'waterChargesRecord',
    data
  })
}

// 详情》删除
export function waterDletes (data) {
  return request({
    method: 'DELETE',
    url: 'userTable',
    data
  })
}

// 详情》修改
export function waterXiugai (data) {
  return request({
    method: 'post',
    url: 'userTable',
    data
  })
}

// 查询
export function Czhixios (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// 换表记录查询
export function hbcxuns (data) {
  return request({
    method: 'get',
    url: 'userMeterChange',
    params: data
  })
}

// 获取小区
export function communities (data) {
  return request({
    method: 'get',
    url: 'communities',
    params: data
  })
}

// 获取楼栋
export function buildings (data) {
  return request({
    method: 'get',
    url: 'buildings',
    params: data
  })
}

// 获取单元
export function units (data) {
  return request({
    method: 'get',
    url: 'units',
    params: data
  })
}

// 获取房间
export function rooms (data) {
  return request({
    method: 'get',
    url: 'rooms',
    params: data
  })
}

// 添加小区
export function putcommunities (data) {
  return request({
    method: 'put',
    url: 'communities',
    data
  })
}

// 添加楼栋
export function putbuildings (data) {
  return request({
    method: 'put',
    url: 'buildings',
    data
  })
}

// 添加单元
export function putunits (data) {
  return request({
    method: 'put',
    url: 'units',
    data
  })
}

// 添加房间
export function putrooms (data) {
  return request({
    method: 'put',
    url: 'rooms',
    data
  })
}

// 修改小区
export function postCommunities (data) {
  return request({
    method: 'POST',
    url: 'communities',
    data
  })
}

// 修改楼栋
export function postBuildings (data) {
  return request({
    method: 'POST',
    url: 'buildings',
    data
  })
}

// 修改单元
export function postUnits (data) {
  return request({
    method: 'POST',
    url: 'units',
    data
  })
}

// 修改房间
export function postRooms (data) {
  return request({
    method: 'POST',
    url: 'rooms',
    data
  })
}

// 修改单只表
export function postmeterTable (data) {
  return request({
    method: 'POST',
    url: 'meterTable',
    data
  })
}
