<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>预警推送</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 添加 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-button type="primary" style="margin-left: 10px;background-color: #987AFF; color: #fff;border: none;" @click="Btnobtainimg">绑定接收者微信</el-button>
          <el-button type="primary" style="margin-left: 10px;background-color: #987AFF; color: #fff;border: none;" @click="BtnRefresh">刷 新</el-button>

        </el-form-item>
      </el-form>
    </div>
    <!-- 添加弹框 -->
    <div class="locationOpenvalve">
    <el-dialog title="绑定接收者微信" :visible.sync="dialogVisible">
      <div style="display: flex; justify-content: center;">
        <img :src="obtainimg" alt="">
      </div>
      <div style="color: brown; margin-top: 20px;">请使用微信扫码，显示绑定成功后关闭此窗口并点击刷新按钮。</div>
      <div style="color: brown; margin-top: 10px;">注意：使用此功能前必须先关注此项目对应的微信公众号，否则无法接收预警信息</div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addLadder"> <i class="el-icon-document"/>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='500'>
    <el-table-column property="id" label="id" width="100" />
    <el-table-column property="wechat_user_nick_name" label="微信用户昵称" :show-overflow-tooltip="true" />
    <el-table-column property="wechat_user_openid" label="微信用户openid" :show-overflow-tooltip="true"/>
    <el-table-column property="project_name" label="项目名称" :show-overflow-tooltip="true"/>
    <el-table-column property="create_time" label="绑定时间" :show-overflow-tooltip="true">
    </el-table-column>
    <el-table-column property="balance" label="操作" width="150">
      <template slot-scope="scope">
        <el-button size="mini" @click="suanchu(scope.row)">删 除</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
  </div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      dialgVsibles: false,
      dialogVisible: false,
      formLabelWidth: '100px',
      // tab
      tableData: [],
      loading: true,
      // 登录返回的项目id
      famlis: {
        token: null
      },
      obtainimg: null,
      // 删除
      schugement: {
        token: null,
        id: null
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    // 删除
    suanchu (row) {
      this.schugement.token = this.$store.state.print.goodsForeNo.token
      this.schugement.id = row.id
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.userGement.deleWechatAdmin(this.schugement).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            this.tabData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 获取二维码
    Btnobtainimg () {
      this.$api.userGement.BindQRUrl(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          this.obtainimg = res.data.data.url
          console.log(this.obtainimg, 'res')
          this.dialogVisible = true
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.$router.push('/', this.fasinull)
        }
      })
    },
    addLadder () {
      this.dialogVisible = false
    },
    BtnRefresh () {
      this.tabData()
    },
    // table数据
    tabData () {
      this.$api.userGement.WechatAdmin(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #fff;
      color: #484848;
      border: 1px solid #744dfe;
    }
    .sele {
      width: 350px;
      margin-left: 50px;
    }
    .seles {
      width: 450px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加提示
  .el-dialog{
      .el-form{
        display: flex;
        flex-wrap: wrap;
        .el-form-item{
          display: flex;
          .el-input{
            width: 200px;
          }
        }
      }
      .el-button{
        background-color: #fff;
        border: 1px solid #744dfe;
        color: #000;
        border-radius: 7px;
        i{
          margin-right: 10px;
        }
      }
  }
  .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
  }
}
        // 移动端
        @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .suso{
        padding: 10px 10px;
      }
      .tab{
        padding: 10px 10px;
      }
      .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 94%;
  }
}
    }
</style>
