<template>
  <div class="T">
    <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>水表接收与设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input placeholder="请搜索关键字点击输入进行查询" v-model.trim="cxyts.search_content" ></el-input>
          <el-button class="formBtn" type="primary" @click="pay_search">查询</el-button>
          <el-button class="formBtn" type="primary" @click="shuaxin">重选</el-button>
        </el-form-item>
        <el-form-item label="小区:" class="elform">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:" class="elform">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:" class="elform">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:" class="elform">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
            @change="selectroom(cxyts.room_id)">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button class="elfomobtn" type="primary" @click="pay_search" size="small">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="elfomobtn" type="primary" @click="shuaxin" size="small">重 置</el-button>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" size="small">批 量 导 出</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 按钮区域 -->
    <div class="ButtonArea">
        <div>
          <el-dropdown>
            <el-button type="primary">
                阀门控制<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="Openvalve">开阀</el-dropdown-item>
                <el-dropdown-item @click.native="Closevalve">关阀</el-dropdown-item>
                <el-dropdown-item @click.native="Callvalve">报警关阀</el-dropdown-item>
                <el-dropdown-item @click.native="Testvalve">阀门测试</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div><el-button @click="zqiosbtn">周期上传参数</el-button></div>
        <div><el-button @click="fixedbtn">定点上传参数</el-button></div>
        <div><el-button @click="Dailybtn">日冻结参数</el-button></div>
        <div><el-button @click="Monthlybtn">月冻结参数</el-button></div>
        <div><el-button @click="ridojie">日冻结数据</el-button></div>
        <div><el-button @click="yuedata">月冻结数据</el-button></div>
        <div><el-button @click="quyuDaochu">区域导出</el-button></div>
        <!-- <div><el-button>报警上传参数</el-button></div> -->
    </div>
    <!-- 按钮区域 -->
    <!-- <div class="ButtonArea">
        <div><el-button @click="ridojie">日冻结数据</el-button></div>
        <div><el-button @click="yuedata">月冻结数据</el-button></div>
    </div> -->
    <!-- 内容区域pc端 -->
    <div class="tab">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="parData"
        tooltip-effect="dark"
        style="width: 100%"
        height='500'
        highlight-current-row
        @selection-change="handleSelectionChange"
        :row-class-name="rowStyle">
        <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column
        prop="pay_id"
        label="缴费编号"
        width="100">
        </el-table-column>
        <el-table-column
        prop="meter_id"
        label="表号"
        width="150">
        </el-table-column>
        <el-table-column
        prop="username"
        label="用户名"
        width="240">
        </el-table-column>
        <el-table-column
        prop="balance"
        label="余额"
        width="140">
        </el-table-column>
        <el-table-column
        prop="address"
        label="位置"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="plotReceiveTime"
        label="最新上报时间"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="sumFlow"
        label="正向累积流量"
        width="140">
        </el-table-column>
        <el-table-column
        prop="netAccumulation"
        label="净累积流量"
        width="120">
        </el-table-column>
        <el-table-column
        prop="reverseAccumulation"
        label="反向累积流量"
        width="120">
        </el-table-column>
        <el-table-column
        prop="atAccumulation"
        label="瞬时流量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="batteryVoltage"
        label="电池电压"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="signallntensity"
        label="信号强度"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shiBaiShangChuanCiShu"
        label="上传失败次数"
        width="200"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="dingShiDongJieJianGe"
        label="定时冻结间隔"
        width="150"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="yaLiF"
        label="压力"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shuiWenF"
        label="水温"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerDown"
        label="掉电状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerLow"
        label="低压状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="valveStatus"
        label="阀门状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="magneticAttack"
        label="磁攻击"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        property="zhuangTaiChaiXieZhuangTai"
        label="拆卸状态" />
        <el-table-column
        prop="zhuangTaiLouShui"
        label="漏水"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiFanXiangJiLiang"
        label="反向计量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiLiuLiangChaoShangXian"
        label="流量超限"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
      fixed="right"
      label="操作"
      width="370">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="cxunbtn(scope.row)">
          查询设置进程
        </el-button>
        <el-button
          type="text"
          size="small"
          @click="history(scope.row)">
          查询历史记录
        </el-button>
        <el-button
        type="text"
          size="small"
          @click="YYQXbtn(scope.row)">
          用水曲线
        </el-button>
        <el-button
        type="text"
          size="small"
          @click="TablecodeBtn(scope.row)">
          录入表码
        </el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
    </div>
    <!-- 内容区域移动端 -->
    <div class="moveab">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="parData"
        tooltip-effect="dark"
        style="width: 100%"
        height='400'
        highlight-current-row
        @selection-change="handleSelectionChange"
        :row-class-name="rowStyle">
        <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column
        prop="pay_id"
        label="缴费编号"
        width="100"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="meter_id"
        label="表号"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="username"
        label="用户名"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="balance"
        label="余额"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="address"
        label="位置"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="plotReceiveTime"
        label="最新上报时间"
        width="120"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="sumFlow"
        width="120"
        label="正向累积流量">
        </el-table-column>
        <el-table-column
        width="100"
        label="净累积流量">
        <template slot-scope="scope">{{ scope.row.netAccumulation }}</template>
        </el-table-column>
        <el-table-column
        prop="reverseAccumulation"
        width="120"
        label="反向累积流量">
        </el-table-column>
        <el-table-column
        prop="atAccumulation"
        label="瞬时流量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="batteryVoltage"
        label="电池电压"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="signallntensity"
        label="信号强度"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shiBaiShangChuanCiShu"
        label="上传失败次数"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="dingShiDongJieJianGe"
        label="定时冻结间隔"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="yaLiF"
        label="压力"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shuiWenF"
        label="水温"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerDown"
        label="掉电状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerLow"
        label="低压状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="valveStatus"
        label="阀门状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="magneticAttack"
        label="磁攻击"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiLouShui"
        label="漏水"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiFanXiangJiLiang"
        label="反向计量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiLiuLiangChaoShangXian"
        label="流量超限"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column property="zhuangTaiChaiXieZhuangTai" label="拆卸状态" />
        <el-table-column
      label="操作"
      width="250">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="cxunbtn(scope.row)">
          查询设置进程
        </el-button>
        <el-button
          type="text"
          size="small"
          @click="history(scope.row)">
          查询历史记录
        </el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
    </div>
  <!-- 开阀弹框-->
  <div class="locationOpenvalve">
  <el-dialog title="开阀设备" :visible.sync="tianjiaVisible">
  <el-form>
    <el-form-item>
      <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="ValveControl.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button plain size="medium" @click="OpenCancel">取消</el-button>
    <el-button type="success" size="medium" @click="OpenSubmit">提交</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 关阀弹框-->
  <div class="locationOpenvalve">
  <el-dialog title="关阀设备" :visible.sync="CloseVisible">
  <el-form>
    <el-form-item>
      <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="ValveControl.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button plain size="medium" @click="OpenCancel">取消</el-button>
    <el-button type="success" size="medium" @click="OpenSubmit">提交</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 报警关阀弹框-->
  <div class="locationOpenvalve">
  <el-dialog title="报警关阀设备" :visible.sync="callCloseVisible">
  <el-form>
    <el-form-item>
      <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="ValveControl.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button plain size="medium" @click="OpenCancel">取消</el-button>
    <el-button type="success" size="medium" @click="OpenSubmit">提交</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 阀门测试弹框-->
  <div class="locationOpenvalve">
  <el-dialog title="阀门测试设备" :visible.sync="TestCloseVisible">
  <el-form>
    <el-form-item>
      <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="ValveControl.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button plain size="medium" @click="OpenCancel">取消</el-button>
    <el-button type="success" size="medium" @click="OpenSubmit">提交</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 查询设置进程 -->
  <!-- 编辑的弹框 -->
  <div class="cyclKai">
  <el-dialog title="查询进程" :visible.sync="dialgVsibles">
    <div class="wwoop"><span>设备编号: {{this.ccnn}}</span></div>
     <el-table
    :data="bansdata"
    border
    height='400'
    :header-cell-style="{'text-align':'center'}"
    :cell-style="{'text-align':'center'}"
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      width="70">
    </el-table-column>
    <el-table-column
      prop="meter_id"
      label="表具编号">
    </el-table-column>
    <el-table-column
      prop="command_type"
      label="操作内容"
      width="120">
    </el-table-column>
    <el-table-column
      prop="status"
      label="操作结果">
    </el-table-column>
    <el-table-column
      prop="create_time"
      label="创建时间">
    </el-table-column>
    <el-table-column
      prop="send_time"
      label="发送时间">
    </el-table-column>
    <el-table-column
      prop="run_time"
      label="完成时间">
    </el-table-column>
    <el-table-column
      prop="result"
      label="响应结果">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="processSizeChange"
      @current-change="processCurrentChange"
      :current-page="cuanss.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="cuanss.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="cuansstotal">
    </el-pagination>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 定点上传 -->
    <div class="cyclKai">
  <el-dialog title="定点上传" :visible.sync="fixedVsibles">
    <div class="Periodic">
    <div>当前勾选: {{ValveControl.lengthid}} 台设备</div>
    <el-checkbox v-model="fixesiso.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
    <div class="dingdianfas">
      <span>每</span>&nbsp;
      <el-select v-model="month" placeholder="月/日" @change="monthChange">
        <el-option
        v-for="item in montopns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <div class="y1" v-show="isyue">
      <span>的</span>&nbsp;
      <el-select v-model="dayval" multiple :multiple-limit='4' @change="dayChange" placeholder="日">
        <el-option
        v-for="item in dayopns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <span>的</span>&nbsp;
      <el-select v-model="hourval" @change="hourChange" placeholder="时">
        <el-option
        v-for="item in Houropns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <span>上传</span>
    </div>
    <!-- 选择日 -->
    <div class="y2" v-show="isday">
      <span>的</span>&nbsp;
      <el-select v-model="hourfsis" multiple :multiple-limit='4' @change="hoursChange" placeholder="时" size='small' style="width: 300px">
        <el-option
        v-for="item in hoursopns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <span>上传</span>
    </div>
    </div>
    <div class="zhuos">注: 如果后面不选择的话就取消定点上传功能</div>
    <div slot="footer" class="dialog-footer">
    <el-button plain size="medium" @click="faixvals">取消</el-button>
    <el-button type="success" size="medium" @click="faixbtn">提交</el-button>
  </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 周期上传-->
    <div class="cycle">
  <el-dialog title="周期上传" :visible.sync="zqiVsibles">
    <div class="Periodic">
      <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
      <el-checkbox v-model="Cycleall" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
    <div class="time">
      <span>上传时间：</span>
      <el-time-picker
        is-range
        @change="chanvalu"
        v-model="homemo"
        format="HH:mm"
        value-format="HH:mm"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
      >
  </el-time-picker>
  <el-button @click="zqibtn">提交</el-button>
    </div>
    <div style="margin-top: 15px;">
      <span>上传间隔：</span>
      <el-input-number v-model="interval.params.d2" controls-position="right" @change="handleChange" :min="10" :max="65536"></el-input-number>
      <el-button @click="jiangebtn">提交</el-button>
    </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 日冻结数据-->
    <div class="cycle">
  <el-dialog title="日冻结数据" :visible.sync="rdjVsibles">
    <div class="block">
    <el-date-picker
      v-model="rdais.day_date"
      type="date"
      @change='rekois'
      placeholder="选择日期">
    </el-date-picker>
    <el-button @click="fasidata">查询</el-button>
  </div>
  <div class="rdojie">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="redatas"
        tooltip-effect="dark"
        style="width: 100%"
        height='300'
        size=mini>
        <el-table-column
        prop="leiJiLiuLiangZhengXiangF"
        label="正向累积流量"
        width="100">
        </el-table-column>
        <el-table-column
        prop="lastDongJieShiJian"
        label="冻结时间"
        width="150">
        </el-table-column>
        <el-table-column
        prop="meterId"
        label="表号"
        width="140">
        </el-table-column>
        <el-table-column
        prop="lastUploadDate"
        label="上传日期"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangF"
        label="净累积流量"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangFanXiangF"
        label="反向累积流量"
        width="140">
        </el-table-column>
    </el-table>
      </el-card>
    </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 月冻结数据-->
    <div class="cycle">
  <el-dialog title="月冻结数据" :visible.sync="yueVsibles">
    <div class="block">
    <el-date-picker
      type="month"
      v-model="rdais.month_date"
      @change='er'
      placeholder="选择月">
    </el-date-picker>
    <el-button @click="residata">查询</el-button>
  </div>
  <div class="rdojie">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="yuedatas"
        tooltip-effect="dark"
        style="width: 100%"
        height='300'
        size=mini>
        <el-table-column
        prop="meterId"
        label="表号"
        width="140">
        </el-table-column>
        <el-table-column
        prop="lastDongJieShiJian"
        label="冻结时间"
        width="150">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangF"
        label="净累积流量"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangZhengXiangF"
        label="正向累积流量"
        width="100">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangFanXiangF"
        label="反向累积流量"
        width="140">
        </el-table-column>
        <el-table-column
        prop="lastUploadDate"
        label="上传日期"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
    </el-table>
      </el-card>
    </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 日冻结数据-->
    <div class="cycle">
  <el-dialog title="日冻结参数" :visible.sync="DailVsibles">
    <div class="Periodic">
    <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    <el-checkbox v-model="fixesiso.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
    <div class="block">
      <span>日冻结时间：</span>
    <el-time-picker
    v-model="Dailyparameters"
    format="HH:mm"
    value-format="HH:mm"
    @change='Dailymeters'
    size="medium"
    placeholder="选择时间">
  </el-time-picker>
    <el-button @click="Dailyparametersbtn" size="medium">提交</el-button>
  </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 月冻结数据-->
    <div class="cycle">
  <el-dialog title="月冻结参数" :visible.sync="MonthlyVsibles">
    <div class="Periodic">
    <span>当前勾选: {{ValveControl.lengthid}} 台设备</span>
    <el-checkbox v-model="fixesiso.regional_issued" @change="handleCheckedCitiesChange">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
    <div class="block">
    <span>月冻结时间：</span>
    <el-select v-model="months" @change="DailyChange" placeholder="日" size='small'>
        <el-option
        v-for="item in dayopns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <span>的</span>&nbsp;
      <el-select v-model="Hours" @change="HourChange" placeholder="时" size='small'>
        <el-option
        v-for="item in Houropns"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>&nbsp;
    <el-button @click="Monthlyfreeze" size="medium">提交</el-button>
  </div>
    </el-dialog>
  </div>
    <!-- 编辑的弹框 历史纪录-->
    <div class="cyclKai">
  <el-dialog title="历史记录" :visible.sync="historyVsibles">
    <div class="aaaaaaaaaa">
    <el-table :data="tableData" size=mini height='300' tooltip-effect="dark" highlight-current-row :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" element-loading-text="拼命加载中">
    <el-table-column type="index" width="50" label="序号" />
    <el-table-column property="meterId" label="表具编号" width="171" />
    <el-table-column property="sumFlow" label="累计总用量" width="100" :show-overflow-tooltip="true" />
    <el-table-column property="unit" label="单位" width="100" />
    <el-table-column property="plotReceiveTime" label="抄表时间" width="171" :show-overflow-tooltip="true" />
    <el-table-column property="netAccumulation" label="净累积流量" width="100" />
    <el-table-column property="reverseAccumulation" label="反向累积流量" width="100" />
    <el-table-column property="atAccumulation" label="瞬时流量" width="100" />
    <el-table-column property="batteryVoltage" label="电池电压" width="100" />
    <el-table-column property="signallntensity" label="信号强度" width="100" />
    <el-table-column
        prop="shiBaiShangChuanCiShu"
        label="上传失败次数"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="dingShiDongJieJianGe"
        label="定时冻结间隔"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="yaLiF"
        label="压力"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shuiWenF"
        label="水温"
        show-overflow-tooltip>
        </el-table-column>
    <el-table-column property="powerDown" label="掉电状态" width="100" />
    <el-table-column property="powerLow" label="低压状态" width="100" />
    <el-table-column property="magneticAttack" label="磁攻击" width="100" />
    <el-table-column property="valveStatus" label="阀门状态" width="172" />
    <el-table-column property="zhuangTaiChaiXieZhuangTai" label="拆卸状态" />
    <el-table-column
        prop="zhuangTaiLouShui"
        label="漏水"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiFanXiangJiLiang"
        label="反向计量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiLiuLiangChaoShangXian"
        label="流量超限"
        show-overflow-tooltip>
        </el-table-column>
    </el-table>
    <el-pagination
      @size-change="watefamlsSizeChange"
      @current-change="watefamlsCurrentChange"
      :current-page="watefamls.pagenum"
      :page-sizes="[10, 20, 40, 60, 70]"
      :page-size="watefamls.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="watefamlstotal">
    </el-pagination>
    </div>
    </el-dialog>
  </div>
    <!-- 暂无数据 -->
    <div class="wuju" v-show="zanwu">暂无数据</div>
    <!-- 用水曲线 -->
     <div class="yyqxcals">
  <el-dialog title="用水曲线" :visible.sync="YYQXVsibles" width="70%" :before-close="handleClose" >
    <div class="block">
    <el-date-picker
      v-model="YYQXdes.search_time"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
    </el-date-picker>
    <el-button @click="YYCXbtn">查询</el-button>
  </div>
  <div class="rdojie" v-show="yycxshow">
      <el-card>
        <EchaSDLL :message="timeheflow"/>
      </el-card>
    </div>
    </el-dialog>
  </div>
   <!-- 录入表码 -->
   <div class="yyqxcals">
  <el-dialog title="录入表码数" :visible.sync="TableCold" width="20%">
    <div style="display: flex; align-items: center;">
      <span style="width: 60px;">表码数:</span><el-input style="width: 50%;" v-model.trim="tabcode.flow"/>
    </div>
    <div slot="footer" class="dialog-footer">
    <el-button style="background-color: #744dfe; color: #fff;" size="small" @click="GinBaous">保存</el-button>
  </div>
    </el-dialog>
  </div>
  </div>
</template>
<script>
import { dataConversionUtil } from '@/utils/data2Excel'
import EchaSDLL from './echartsSDLL.vue'

export default {
  components: {
    EchaSDLL
  },
  inject: ['reload'],
  data () {
    return {
      TableCold: false,
      tabcode: {},
      // 用水曲线
      YYQXVsibles: false,
      yycxshow: false,
      timeheflow: {},
      aioid: null,
      YYQXdes: {
        token: null,
        meter_id: null,
        search_time: null
      },
      Cycleall: null,
      // table数据
      tableData: [{
        meterId: null,
        sumFlow: null,
        unit: null,
        insertTime: null,
        valveStatus: null
      }],
      historyVsibles: false,
      months: null,
      Hours: null,
      Dailyparameters: null,
      valuess1: null,
      MonthlyVsibles: false,
      DailVsibles: false,
      homemo: null,
      value1: null,
      yueVsibles: null,
      rdj: null,
      rdjVsibles: false,
      hms: [new Date(2022, 9, 10, 8, 40), new Date(2022, 9, 10, 9, 40)],
      num: 10,
      zqiVsibles: false,
      isday: false,
      isyue: false,
      bansdata: [],
      fixedVsibles: false,
      dialgVsibles: false,
      TestCloseVisible: false,
      callCloseVisible: false,
      tianjiaVisible: false,
      CloseVisible: false,
      // 暂无数据
      zanwu: false,
      // 月冻结数据
      yuedatas: [],
      redatas: [],
      // 定点上传提交
      fixesiso: {
        token: null,
        optionId: 5,
        regional_issued: false,
        meter_id_list: [],
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        params: {
          d1: null,
          d2: ''
        }
      },
      fansd: [],
      huoers: null,
      // 周期上传间隔
      interval: {
        token: null,
        optionId: 5,
        regional_issued: false,
        meter_id_list: [],
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        params: {
          d1: null,
          d2: null
        }
      },
      // 定点上传月
      month: '',
      montopns: [{
        id: 1,
        name: '月'
      }, {
        id: 2,
        name: '日'
      }],
      // 定点上传日
      dayval: '',
      dayopns: [{
        id: 1,
        name: '1日'
      }, {
        id: 2,
        name: '2日'
      },
      {
        id: 3,
        name: '3日'
      }, {
        id: 4,
        name: '4日'
      }, {
        id: 5,
        name: '5日'
      }, {
        id: 6,
        name: '6日'
      }, {
        id: 7,
        name: '7日'
      }, {
        id: 8,
        name: '8日'
      }, {
        id: 9,
        name: '9日'
      }, {
        id: 10,
        name: '10日'
      }, {
        id: 11,
        name: '11日'
      }, {
        id: 12,
        name: '12日'
      }, {
        id: 13,
        name: '13日'
      }, {
        id: 14,
        name: '14日'
      }, {
        id: 15,
        name: '15日'
      }, {
        id: 16,
        name: '16日'
      }, {
        id: 17,
        name: '17日'
      }, {
        id: 18,
        name: '18日'
      }, {
        id: 19,
        name: '19日'
      }, {
        id: 20,
        name: '20日'
      }, {
        id: 21,
        name: '21日'
      }, {
        id: 22,
        name: '22日'
      }, {
        id: 23,
        name: '23日'
      }, {
        id: 24,
        name: '24日'
      }, {
        id: 25,
        name: '25日'
      }, {
        id: 26,
        name: '26日'
      }, {
        id: 27,
        name: '27日'
      }, {
        id: 28,
        name: '28日'
      }, {
        id: 29,
        name: '29日'
      }, {
        id: 30,
        name: '30日'
      }, {
        id: 31,
        name: '31日'
      }],
      // 定点上传月
      hourval: '',
      Houropns: [
        {
          id: 0,
          name: '0时'
        }, {
          id: 1,
          name: '1时'
        }, {
          id: 2,
          name: '2时'
        },
        {
          id: 3,
          name: '3时'
        }, {
          id: 4,
          name: '4时'
        }, {
          id: 5,
          name: '5时'
        }, {
          id: 6,
          name: '6时'
        }, {
          id: 7,
          name: '7时'
        }, {
          id: 8,
          name: '8时'
        }, {
          id: 9,
          name: '9时'
        }, {
          id: 10,
          name: '10时'
        }, {
          id: 11,
          name: '11时'
        }, {
          id: 12,
          name: '12时'
        }, {
          id: 13,
          name: '13时'
        }, {
          id: 14,
          name: '14时'
        }, {
          id: 15,
          name: '15时'
        }, {
          id: 16,
          name: '16时'
        }, {
          id: 17,
          name: '17时'
        }, {
          id: 18,
          name: '18时'
        }, {
          id: 19,
          name: '19时'
        }, {
          id: 20,
          name: '20时'
        }, {
          id: 21,
          name: '21时'
        }, {
          id: 22,
          name: '22时'
        }, {
          id: 23,
          name: '23时'
        }],
      // 定点上传日、时
      hourfsis: '',
      hoursopns: [
        {
          id: 0,
          name: '0时'
        }, {
          id: 1,
          name: '1时'
        }, {
          id: 2,
          name: '2时'
        },
        {
          id: 3,
          name: '3时'
        }, {
          id: 4,
          name: '4时'
        }, {
          id: 5,
          name: '5时'
        }, {
          id: 6,
          name: '6时'
        }, {
          id: 7,
          name: '7时'
        }, {
          id: 8,
          name: '8时'
        }, {
          id: 9,
          name: '9时'
        }, {
          id: 10,
          name: '10时'
        }, {
          id: 11,
          name: '11时'
        }, {
          id: 12,
          name: '12时'
        }, {
          id: 13,
          name: '13时'
        }, {
          id: 14,
          name: '14时'
        }, {
          id: 15,
          name: '15时'
        }, {
          id: 16,
          name: '16时'
        }, {
          id: 17,
          name: '17时'
        }, {
          id: 18,
          name: '18时'
        }, {
          id: 19,
          name: '19时'
        }, {
          id: 20,
          name: '20时'
        }, {
          id: 21,
          name: '21时'
        }, {
          id: 22,
          name: '22时'
        }, {
          id: 23,
          name: '23时'
        }],
      // 添加
      bushuData: {
        address: null,
        location: null,
        meter: null,
        name: null,
        pay_id: null,
        phone: null
      },
      // 主体数据
      parData: [{
        address: null,
        balance: null,
        meter_id: null,
        meter_status: null,
        meter_type: null,
        name: null,
        pay_id: null,
        phone: null,
        water_type: null
      }],
      famlis: {
        token: null,
        pagesize: 20,
        pagenum: 1,
        search_content: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null
      },
      total: null,
      cuansstotal: null,
      userBmfi: {
        project_id: null,
        token: null,
        pay_id: null
      },
      project_id: null,
      userfima: {
        project_id: null,
        pay_id: null,
        token: null,
        meter_id: 0
      },
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      zaisns: [{
        id: 1,
        name: null
      }],
      cxyts: {
        project_id: null,
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null,
        insufficient_balance: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        pagenum: null,
        pagesize: null
      },
      ValveControl: {
        token: null,
        regional_issued: false,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        meter_id_list: [],
        lengthid: null,
        optionId: 1,
        params: {
          d1: null
        }
      },
      ccnn: null,
      ccoo: null,
      ooo: null,
      fff: null,
      ioio: null,
      ishuor: null,
      isdayst: null,
      uuu: null,
      list: [],
      cuanss: {
        token: null,
        meter_id: null,
        pagesize: 20,
        pagenum: 1
      },
      rdais: {
        token: null,
        meter_id_list: [],
        day_date: null,
        month_date: null
      },
      currentDateStart: null,
      shijainfa: null,
      hompp: null,
      homii: null,
      aaa: null,
      Dailws: '',
      Hourws: '',
      Dayedes: null,
      watefamlstotal: null,
      watefamls: {
        meter_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 10
      },
      ids: [],
      // 区域导出
      qudaoCans: {
        token: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        pagesize: null,
        pagenum: 1
      },
      today: new Date(),
      formattedDate: null
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.userBmfi.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userBmfi.token = this.$store.state.print.goodsForeNo.token
    this.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userData()
    this.fasdData()
    this.watefData()
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
    // this.CxunR()
  },
  methods: {
  // 录入表码
    TablecodeBtn (row) {
      console.log(row)
      this.tabcode.meter_id = row.meter_id
      this.TableCold = true
    },
    GinBaous () {
      this.tabcode.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterSetFlow(this.tabcode).then((res) => {
        if (res.data.status === 'success') {
          this.tabcode.flow = null
          this.TableCold = false
          this.$message.success(res.data.message)
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
    codeose () {
    },
    yesterday () {
      const yesterday = new Date(this.today)
      yesterday.setDate(this.today.getDate() - 1)
      return yesterday
    },
    // 查看用水曲线
    YYQXbtn (row) {
      console.log(row)
      this.aioid = row.meter_id
      this.YYQXdes.token = this.$store.state.print.goodsForeNo.token
      this.YYQXdes.meter_id = row.meter_id
      this.YYQXVsibles = true
      console.log(this.yesterday())
      const date = new Date(this.yesterday())
      const formattedDate = `${date.toISOString().slice(0, 10).replace(/-/g, '')}`.replace(/(.{4})(.{2})(.{2})/, '$1-$2-$3')
      this.YYQXdes.search_time = formattedDate
      console.log(this.YYQXdes.search_time, '编译后的时间') // 输出: 2024-01-30
    },
    handleClose (done) {
      this.YYQXVsibles = false
      this.yycxshow = false
      this.YYQXdes.search_time = null
    },
    // 用水查询按钮
    YYCXbtn () {
      console.log(this.YYQXdes)
      this.$api.waterMeter.BasedTraffic(this.YYQXdes).then((res) => {
        if (res.data.status === 'success') {
          console.log(res.data.data, '曲线数据')
          this.timeheflow = res.data.data
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
      this.yycxshow = true
    },
    // 区域导出
    quyuDaochu () {
      this.qudaoCans.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.qudaoCans).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '水表接收与设置区域导出数据')
          var data = res.data.data
          var tableHeader = [
            ['序号', '缴费编号', '表号', '用户名', '余额', '位置', '最新上报时间', '正向累积流量', '净累积流量', '反向累积流量', '瞬时流量', '电池电压', '信号强度', '掉电状态', '低压状态', '阀门状态', '磁攻击']
          ]
          var dataList = []
          data.forEach((item, index) => {
            dataList.push([index + 1, item.pay_id, item.meter_id, item.username, item.balance, item.address, item.plotReceiveTime, item.sumFlow, item.netAccumulation, item.reverseAccumulation,
              item.atAccumulation, item.batteryVoltage, item.signallntensity, item.powerDown, item.powerLow, item.valveStatus, item.magneticAttack])
          })
          dataConversionUtil.dataToExcel('水表接收与设置区域信息表', tableHeader, dataList)
          this.$message.success('导出成功!')
          this.qudaoCans.community_id = null
          this.qudaoCans.building_id = null
          this.qudaoCans.unit_id = null
          this.qudaoCans.room_id = null
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
    // 月冻结参数
    Monthlyfreeze () {
      // 选择日
      /*
      if (this.Dailws < 10) {
        this.Dailws = '0' + this.Dailws
      }
      console.log(this.Dailws)
      // 选择时
      if (this.Hourws < 10) {
        this.Hourws = '0' + this.Hourws
      }
      console.log(this.Hourws)
      */
      console.log(this.Dailws.toString().padStart(2, '0') + this.Hourws.toString().padStart(2, '0'))
      // console.log(this.Dailws.padStart() + this.Hourws.padStart())
      // this.Dayedes = this.Dailws.toString() + this.Hourws.toString()
      this.fixesiso.params.d2 = this.Dailws.toString().padStart(2, '0') + this.Hourws.toString().padStart(2, '0')
      this.fixesiso.token = this.$store.state.print.goodsForeNo.token
      this.fixesiso.params.d1 = '0023'
      this.$api.waterMeter.putdeviceCommand(this.fixesiso).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '上传间隔')
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
      this.MonthlyVsibles = false
      this.reload()
    },
    Monthlybtn () {
      this.MonthlyVsibles = true
    },
    DailyChange (val) {
      this.Dailws = val
      console.log(this.Dailws)
    },
    HourChange (val) {
      this.Hourws = val
      console.log(this.Hourws)
    },
    // 日冻结参数
    Dailybtn () {
      this.DailVsibles = true
    },
    Dailymeters (val) {
      console.log(val)
      this.Dailyparameters = val
    },
    Dailyparametersbtn () {
      this.fixesiso.params.d2 = this.Dailyparameters.replace(/:/g, '')
      this.fixesiso.token = this.$store.state.print.goodsForeNo.token
      this.fixesiso.params.d1 = '0123'
      this.$api.waterMeter.putdeviceCommand(this.fixesiso).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '上传间隔')
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.DailVsibles = false
      this.reload()
    },
    // 日期确定---开始时间
    // 标准时间转年月日
    formreDate (date) {
      console.log('data=', date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    rekois (data) {
      console.log('日期确定触发', data)
      this.currentDateStart = this.formatDate(data) // 将获取到的时间调用formatDate方法转换成年月日
      console.log('this.currentDateStart=', this.currentDateStart)
      this.rdais.day_date = this.currentDateStart
    //   this.valueStart = this.currentDateStart,
    //   this.valueEnd = ''
    //   this.showStart = false
    },
    // 月冻结数据
    residata () {
      this.rdais.token = this.$store.state.print.goodsForeNo.token
      this.rdais.day_date = null
      this.$api.waterMeter.getdeviceFrozenData(this.rdais).then((res) => {
        console.log(res, 'res')
        if (res.data.status === 'success') {
          this.yuedatas = res.data.data
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 日期确定---开始时间
    // 标准时间转年月日
    formatDate (date) {
      console.log('data=', date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    er (data) {
      console.log('日期确定触发', data)
      this.currentDateStart = this.formatDate(data) // 将获取到的时间调用formatDate方法转换成年月日
      console.log('this.currentDateStart=', this.currentDateStart)
      this.rdais.month_date = this.currentDateStart
    //   this.valueStart = this.currentDateStart,
    //   this.valueEnd = ''
    //   this.showStart = false
    },
    fixedbtn () {
      this.fixedVsibles = true
    },
    // 日冻结数据
    fasidata () {
      this.rdais.token = this.$store.state.print.goodsForeNo.token
      this.rdais.month_date = null
      this.$api.waterMeter.getdeviceFrozenData(this.rdais).then((res) => {
        console.log(res, 'res')
        if (res.data.status === 'success') {
          this.redatas = res.data.data
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    yuedata () {
      this.yueVsibles = true
    },
    ridojie () {
      this.rdjVsibles = true
    },
    // 周期上传
    zqiosbtn () {
      this.zqiVsibles = true
    },
    // 上传间隔
    jiangebtn () {
      this.interval.token = this.$store.state.print.goodsForeNo.token
      this.interval.optionId = 5
      this.interval.params.d1 = '1123'
      this.$api.waterMeter.putdeviceCommand(this.interval).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '上传间隔')
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.reload()
    },
    // 周期时间
    zqibtn () {
      console.log(this.homemo[0], 'dd2')
      this.hompp = this.homemo[0]
      console.log(this.homemo[1], 'dd2')
      this.homii = this.homemo[1]
      this.aaa = this.hompp + ';' + this.homii + ';' + '05'
      // this.fixesiso.params.d2 = this.aaa.split(':').join('') //和下面两种方法都可以 左右去掉对象中的:号
      this.fixesiso.params.d2 = this.aaa.replace(/:/g, '')
      console.log(this.fixesiso.params.d2, 'this.fixesiso.params.d2')
      this.fixesiso.token = this.$store.state.print.goodsForeNo.token
      this.fixesiso.params.d1 = '1223'
      this.$api.waterMeter.putdeviceCommand(this.fixesiso).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '上传间隔')
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
    chanvalu (val) {
      console.log(val, 'ww')
      // val.forEach((item, index) => {
      //   this.shijainfa = item
      //   console.log(this.shijainfa)
      // })
      this.homemo = val
      console.log(this.homemo, 'homemo')
      // this.fixesiso.params.d2 = this.homemo
      // console.log(this.fixesiso.params.d2, 'this.fixesiso.params.d2')
    },
    handleChange (val) {
      console.log(val)
      this.this.fixesiso.params.d2 = val
    },
    // 定点上传参数
    // 提交按钮
    faixbtn () {
      this.fixesiso.token = this.$store.state.print.goodsForeNo.token
      // d1的参数
      var array = ['1124', '1224', '1324', '1424']
      // 选择日
      console.log(this.fansd, 'fansd')
      this.fansd.forEach((item, index) => {
        this.fff = item
        console.log(this.fff, 'this.fff') // 输出数组的每一个元素
      })
      // 选择时的
      // if (this.huoers < 10) {
      //   this.huoers = '0' + this.huoers
      // }
      // console.log(this.huoers, 'this.huoers')
      //
      var arrsy = [0, 1, 2, 3]
      // this.lengths = this.fansd.length-1
      arrsy.forEach((item, index) => {
        console.log(item)
        console.log(array[item], 'array[item]')
        this.fixesiso.params.d1 = array[item]
        console.log(this.fansd[item], 'this.fansd[item]')
        this.ishuor = this.fansd[item]
        // if (this.ishuor < 10) {
        //   this.ishuor = '0' + this.ishuor
        // }
        // console.log(this.ishuor, 'this.ishuor')
        if (this.isyue) {
          this.isdayst = this.ishuor.toString().padStart(2, '0') + this.huoers.toString().padStart(2, '0')
        } else {
          this.isdayst = this.ishuor.toString().padStart(4, '0')
        }
        if (index > this.fansd.length - 1) {
          this.isdayst = '9900'
        }
        console.log(this.isdayst, 'isdayst')
        this.fixesiso.params.d2 = this.isdayst

        this.$api.waterMeter.putdeviceCommand(this.fixesiso).then((res) => {
          if (item === this.fansd.length - 1) {
            if (res.data.status === 'success') {
              console.log(res, '定点提交')
              this.$notify({
                title: '成功',
                message: res.data.message,
                type: 'success'
              })
            } else if (res.data.status === 'error') {
              this.$message.error(res.data.message)
            }
          }
          this.fixedVsibles = this.false
        })
      })
      this.reload()
      // array.map((item, index) => {
      //   console.log(item, index, 'item')
      //   this.list.push(item.toString().padStart(2, '0') + this.huoers.toString().padStart(2, '0'))
      //   this.fixesiso.params.d1 = this.list
      //   console.log(this.fixesiso.params.d1, 'list')
      // })
    },
    // 取消按钮
    faixvals () {
      this.fixedVsibles = false
    },
    // 选择月/日
    monthChange (val) {
      console.log(val, '月/日')
      if (val !== 1) {
        this.isyue = false
        this.isday = true
      } else {
        this.isyue = true
        this.isday = false
      }
    },
    // 月，日
    dayChange (val) {
      console.log(val, 'ff')
      this.fansd = val
    },
    // 月，时
    hourChange (val) {
      console.log(val, 'ggg')
      this.huoers = val
    },
    // 日、时
    hoursChange (val) {
      console.log(val, 'hhh')
      this.fansd = val
    },
    // 刷新
    Rt () {
      console.log(23)
      // this.CxunR()
    },
    // 查询历史纪录
    history (row) {
      console.log(row)
      this.ccoo = row.meter_id
      this.historyVsibles = true
      this.watefamls.token = this.$store.state.print.goodsForeNo.token
      this.watefamls.meter_id = this.ccoo
      this.$api.waterMeter.wateDatas(this.watefamls).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '历史记录')
          this.tableData = res.data.data
          this.watefamlstotal = res.data.total
        }
      })
    },
    // 查询设置进程
    cxunbtn (row) {
      this.dialgVsibles = true
      console.log(row, 'row')
      this.cuanss.token = this.$store.state.print.goodsForeNo.token
      this.ccnn = row.meter_id
      this.cuanss.meter_id = this.ccnn
      this.$api.waterMeter.getdeviceCommand(this.cuanss).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '查询设置进程')
          this.bansdata = res.data.data
          this.cuansstotal = res.data.total
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.bansdata = null
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      // this.CxunR()
    },
    CxunR () {
      this.$api.waterMeter.getdeviceCommand(this.cuanss).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '查询设置进程')
          this.bansdata = res.data.data
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 开阀取消
    OpenCancel () {
      this.tianjiaVisible = this.false
      this.CloseVisible = this.false
      this.callCloseVisible = false
      this.TestCloseVisible = false
      this.ValveControl.regional_issued = false
    },
    // 开阀提交
    OpenSubmit () {
      this.ValveControl.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.putdeviceCommand(this.ValveControl).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '阀门控制')
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
        this.tianjiaVisible = this.false
        this.CloseVisible = this.false
      })
      this.TestCloseVisible = false
    },
    // 全选按钮
    handleCheckedCitiesChange (val) {
      console.log(val, 'quanx')
      this.Cycleall = val
      this.interval.regional_issued = this.Cycleall
      this.fixesiso.regional_issued = this.Cycleall
    },
    // 开阀按钮
    Openvalve () {
      this.ValveControl.params.d1 = 28
      console.log(this.ValveControl.params.d1, 'button 开阀28')
      this.tianjiaVisible = true
    },
    // 关阀按钮
    Closevalve () {
      this.ValveControl.params.d1 = 26
      console.log(this.ValveControl.params.d1, 'button 关阀26')
      this.CloseVisible = true
    },
    // 报警关阀
    Callvalve () {
      this.ValveControl.params.d1 = 27
      console.log(this.ValveControl.params.d1, 'button 报警关阀27')
      this.callCloseVisible = true
    },
    // 阀门测试
    Testvalve () {
      this.ValveControl.params.d1 = 29
      console.log(this.ValveControl.params.d1, 'button 阀门测试29')
      this.TestCloseVisible = true
    },
    // table选择
    handleSelectionChange (val) {
      console.log(val, 'meter_id_list')
      this.ids = val.map(item => item.pay_id)
      // 选中的条数
      this.ValveControl.meter_id_list = val
      this.fixesiso.meter_id_list = val
      this.ValveControl.lengthid = val.length
      this.interval.meter_id_list = val
      this.rdais.meter_id_list = val
    },
    rowStyle ({ row, rowIndex }) {
      var arrs = this.ids
      for (let i = 0; i < arrs.length; i++) {
        console.log(row.pay_id, arrs[i], 'row.pay_id,arrs')
        if (row.pay_id === arrs[i]) {
          return 'rowStyle'
        }
      }
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.ValveControl.community_id = val
      this.interval.community_id = val
      this.fixesiso.community_id = val
      this.qudaoCans.community_id = val // 区域导出
      console.log(this.ValveControl.community_id, '给阀门控制id')
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        this.qudaoCans.pagesize = val.data.total // 区域导出
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.ValveControl.building_id = val
      this.interval.building_id = val
      this.fixesiso.building_id = val
      this.qudaoCans.building_id = val // 区域导出
      console.log(this.ValveControl.building_id, '给阀门控制id')
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        this.qudaoCans.pagesize = val.data.total // 区域导出
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.ValveControl.unit_id = val
      this.interval.unit_id = val
      this.fixesiso.unit_id = val
      this.qudaoCans.unit_id = val // 区域导出
      console.log(this.ValveControl.unit_id, '给阀门控制id')
      this.cxyts.room_id = null
      this.room()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        this.qudaoCans.pagesize = val.data.total // 区域导出
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
      this.ValveControl.room_id = val
      this.interval.room_id = val
      this.fixesiso.room_id = val
      this.qudaoCans.room_id = val // 区域导出
      console.log(this.ValveControl.room_id, '给阀门控制id')
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        this.qudaoCans.pagesize = val.data.total // 区域导出
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 查询
    pay_search () {
      console.log(1)
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        this.cxyts.pagesize = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 主体数据
    userData () {
      this.$api.waterMeter.getmeterTable(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.parData = res.data.data
          this.total = res.data.total
          this.qudaoCans.pagesize = res.data.total
          if (this.total === 0) {
            this.zanwu = true
          }
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    fasdData () {
      this.$api.waterMeter.getdeviceCommand(this.cuanss).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '查询设置进程')
          this.bansdata = res.data.data
          this.cuansstotal = res.data.total
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 查询历史纪录
    watefData () {
      this.$api.waterMeter.wateDatas(this.watefamls).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '历史记录')
          this.tableData = res.data.data
          this.watefamlstotal = res.data.total
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.community_id = this.cxyts.community_id
      this.famlis.building_id = this.cxyts.building_id
      this.famlis.unit_id = this.cxyts.unit_id
      this.famlis.room_id = this.cxyts.room_id
      this.userData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.community_id = this.cxyts.community_id
      this.famlis.building_id = this.cxyts.building_id
      this.famlis.unit_id = this.cxyts.unit_id
      this.famlis.room_id = this.cxyts.room_id
      this.userData()
    },
    // 查询设置进程
    processSizeChange (newSize) {
      console.log(newSize, 'q')
      this.cuanss.pagesize = newSize
      this.cuanss.meter_id = this.ccnn
      console.log(this.cuanss.meter_id)
      this.fasdData()
    },
    // 监听 页码值 改变的事件
    processCurrentChange (newPage) {
      console.log(newPage)
      this.cuanss.pagenum = newPage
      this.cuanss.meter_id = this.ccnn
      this.fasdData()
    },
    // 历史记录
    watefamlsSizeChange (newSize) {
      console.log(newSize, 'q11')
      this.watefamls.pagesize = newSize
      this.watefamls.meter_id = this.ccoo
      this.watefData()
    },
    // 监听 页码值 改变的事件
    watefamlsCurrentChange (newPage) {
      console.log(newPage, 'ddddddddddd')
      this.watefamls.pagenum = newPage
      this.watefamls.meter_id = this.ccoo
      this.watefData()
    }
  }
}

</script>

<style lang="less" scoped>
/deep/.rowStyle{
      background-color: oldlace !important;
    }
.T{
    padding: 0;
}
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  // width: 900px;
  padding: 20px 0px 0px 74px;
  // display: flex;
  // border: 1px solid #474747;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    .el-form-item{
      margin-bottom: 9px;
      display: flex;
      .ilo{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .ili{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-select{
      width: 150px;
    }
    }
  }
}
// 主体区域
.navto {
display: flex;
flex-wrap: wrap;
padding: 0px 0px 50px 57px;
.num {
    margin-top: 13px;
    margin-left: 17px;
    width: 368px;
    height: 253px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 1px rgba(116, 77, 254, 0.5);
    border-radius: 7px;
    border: solid 2px #c6c6c6;
    line-height: 31px;
  .iconfont {
    font-size: 30px;
    color: #744dfe;
  }
  .num1 {
      padding: 10px 10px;
      display: flex;
      align-items: center;
    span {
      margin-left: 20px;
      font-family: PingFangSC-Heavy;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 31px;
      letter-spacing: 2px;
      color: #474747;
    }
    .el-button {
      float: right;
      margin-left: 30px;
      background-color: #F5F5F5;
      color: #9FA2AE;
      border: 1px solid #DDD5FC;
    }
  }
  .numsop{
    font-family: PingFangSC-Heavy;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 2px;
    color: #656d92;
    padding-left: 60px;
  }
  .num2 {
    .num-1{
        padding: 0px 10px;
        .nui{
          margin-left: 19px;
        }
        span{
          font-family: MiSans-Normal;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 2px;
          color: #656d92;
        }
    }
    .nummane{
      line-height: 50px;
      padding-left: 60px;
      font-family: MiSans-Normal;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 2px;
      color: #656d92;
    }
    .num-2{
        text-align: center;
        padding: 10px 0px;
        color: #9194AF;
    }
    .num-3{
        padding: 3px 0px;
        span{
            padding: 0px 45px;
        }
    }
  }
  .numbtn{
      margin-top: 15px;
         border-top: 1px solid #DED6FF;
         padding: 5 px 0px;
         display: flex;
         justify-content: space-between;
     }
     .numbtns{
       margin-top: 10px;
        display: flex;
         justify-content: space-between;
       .el-button{
             width: 33%;
             border: none;
             border-right: 1px solid #DED6FF;
             font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 2px;
            // color: #474747;
         }
        .btnbang{
          background-color: #909399;
          color: #fff;
        }
         .el-button:nth-child(3){
             border-right: none;
         }
     }
}
}
// 分页
.feye{
  background-color: rgb(247, 245, 245);
  width: 1542px;
  height: 40px;
  position: fixed;
  right: 20px;
  bottom: 0;
}
// 暂无数据
.wuju{
  margin-left: 40%;
  margin-top: 15%;
}
// <!-- table表格 -->
    .tab{
        padding: 30px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
            ::v-deep .el-table th.el-table__cell{
              background-color: #987AFF;
            }
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
          background-color: #744dfe;
          color: #FFF;
          letter-spacing: 3PX;
        }
    }
    // 按钮区域
    .ButtonArea{
        display: flex;
        flex-wrap: wrap;
        margin-left: 54px;
        margin-top: 10px;
        .el-button{
            margin-left: 20px;
            background-color: #744dfe;
            color: #fff;
            letter-spacing: 4px;
            border: 0px;
        }
    }
    // 开阀操作
    .locationOpenvalve{
      .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
  }
}
      .el-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .el-form-item{
          width: 51%;
          span{
            color: #A9A9A9;
            font-size: 18px;
          }
          .el-checkbox{
            ::v-deep .el-checkbox__label{
               color: #FF9D0B;
              font-size: 18px;
            }
          }
        }
      }
    }
    // 查询进程
    .wwoop{
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      .el-icon-refresh-right{
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #928d8d;
        font-size: 16px;
      }
    }
    .zhuos{
      margin-top: 20px;
      color: brown;
    }
    // <!-- table表格 -->
    .rdojie{
      margin-top: 20px;
        // padding: 30px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
            ::v-deep .el-table th.el-table__cell{
              background-color: #987AFF;
            }
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
          background-color: #744dfe;
          color: #FFF;
          letter-spacing: 3PX;
        }
    }
    .Periodic{
      align-items: center;
      margin-bottom: 20px;
    }
    // 修改区域
    .elform{
    margin-left: 20px;
  }
  .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 30%;
    .Periodic{
        display: flex;
        justify-content: space-between;
      }
      .time{
        display: flex;
        align-items: center;
      }
      .block{
        display: flex;
        align-items: center;
        .el-select{
          width: 100px;
        }
      }
    }
  }
  }
  .cyclKai{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 40%;
    .Periodic{
        display: flex;
        justify-content: space-between;
      }
      .time{
        display: flex;
        align-items: center;
      }
      .block{
        display: flex;
        align-items: center;
        .el-select{
          width: 100px;
        }
      }
    }
  }
  }
  @media (min-width: 768px) {
    .formBtn{
      display: none;
    }
    .moveab{
      display: none;
    }
    }
    // 移动端
    @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .suso{
        padding: 10px 10px 0px 10px;
      }
      .tab{
        display: none;
      }
      .elform{
        margin-left: 0px;
      }
      .elfomobtn {
      display: none;
    }
    .el-form{
      .el-form-item{
        ::v-deep .el-form-item__content{
          display: flex;
        }
      }
  }
  .suso .el-form .el-form-item{
    margin-bottom: 2px;
  }
  .ButtonArea{
    margin-left: 0px;
    margin-top: 10px;
    .el-button{
      margin-left: 10px;
      background-color: #744dfe;
      color: #fff;
      letter-spacing: 2px;
      border: 0px;
      font-size: 12px;
      margin: 6px 4px;
      padding: 10px 14px;
    }
  }
  .moveab{
    margin: 10px 10px;
    .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
            ::v-deep .el-table th.el-table__cell{
              background-color: #987AFF;
            }
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
          background-color: #744dfe;
          color: #FFF;
          letter-spacing: 3PX;
        }
  }
  .locationOpenvalve {
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 92%;
    }
  }
    }
    .locationOpenvalve .el-form[data-v-9207be80]{
      display: flex;
      justify-content: flex-start;
    }
    .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 94%;
    .el-dialog__body{
      padding: 10px 4px;
      .Periodic{
        display: flex;
        justify-content: space-between;
      }
      .time{
        .el-range-editor.el-input__inner{
          width: 56%;
        }
      }
      .block{
        display: flex;
        align-items: center;
        .el-date-editor.el-input, .el-date-editor.el-input__inner{
          width: 52%;
        }
        .el-select{
          width: 80px;
        }
      }
      .el-pagination{
        margin-top: 4px;
        white-space: initial;
      }
    }
    }
  }
  }
  .yyqxcals{
    .el-dialog{
      width: 80%;
    }
  }
  .cyclKai{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 94%;
    .el-dialog__body{
      padding: 10px 4px;
      .Periodic{
        display: flex;
        justify-content: space-between;
      }
      .time{
        .el-range-editor.el-input__inner{
          width: 56%;
        }
      }
      .block{
        display: flex;
        align-items: center;
        .el-date-editor.el-input, .el-date-editor.el-input__inner{
          width: 52%;
        }
        .el-select{
          width: 80px;
        }
      }
      .el-pagination{
        margin-top: 4px;
        white-space: initial;
      }
    }
    }
  }
  }
    }
</style>
