<template>
    <div class="Login">
        <div class="num1">
            <div class="num1-i">
                <div class="num2-1">
                  <div><img src="@/views/Login/img/num5.png" alt=""></div>
                  <div class="tosiz">欢迎使用</div>
                </div>

                <div class="num2-2">
                  <div class="num2-2-1">智慧运维平台</div>

                  <el-form :model="formInline" ref="loginFormRef" :rules="loginFormRules">
                      <el-form-item prop="username">
                        <div class="num2-2-2">
                          <img src="@/views/Login/img/num3.png" alt="">
                          <el-input  v-model.trim="formInline.username" placeholder="请输入账户名称"></el-input>
                        </div>
                      </el-form-item>

                      <el-form-item prop="password">
                        <div class="num2-2-3">
                          <img src="@/views/Login/img/num4.png" alt="">
                          <el-input v-model.trim="formInline.password" placeholder="请输入登录密码" show-password></el-input>
                        </div>
                      </el-form-item>

                  </el-form>
                  <div class="num2-2-4">
                      <el-button @click.native.prevent="handleLogin">登录</el-button>
                  </div>
                </div>

            </div>

            <div class="num1-img">
                <img src="@/views/Login/img/num2.png" alt="">
            </div>

        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      goodsForeNo: null,
      formInline: {
        username: '',
        password: ''
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在3到10个字符', trigger: 'blur' }
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在5到15个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapMutations(['handleLogin']),
    handleLogin () {
      this.$refs.loginFormRef.validate(valid => {
        console.log(valid, 'valid')
      })
      this.$api.lonig.Logins(this.formInline).then((res) => {
        console.log(res, '登录')
        if (res.data.status === 'success') {
          window.sessionStorage.setItem('token', res.data.data.token)
          this.goodsForeNo = res.data.data
          this.$store.commit('print/STORE_GOODSFORENO', this.goodsForeNo)
          this.$router.push({ path: '/users' })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>

.Login{
    width: 100%;
    height: 100vh;
    background: url(../../views/Login/img/num1.png) no-repeat;
     position: fixed;
     background-size: 100% 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     .num1{
         border-radius: 10px;
         background-color: #fff;
         display: flex;
         justify-content: space-between;
         .num1-i{
             width: 50%;
             .num2-1{
                 width: 200px;
                 color: rgb(94, 90, 90);
                 padding: 20px 20px;
                 img{
                     width: 150px;
                 }
                 .tosiz{
                     margin-top: 10px;
                     font-size: 13px;
                     letter-spacing: 0.5em;
                 }
             }
             .num2-2{
                 width: 270px;
                 margin: 0 auto;
                 .num2-2-1{
                     margin-top: 20px;
                     width: 100%;
                     font-size: 30px;
                     color: #555;
                     letter-spacing: 0.5em;
                     text-align: center;
                 }
                 .num2-2-2{
                     margin-top: 40px;
                     width: 100%;
                     font-size: 10px;
                     color: #555;
                     letter-spacing: 0.5em;
                     text-align: center;
                     display: flex;
                     align-items: center;
                     img{
                         width: 30px;height: 30px;margin-right: 10px;
                     }
                 }
                 .num2-2-3{
                     margin-top: 30px;
                     width: 100%;
                     font-size: 10px;
                     color: #555;
                     letter-spacing: 0.5em;
                     text-align: center;
                     display: flex;
                     align-items: center;
                     img{
                         width: 30px;height: 30px;margin-right: 10px;
                     }
                 }
                 .num2-2-4{
                   margin-top: 80px;
                     font-size: 10px;
                     color: #555;
                     letter-spacing: 0.5em;
                     text-align: center;
                     .el-button{
                         width: 200px;
                         background-color: #744DFE;
                         color: #fff;
                     }
                 }
             }
         }

         .num1-img{
             width: 50%;
             img{
                width: 100%;
                height: 100%;
             }
         }
     }
}
  @media (max-width: 768px) {
    .num2-1{
      display: none;
    }
    .num1-img{
      display: none;
    }
     .Login{
      background: none;
    }
  }
</style>
