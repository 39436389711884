<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>阀控管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input placeholder="请输入表号" v-model="buosDasi.search_content"></el-input>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </el-form-item>
        <el-form-item>
          <span>用水类型:</span>&nbsp;&nbsp;
              <el-select placeholder="请选择用水类型" v-model="buosDasi.water_type_id">
                <el-option
                  v-for="item in withdown"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
        </el-form-item>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-form-item>
          <span>费用:</span>&nbsp;&nbsp;
              <el-select placeholder="请选择是否欠费" v-model="buosDasi.owing">
                <el-option
                  v-for="item in zaisns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 50px;">
          <el-button type="primary" @click="pay_search">查 询</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 20px;">
          <el-button type="primary" @click="shuaxin">重 置</el-button>
        </el-form-item>
        <!-- <el-form-item style="margin-left: 230px;">
          <el-button type="primary" @click="ViccdBtn">指令记录</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}" v-loading="loading" height='500'>
    <el-table-column type="index" width="40" />
    <el-table-column property="user.pay_id" label="缴费编号" width="200" />
    <el-table-column property="meter_id" label="水表编号" width="250" :show-overflow-tooltip="true" />
    <el-table-column property="user.name" label="用户姓名" width="200" />
    <el-table-column property="user.phone" label="联系方式" width="130" />
    <el-table-column property="user.address" label="位置信息" width="260" :show-overflow-tooltip="true" />
    <el-table-column property="balance" label="余额" width="100" />
    <el-table-column property="valveStatus" label="阀门状态" width="100" />
    <el-table-column property="valve" label="操作" width="245">
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)">开阀</el-button>
        <el-button size="mini" @click="opens(scope.row)">关阀</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      formLabelWidth: '100px',
      // 下拉框
      seldata: [],
      options: [
        {
          name: '金湖'
        }
      ],
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null,
        pagenum: 1,
        pagesize: 20,
        search_content: null,
        water_type_id: null,
        owing: null
      },
      tableSize: null,
      // 开阀
      valvefamiks: {
        project_id: null,
        token: null,
        meter_id: null,
        command_type: 'open'
      },
      // 关阀
      valvefamigs: {
        project_id: null,
        token: null,
        meter_id: null,
        command_type: 'close'
      },
      // 搜索
      buosDasi: {
        project_id: null,
        token: null,
        meter_id: null,
        not_bind: null,
        search_content: null,
        water_type_id: null,
        online: null
      },
      // 用水类型下拉框
      withdown: [{
        id: 1,
        name: null
      }],
      // 费用下拉框
      zaisns: [{
        id: 0,
        name: '不欠费'
      },
      {
        id: 1,
        name: '欠费'
      }],
      total: null
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
    this.waterdown()
  },
  methods: {
    shuaxin () {
      this.reload()
    },
    // 添加的用水类型下拉框
    waterdown () {
      this.$api.waterMeter.waterdowns(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '用水类型')
          this.withdown = res.data.data
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 查询
    pay_search () {
      this.buosDasi.project_id = this.$store.state.print.goodsForeNo.project_id
      this.buosDasi.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.cateXatas(this.buosDasi).then((res) => {
        console.log(res, '查询')
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    //   指令跳转
    ViccdBtn () {
      this.$router.push('/instruction')
    },
    // 开阀
    ficsati (row) {
      console.log(row.meter_id, '开阀')
      this.valvefamiks.meter_id = row.meter_id
      this.valvefamiks.project_id = this.$store.state.print.goodsForeNo.project_id
      this.valvefamiks.token = this.$store.state.print.goodsForeNo.token
      this.$confirm('此操作将把此水表开阀, 是否继续?', '开阀', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.valveMent.valaeks(this.valvefamiks).then((res) => {
          console.log(res, '开阀res')
          this.$message({
            type: 'success',
            message: '命令发送成功!'
          })
          this.tabData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消发送'
        })
      })
    },
    // 关阀
    opens (row) {
      console.log(row, '关阀')
      this.valvefamigs.meter_id = row.meter_id
      this.valvefamigs.project_id = this.$store.state.print.goodsForeNo.project_id
      this.valvefamigs.token = this.$store.state.print.goodsForeNo.token
      this.$confirm('此操作将把此水表关阀, 是否继续?', '关阀', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.valveMent.valaeks(this.valvefamigs).then((res) => {
          console.log(res, '开阀res')
          this.$message({
            type: 'success',
            message: '命令发送成功'
          })
          this.tabData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消发送'
        })
      })
    },
    // table数据
    tabData () {
      this.$api.waterMeter.meterdatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.total = res.data.total
          // 数组套数组循环遍历出 user 赋值给 tablerData
          // const arr = []
          // res.data.data.forEach(item => {
          //   arr.push(item.user)
          //   this.tableData = arr
          // })
          // console.log(arr, 'arr')

          // 赋值给tableData有的表没有用户数据给他分出来
          // const arr = []·
          // res.data.data.map(item => {
          //   if (item.user != null) {
          //     arr.push(item.user)
          //     arr[arr.length - 1].valueStatus = item.valueStatus
          //   }
          //   this.tableData = arr
          // })
          this.loading = false
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    handleSizeChange (newSize) {
      console.log(newSize, 'newSize')
      this.famlis.pagesize = newSize
      this.tabData()
    },
    handleCurrentChange (newPage) {
      console.log(newPage, 'newPagw')
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.buosDasi.search_content
      this.famlis.water_type_id = this.buosDasi.water_type_id
      this.famlis.owing = this.buosDasi.owing
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      color: #fff;
      border: 1px solid #744dfe;
    }
    .sele {
      width: 400px;
      margin-left: 50px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加提示
.locationAdd{
  .el-dialog{
      .el-input{
        width: 240px;
      }
      .el-button{
        background-color: #fff;
        border: 1px solid #744dfe;
        color: #000;
        border-radius: 7px;
        i{
          margin-right: 10px;
        }
      }
  }
}

// 缴费提示
  .el-dialog{
    .el-dialog__header{
      span.dialog__title{
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
    }
    }
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .diaonum{
        margin-left: 100px;
        // width: 324px;
        height: 123px;
        font-family: XinPingHeiTC-W2;
        font-size: 19px;
        // font-weight: bold;
        font-stretch: normal;
        line-height: 45px;
        letter-spacing: 4px;
        color: #484848;
        // border: 1px solid;
        .diaonsums{
          font-family: XinPingHeiTC-W2;
          font-size: 19px;
          // font-weight: bold;
          letter-spacing: 10px;
          color: #484848;
          span{
            font-family: XinPingHeiTC-W2;
            font-size: 19px;
            // font-weight: bold;
            letter-spacing: 4px;
            color: #484848;
          }
        }
      }
      .diaoinput{
        margin: 20px 0px 0px 100px;
        display: flex;
        align-items: center;
        span{
          font-family: BENMOJingyuan;
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 1px;
          color: #744dfe;
        }
        .el-input{
          width: 100px;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
      .dialog-footer{
        .el-button{
          font-size: 20px;
          i{
            margin-right: 10px;
            font-size: 24px;
          }
        }
      }
  }
</style>
