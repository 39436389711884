<template>
  <div class="about">
  <el-aside width="15%"><CommonAside/></el-aside>
    <el-header><containers/></el-header>
    <el-main><router-view/></el-main>
  </div>
</template>
<script>
import CommonAside from '../components/CommonAside/CommonAsides.vue'
import containers from '../components/container/container.vue'
export default {
  components: { CommonAside, containers },
  data () {
    return {}
  }
}
</script>
<style scoped>
.el-header{
    padding: 0;
    margin-left: 13.5%;
  background-color: #fff;
}
.el-header, .el-footer {
    /* background-color: #B3C0D1; */
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-aside {
    height: 100vh;
  position:fixed;
    background-color: #F5F9FC;
    color: #333;
    text-align: center;
    line-height: 60px;
    z-index: 999;
  }

  .el-main {
    width: 85%;
    margin-left: 15%;
    padding: 20px 0px;
    background-color: #fff;
  }
  .el-container{
    width: 1632px;
  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
  @media (max-width: 768px) {
  aside{
    display: none;
  }
  .el-header{
    /* display: none; */
    margin-left: 0px;
  }
  .el-main{
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    /* display: none; */
  }
}
</style>
