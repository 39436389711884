<template>
  <div>
    <!-- pc端 -->
    <div class="num">
        <div class="num1">{{this.project_title}}</div>
        <div class="num2">
            <span>用户名：{{this.username}}</span>
            <el-dropdown>
    <el-badge is-dot class="item" v-show="yesi">公告</el-badge>
    <el-badge class="item" v-show="muio">公告</el-badge>
  <el-dropdown-menu slot="dropdown">
    <el-table
    :data="tableData"
    style="width: 100%"
    max-height="250">
    <el-table-column
      fixed
      prop="message"
      label="公告"
      width="350">
    </el-table-column>
    <el-table-column
      fixed
      prop="create_time"
      label="发布时间"
      width="200">
    </el-table-column>
  </el-table>
  </el-dropdown-menu>
</el-dropdown>
            <div class="tcui">
              <span @click="tuichu">退出</span>
            </div>
        </div>
    </div>
    <!-- 移动端 -->
    <div class="move">
      <div class="text">{{this.project_title}}</div>
      <div class="VVEE">
      <div class="icons">
        <el-button type="info" icon="el-icon-s-operation" v-show="xinas" @click="xianshi"/>
        <el-button type="info" icon="el-icon-close" v-show="quxnas" @click="quxiao"/>
      </div>
      <div class="tuidt">
        <!-- <img src="@/components/container/img/igm2.png" alt="" @click="tuichu"> -->
        <el-dropdown trigger="click" @command="handleClick">
      <span class="el-dropdown-link">
        <img :src='`https://btmswaterbg.sdbina.com/api/v1/projectLogo?token=${token}`' alt="">
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="dengchu">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
      </div>
    </div>
    </div>
    <!-- 移动端》》导航菜单 -->
    <div class="menu" v-show="daohen">
      <el-menu
  :default-active="activePath"
  class="el-menu-demo"
  router
  @select="handleSelect"
  background-color="#545c64"
  text-color="#fff"
  active-text-color="#ffd04b">
  <el-menu-item :index="item.path" v-for="item in noChildren"
        :key="item.id" @click="saveNavState('/' + item.path)">
        <template>
        <span slot="title" style="font-size: 1rem;">{{item.authName}}</span>
        <!-- <i class="iconfont icon-dian" style="margin-left: 0px" v-show="icons"></i> -->
        </template>
      </el-menu-item>
        <el-submenu :index="'/' + item.path" v-for=" item in hasChilren "
         :key="item.id">
            <template slot="title">
              <span style="font-size: 1rem;">{{item.authName}}</span>
              <!-- <i class="iconfont icon-dian" style="margin-left: 40px" v-show="icons"></i> -->
            </template>
          <!-- 二级菜单 -->
            <el-menu-item :index="'/' + subItem.path" v-for="subItem in item.childern"
            :key="subItem.id" @click="saveNavState('/' + item.path)">
              <template>
                <!-- 图表 -->
                <i class=""></i>
                <!-- 文本 -->
                <span style="letter-spacing: 1px;">{{subItem.authName}}</span>
                <!-- <i class="iconfont icon-dian" style="margin-left: 40px" v-show="icons"></i> -->
              </template>
            </el-menu-item>
          </el-submenu>
</el-menu>
  </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      yesi: true,
      muio: false,
      // 移动端
      xinas: true,
      quxnas: false,
      daohen: false,
      activePath: '',
      menulist: [
        {
          id: 1,
          icon: 'iconfont icon-xiangmushouye1',
          authName: '项目首页',
          path: 'users'
        },
        {
          id: 4,
          icon: 'iconfont icon-caiwubaobiao',
          authName: '账务报表',
          path: 'h',
          childern: [
            {
              id: 301,
              authName: '充值记录',
              path: 'details'
            },
            {
              id: 302,
              authName: '账单流水',
              path: 'summary'
            },
            {
              id: 303,
              authName: '售水日报',
              path: 'sshuiyuebao'
            },
            {
              id: 304,
              authName: '售水月报',
              path: 'sshuiyuebaos'
            },
            {
              id: 305,
              authName: '售水年报',
              path: 'ssuinianbao'
            }
          ]
        },
        {
          id: 201,
          icon: 'iconfont icon-caiwuguanli',
          authName: '交款退款',
          path: 'accountMent'
        },
        {
          id: 2,
          icon: 'iconfont icon-jichuziliao',
          authName: '基础资料',
          path: 'a',
          childern: [
            {
              id: 101,
              authName: '用水类型',
              path: 'waterType'
            },
            {
              id: 102,
              authName: '位置信息',
              path: 'positionTion'
            },
            {
              id: 106,
              authName: '表具类型',
              path: 'Gaugetype'
            },
            {
              id: 103,
              authName: '水表档案',
              path: 'waterMeterFile'
            },
            {
              id: 105,
              authName: '阀控策略',
              path: 'strategy'
            }
          ]
        },
        {
          id: 901,
          icon: 'iconfont icon-shuibiaoguanli',
          authName: '抄收与设置',
          path: 'Parameter'
        },
        {
          id: 7,
          icon: 'iconfont icon-xitongguanli',
          authName: '系统管理',
          path: 'e',
          childern: [
            {
              id: 601,
              authName: '账户管理',
              path: 'userGement'
            },
            {
              id: 602,
              authName: '全局配置',
              path: 'globalRation'
            }
          ]
        }
      ],
      // pc端
      username: null,
      project_title: null,
      fasinull: {
        id: null,
        is_admin: null,
        project_id: null,
        project_name: null,
        token: null,
        username: null
      },
      buosDasi: {
        token: null
      },
      noties: {
        token: null
      },
      token: null,
      tableData: []
    }
  },
  created () {
    this.token = this.$store.state.print.goodsForeNo.token
    this.username = this.$store.state.print.goodsForeNo.username
    this.project_title = this.$store.state.print.goodsForeNo.project_title
    // 移动
    this.setCurrentRoute()
    this.activePath = window.sessionStorage.getItem('activePath')
    this.Xiaoxin()
  },
  // 移动
  watch: {
    $route () {
      this.setCurrentRoute()
    }
  },
  // 移动
  computed: {
    noChildren () {
      return this.menulist.filter((item) => !item.childern)
    },
    hasChilren () {
      return this.menulist.filter((item) => item.childern)
    }
  },
  methods: {
    handleClick (command) {
      if (command === 'dengchu') {
        this.buosDasi.token = this.$store.state.print.goodsForeNo.token
        this.$api.lonig.postwebUserLogout(this.buosDasi).then((res) => {
          if (res.data.status === 'success') {
            this.$router.push('/', this.fasinull)
            this.$notify({
              title: '成功',
              message: res.data.message,
              type: 'success'
            })
            window.sessionStorage.clear()
            console.log(window.sessionStorage, 'window.sessionStorage')
            var stotag = this.$store.state.print.goodsForeNo.token
            stotag.clear()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }
    },
    // PC端
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    tuichu () {
      this.buosDasi.token = this.$store.state.print.goodsForeNo.token
      this.$api.lonig.postwebUserLogout(this.buosDasi).then((res) => {
        console.log(res, '退出')
        if (res.data.status === 'success') {
          this.$router.push('/', this.fasinull)
          this.$notify({
            title: '成功',
            message: res.data.message,
            type: 'success'
          })
          window.sessionStorage.clear()
          console.log(window.sessionStorage, 'window.sessionStorage')
          var stotag = this.$store.state.print.goodsForeNo.token
          stotag.clear()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    Xiaoxin () {
      this.noties.token = this.$store.state.print.goodsForeNo.token
      this.$api.lonig.notifys(this.noties).then((res) => {
        console.log(res, '消息')
        if (res.data.status === 'success') {
          this.tableData = res.data.data
          if (res.data.total === 0) {
            this.muio = true
            this.yesi = false
          }
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 移动端
    // 保存链接的激活状态.。。》移动
    saveNavState (activePath) {
      console.log('dwdhwdh')
      this.daohen = false
      this.quxnas = false
      this.xinas = true
      this.show = !this.show
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    setCurrentRoute () {
      this.defaultActive = this.$route.name
    },
    xianshi () {
      this.quxnas = true
      this.xinas = false
      this.daohen = true
    },
    quxiao () {
      this.xinas = true
      this.quxnas = false
      this.daohen = false
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.num{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .num1{
        margin-left: 75px;
        letter-spacing: 20px;
        color: #7d7d7d;
        font-size: 2rem;
        // line-height: 90px;
        font-family: MicrosoftYaHeiUI-Bold;
    }
    .num2{
        position: relative;
        .yuo{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #7047FD;
            position: absolute;
            top: 28px;
            left: 110px;
        }
        display: flex;
        align-items: center;
        font-size: 14px;
        span{
            margin-right: 32px;
        }
        img{
            width: 32px;
            height: 32px;
            margin-right: 32px;
        }
    }
}
.move{
  padding: 0px 20px;
  color: #fff;
  background-color: #545C64;
  display: flex;
  justify-content: space-between;
   .VVEE{
      display: flex;
      .tuidt{
        // border: 1px solid;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          padding-top: 15px;
          margin-left: 10px;
          width: 50px;
          height: 40px;
        }
      }
    }
}
.menu{
  position: absolute;
  z-index: 999999;
  border-top: 1px solid #fff;
  background-color: #7047FD;
  .el-menu{
  background-color: #545C64;
  }
}
// 消息
.el-dropdown{
  width: 60px;
  height: 20%;
  display: flex;
  align-items: center;
}
.tcui{
  cursor:pointer;/*鼠标变小手*/
}
.el-badge{
  height: 100%;
  display: flex;
  align-items: center;
  cursor:pointer;/*鼠标变小手*/
}
// pc端
@media (min-width: 768px) {
  .move{
    display: none;
  }
  .menu{
    display: none;
  }
}
// 移动
@media (max-width: 768px) {
  .num{
    display: none;
  }
}
</style>
