<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>全局配置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='300'>
    <el-table-column property="name" label="名称" width="150" />
    <el-table-column property="address" label="地址" width="200" :show-overflow-tooltip="true" />
    <el-table-column property="mch_id" label="商户号" width="100" />
    <el-table-column property="wechat_appid" label="公众号id" width="200" />
    <el-table-column property="balance_tip_standard" label="余额不足标准" width="100" />
    <el-table-column property="valve" width="200" label="操作" >
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)">修改</el-button>
        <el-button size="mini" @click="uploadlogo = true">上传logo</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
  </div>
   <!-- 编辑的弹框 -->
   <div class="locationOpenvalve">
  <el-dialog title="账号编辑" :visible.sync="diaes" >
  <div class="spans"/>
  <el-form>
    <el-form-item label="项目名称:">
      <el-input v-model.trim="ficsData.name"></el-input>
    </el-form-item>
    <el-form-item label="项目地址:">
      <el-input v-model.trim="ficsData.address"></el-input>
    </el-form-item>
    <el-form-item label="余额不足剩余量:">
      <el-input v-model.trim="ficsData.balance_tip_standard" type="number" style="width: 100px;"></el-input>&nbsp;
      <span>元</span>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click=Biaos> <i class="el-icon-document"/>保 存</el-button>
  </span>
</el-dialog>
</div>
<!-- 上传logo-->
<div class="locationOpenvalve">
  <el-dialog title="上传logo" width="" :visible.sync="uploadlogo" >
    <form>
      <!-- accept="image/*"  -->
      <input type="file" ref="fileInput" accept="image/*" @change="handleFileUpload"/>
      <button type="button" v-show="vshaBn" @click="uploadFile">确定</button>
      <button type="button" v-show="svhaBtn" disabled>确定</button>
      <div style="color: brown; margin-top: 10px;">注：只能上传图片 且不得大于10MB</div>
    </form>
</el-dialog>
</div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      vshaBn: true,
      svhaBtn: false,
      file: null,
      imageUrl: null,
      uploadlogo: false,
      ficsData: {
        project_id: null,
        token: null,
        project_name: null,
        project_address: null,
        automatic_closing_valve_for_arrears: null,
        balance_tip_standard: null
      },
      diaes: false,
      // tab
      tableData: [{
        name: null,
        address: null,
        automatic_closing_valve_for_arrears: null,
        mch_id: null,
        wechat_appid: null,
        balance_tip_standard: null
      }],
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null
      },
      uploadHeaders: {
        token: null,
        image: null
      },
      // 费用下拉框
      zaisns: [{
        id: true,
        name: '是'
      },
      {
        id: false,
        name: '否'
      }]
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.uploadHeaders.token = this.$store.state.print.goodsForeNo.token
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    handleFileUpload () {
      console.log(this.$refs.fileInput.files[0].name.lastIndexOf('.'))
      const data = this.$refs.fileInput.files[0].name
      const index = this.$refs.fileInput.files[0].name.lastIndexOf('.')
      const jpg = data.substring(index + 1, data.length)
      const jpeg = data.substring(index + 1, data.length)
      const png = data.substring(index + 1, data.length)
      const gif = data.substring(index + 1, data.length)
      const svg = data.substring(index + 1, data.length)
      console.log(jpg)
      if (jpeg === 'jpeg' || jpg === 'jpg' || png === 'png' || gif === 'gif' || svg === 'svg' || svg === 'webp') {
        this.vshaBn = true
        this.svhaBtn = false
      } else {
        this.$message.error('请选择图片在进行上传')
        this.vshaBn = false
        this.svhaBtn = true
      }
      this.file = this.$refs.fileInput.files[0]
      this.uploadHeaders.image = this.file
      console.log(this.uploadHeaders.image)
    },
    uploadFile () {
      const formData = new FormData()
      formData.append('image', this.file)
      formData.append('token', this.uploadHeaders.token)
      this.$api.accountMent.projectLogos(formData).then((res) => {
        console.log(res)
        this.uploadlogo = this.false
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          setTimeout(() => {
            location.reload()
          }, 2000)
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 保存
    Biaos () {
      this.ficsData.project_id = this.$store.state.print.goodsForeNo.project_id
      this.ficsData.token = this.$store.state.print.goodsForeNo.token
      this.$api.accountMent.globalxius(this.ficsData).then((res) => {
        if (res.data.status === 'success') {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          console.log(res, '修改成功值')
          this.diaes = false
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 编辑
    ficsati (row) {
      console.log(row, '修改')
      this.ficsData = row
      console.log(this.ficsData)
      this.diaes = true
    },
    // table数据
    tabData () {
      console.log(this.famlis, ' this.famlis.project_id')
      this.$api.accountMent.globalConfigs(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res.data.data, '全局配置tab')
          this.tableData = res.data.data
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 50px 30px 0px 74px;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
    .el-dialog{
      .spans{
        width: 3px;
        height: 15px;
        background-color: #744dfe;
        display: inline-block;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .el-form{
        display: flex;
        flex-wrap: wrap;
        .el-form-item{
          display: flex;
          .el-input{
            width: 200px;
          }
          .el-select{
            width: 180px;
          }
        }
      }
      .dialog-footer{
        .el-button{
          background-color: #fff;
          border: 1px solid #744dfe;
          color: #575757;
          border-radius: 10px;
          font-size: 17px;
          span{
            i{
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
    // 移动端
    @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .tab{
        padding: 10px 10px;
      }
      .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 94%;
  }
}
    }
</style>
