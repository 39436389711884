import request from '../../utils/request'

// 用户管理
export function GementDatas (data) {
  return request({
    method: 'get',
    url: 'webUser',
    params: data
  })
}

// 添加
export function GementPuts (data) {
  return request({
    method: 'put',
    url: 'webUser',
    data
  })
}

// 修改
export function GementPosts (data) {
  return request({
    method: 'post',
    url: 'webUser',
    data
  })
}

// 删除
export function GementDeletes (data) {
  return request({
    method: 'DELETE',
    url: 'webUser',
    data
  })
}

// 获取预警推送
export function WechatAdmin (data) {
  return request({
    method: 'get',
    url: 'projectBindWechatAdmin',
    params: data
  })
}

// 获取二维码
export function BindQRUrl (data) {
  return request({
    method: 'get',
    url: 'projectBindWechatAdminBindQRUrl',
    params: data
  })
}

// 删除预警推送
export function deleWechatAdmin (data) {
  return request({
    method: 'DELETE',
    url: 'projectBindWechatAdmin',
    data
  })
}
