import request from '../../utils/request'

// 位置信息的tab数据 get请求  id是拼接式使用
export function tabdatas (data) {
  return request({
    method: 'get',
    url: 'locationTable',
    params: data
  })
}

// 位置信息的添加
export function Locats (data) {
  return request({
    method: 'PUT',
    url: 'locationTable',
    data
  })
}

// 位置信息的修改
export function Locatmodis (data) {
  return request({
    method: 'post',
    url: 'locationTable',
    data
  })
}

// 位置信息的删除
export function Locatdelets (data) {
  return request({
    method: 'delete',
    url: 'locationTable',
    data
  })
}

// 位置信息的搜索
export function Lodelets (data) {
  return request({
    method: 'get',
    url: 'locationTable',
    params: data
  })
}

// 水表档案》档案详情》删除
export function SodeCets (data) {
  return request({
    method: 'DELETE',
    url: 'meterTable',
    data
  })
}
