<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>账户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 添加 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" @click="dialogVisible = true">添加信息</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加弹框 -->
    <div class="locationOpenvalve">
    <el-dialog title="用户设置" :visible.sync="dialogVisible">
      <el-form size="small">
        <el-form-item label="用户名:">
          <el-input v-model.trim="tianjgement.username"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model.trim="tianjgement.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="addLadder"> <i class="el-icon-document"/>保 存</el-button>
  </div>
    </el-dialog>
  </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='500'>
    <el-table-column property="id" label="id" width="100" />
    <el-table-column property="username" label="用户名" :show-overflow-tooltip="true" />
    <el-table-column property="project_name" label="所属项目" :show-overflow-tooltip="true"/>
    <el-table-column property="create_time" label="创建日期" :show-overflow-tooltip="true"/>
    <el-table-column property="is_admin" label="是否为管理员" width="100">
      <template slot-scope="{row: {is_admin}}">
        <span v-if="is_admin === true">是</span>
        <span v-else>否</span>
      </template>
    </el-table-column>
    <el-table-column property="balance" label="操作" width="150">
      <template slot-scope="scope">
        <el-button size="mini" @click="bianji(scope.row)">修 改</el-button>
        <el-button size="mini" @click="suanchu(scope.row)">删 除</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
  </div>
  <!-- 编辑的弹框 -->
    <div class="locationOpenvalve">
  <el-dialog title="用户修改" :visible.sync="dialgVsibles">
    <el-form>
        <el-form-item label="用户名:">
          <el-input v-model.trim="xiugaigement.username"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model.trim="xiugaigement.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="addLaers"> <i class="el-icon-document"/>保 存</el-button>
  </div>
    </el-dialog>
    </div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      dialgVsibles: false,
      dialogVisible: false,
      formLabelWidth: '100px',
      // tab
      tableData: [],
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null
      },
      // 添加
      tianjgement: {
        project_id: null,
        token: null,
        username: null,
        password: null
      },
      // 修改
      xiugaigement: {
        project_id: null,
        token: null,
        user_id: null,
        project_name: null,
        username: null
      },
      // 删除
      schugement: {
        project_id: null,
        token: null,
        user_id: null
      }
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    // 删除
    suanchu (row) {
      this.schugement.project_id = this.$store.state.print.goodsForeNo.project_id
      this.schugement.token = this.$store.state.print.goodsForeNo.token
      this.schugement.user_id = row.id
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.userGement.GementDeletes(this.schugement).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            this.tabData()
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 修改的保存
    addLaers () {
      console.log(this.$store.state.print.goodsForeNo, 'vuex')
      this.xiugaigement.token = this.$store.state.print.goodsForeNo.token
      this.$api.userGement.GementPosts(this.xiugaigement).then((res) => {
        // if (res.data.status === 'success') {
        //   console.log(res, '119')
        //   this.$notify({
        //     title: '成功',
        //     message: '修改成功,请重新登录！！！',
        //     type: 'success'
        //   })
        // } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.data.username === this.$store.state.print.goodsForeNo.username) {
          this.$router.push({ path: '/' })
          this.$notify({
            title: '成功',
            message: '修改成功,跳转到登录页面！！！',
            type: 'success'
          })
        } else if (res.data.data.username !== this.$store.state.print.goodsForeNo.username) {
          this.$notify({
            title: '成功',
            message: '修改成功！！！',
            type: 'success'
          })
        }
      })
      this.dialgVsibles = false
    },
    // 修改
    bianji (row) {
      console.log(row, '修改')
      this.xiugaigement = row
      this.xiugaigement.user_id = row.id
      this.dialgVsibles = true
    },
    // 添加
    addLadder () {
      this.tianjgement.project_id = this.$store.state.print.goodsForeNo.project_id
      this.tianjgement.token = this.$store.state.print.goodsForeNo.token
      this.$api.userGement.GementPuts(this.tianjgement).then((res) => {
        if (res.data.status === 'success') {
          this.tabData()
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          // 刷新页面 -----缺点刷线页面有延迟体验不好  -----优点就这一条代码
          // window.location.reload()
          // 刷新页面 -----缺点还需要在app页面中写代码 当前组件中return也需要引入  -----优点刷线页面没有延迟
          this.reload()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.dialogVisible = false
    },
    // table数据
    tabData () {
      this.$api.userGement.GementDatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.$router.push('/', this.fasinull)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #fff;
      color: #484848;
      border: 1px solid #744dfe;
    }
    .sele {
      width: 350px;
      margin-left: 50px;
    }
    .seles {
      width: 450px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加提示
  .el-dialog{
      .el-form{
        display: flex;
        flex-wrap: wrap;
        .el-form-item{
          display: flex;
          .el-input{
            width: 200px;
          }
        }
      }
      .el-button{
        background-color: #fff;
        border: 1px solid #744dfe;
        color: #000;
        border-radius: 7px;
        i{
          margin-right: 10px;
        }
      }
  }
  .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
  }
}
        // 移动端
        @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .suso{
        padding: 10px 10px;
      }
      .tab{
        padding: 10px 10px;
      }
      .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 94%;
  }
}
    }
</style>
