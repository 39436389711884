<template>
    <div>
        <el-card>
    <el-table :data="tableData" size=mini highlight-current-row v-loading="loading" :header-cell-style = "{background:'#987AFF'}" @current-change="handleCurrentChange" height='600' element-loading-text="拼命加载中">
      <el-table-column
      type="index"
      label="序号"
      width="70">
    </el-table-column>
    <el-table-column
      prop="meter_id"
      label="表具编号">
    </el-table-column>
    <el-table-column
      prop="command_type"
      label="操作内容"
      width="120">
    </el-table-column>
    <el-table-column
      prop="status"
      label="操作结果">
    </el-table-column>
    <el-table-column
      prop="create_time"
      label="创建时间">
    </el-table-column>
    <el-table-column
      prop="send_time"
      label="发送时间">
    </el-table-column>
    <el-table-column
      prop="run_time"
      label="完成时间">
    </el-table-column>
    <el-table-column
      prop="result"
      label="响应结果">
    </el-table-column>
  </el-table>
  <el-pagination
      @current-change="handleCurrentChange"
      :page-sizes="[2, 200, 300, 400]"
      :page-size="2"
      layout="total, sizes, prev, pager, next, jumper"
      :total="4">
    </el-pagination>
      </el-card>
    </div>
</template>

<script>
export default {
  data () {
    return {
    // table数据
      tableData: [{
        meter_id: null,
        command_type: null,
        status: null,
        send_time: null
      }],
      // tab数据加载转圈
      loading: false,
      meteDatas: {
        project_id: null,
        meter_id: null,
        token: null
      }
    }
  },
  created () {
    this.meteDatas.project_id = this.$store.state.print.goodsForeNo.project_id
    this.meteDatas.token = this.$store.state.print.goodsForeNo.token
    this.meteDatas.meter_id = this.$parent.meterMaid
    this.maetData()
  },
  methods: {
    maetData () {
      this.$api.waterMeter.wateDatass(this.meteDatas).then((res) => {
        console.log(res, '开关阀')
        this.tableData = res.data.data
      })
    },
    // tab组件
    handleCurrentChange (val) {
    }
  }
}
</script>

<style lang="less" scoped>
.el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
</style>
