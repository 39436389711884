<template>
  <div class="apd">
    <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }" style="font-family: myFont">项目首页</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 主体区域num1 -->
    <el-row type="flex" justify="space-between" class="row-bg">
      <el-col :span="5">
      <div class="num-to4">
        <div class="yt">
        <div class="tors"><span>项目名称：{{famlsdata.project_name}}</span></div>
        <div class="tors"><span>用户名:{{this.$store.state.print.goodsForeNo.username}}</span></div>
        <div class="tors"><span>是否是管理员：{{admin}}</span></div>
        <div class="tors"></div>
      </div>
      </div>
      </el-col>
      <el-col :span="5">
      <div class="num-to3">
        <div class="yt">
        <div class="tors"><span>设备总数量:{{famlsdata.meter_count}}</span></div>
        <div class="tors"><span>余额数量：{{famlsdata.meter_not_owing_count}}</span></div>
        <div class="torss"><span>总剩余额：{{famlsdata.meter_not_owing_money}}</span></div>
        </div>
      </div>
      </el-col>
      <el-col :span="5">
      <div class="num-to2">
        <div class="tors">
          <span>当前欠费数量：{{famlsdata.meter_owing_count}}</span>
        </div>
        <div class="torss"><span>总欠费额：{{famlsdata.meter_owing_money}}</span></div>
      </div>
      </el-col>
      <el-col :span="5">
      <div class="num-to1" >
        <div @click="caiwu">交款退款</div>
        <div @click="fako">抄收与设置</div>
        <div @click="zhangdan">账单流水</div>
        <div></div>
      </div>
      </el-col>
    </el-row>
    <!-- 移动端 -->
    <div type="flex" justify="space-between" class="move-row">
        <div class="move-meis">
          <span>项目名称:{{famlsdata.project_name}}</span>
          <span>用户名:{{this.$store.state.print.goodsForeNo.username}}</span>
        </div>
        <div class="move-meis">
          <span>是否是管理员:{{admin}}</span>
          <span>设备总数量:{{famlsdata.meter_count}}</span>
        </div>
        <div class="move-meis">
          <span>余额数量:{{famlsdata.meter_not_owing_count}}</span>
          <span>总剩余额:{{famlsdata.meter_not_owing_money}}</span>
        </div>
        <div class="move-meis">
          <span>当前欠费数量:{{famlsdata.meter_owing_count}}</span>
          <span>总欠费额:{{famlsdata.meter_owing_money}}</span>
        </div>
    </div>
    <!-- 主题区域num2 -->
    <div class="num2">
<!-- nums--1 -->
  <el-row type="flex" justify="space-between" class="row-num2">
    <el-col :span="12">
      <div class="nums">
        <div class="stxbtn">
          <div><span style="color: #364064">已结算用水量统计</span></div>
          <div>
            <el-button @click="datr()" ref="datrs">按 月</el-button>
            <el-button @click="dayto()" ref="daytos">按 日</el-button>
          </div>
        </div>
        <div class="intus">
          <Histogram v-if="rrty" :hDataArrs="xDataArr"/>
          <HomeMonth v-if="ttyu" :yDataArrs="yDataArrs"/>
        </div>
      </div>
    </el-col>
<!-- nums--2 -->
  <el-col :span="12" class="row-num2-1">
        <div class="nums2-1"><span style="color: #4A5372">异常告警</span></div>
        <div class="nums2-2">
        <el-table :data="tableData" :header-cell-style="{textAlign: 'center'}"  :cell-style="{ textAlign: 'center' }" highlight-current-row style="width: 100%" height="261px">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column
            property="meter_id"
            label="表具编号"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="username"
            label="用户名称"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="address"
            label="位置"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="valve"
            label="阀门状态">
          </el-table-column>
          <el-table-column
            property="warn_type"
            label="异常原因">
          </el-table-column>
          <el-table-column>
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.row)">查看
          </el-button>
          </template>
    </el-table-column>
        </el-table>
        </div>
    </el-col>
  </el-row>
<!-- nums--3 -->
<el-row type="flex" justify="space-between" class="row-num3">
  <el-col :span="12">
          <div class="nums-zhi">本月账务统计数据</div>
          <HomeLine/>
          <div class="nums-ben">
            <div class="benyue">本月</div>
            <span>微信缴费金额:{{famlsdata.total_wechat_pay_money}}</span>&emsp;
            <span>业务售水金额:{{famlsdata.total_pay_type1_money}}</span>&emsp;
            <span>优惠售水金额:{{famlsdata.total_pay_type2_money}}</span>&emsp;
            <span>系统结转金额:{{famlsdata.total_pay_type3_money}}</span>&emsp;
            <span>平台退费金额:{{famlsdata.total_returned_money}}</span>&emsp;
          </div>
      </el-col>
<!-- nums--4 -->
    <el-col :span="12" class="row-num3-1">
        <div class="nums-4-1">
          <div class="nums-4-1-0" style="color: #4A5372">用户余额不足告警</div>
          <div class="nums2-2">
        <el-table :data="anceinfo" :header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }" style="width: 100%" height="180px">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column
            property="pay_id"
            label="缴费编号"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="meter_id"
            label="表号"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="balance"
            label="余额"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="username"
            label="用户名"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            property="address"
            label="地址"
            :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
          </div>
          <!-- 分页 -->
          <div class="feye">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="famliSorry.pagenum"
              :page-sizes="[20, 40, 60, 80, 100]"
              :page-size="famliSorry.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
</el-row>
    </div>
    <!-- 消息通知 -->
    <div class="news" v-show="hide">
      <div class="NoX">
        <span>消息通知</span>
        <i class="el-icon-close" @click="Xhinbtn"></i>
      </div>
      <div class="taebe">
        <el-table
          :data="newsTable"
          style="width: 100%"
          height="250">
          <el-table-column
            prop="message"
            label="公告"
            width="350">
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="发布时间"
            width="200">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Histogram from '../../components/Echarts/HomeHistogram'
import HomeMonth from '../../components/Echarts/HomeMonth'
import HomeLine from '../../components/Echarts/HomeLine'
export default {
  components: { Histogram, HomeMonth, HomeLine },
  data () {
    return {
      // 消息框的显示与隐藏
      hide: true,
      userfamDrte: null,
      yDataArrs: [], // 子组件月份
      xDataArr: [],
      // 是否是管理员
      admin: null,
      // 用户名称
      project_name: null,
      // 按日按钮
      rrty: false,
      // 按月按钮
      ttyu: true,
      tableData: [{
        meter_id: null,
        username: null,
        address: null,
        valve: null,
        warn_type: null
      }],
      // 水表详情数据接口返回参数
      famlis: {
        token: null,
        insufficient_balance: true,
        pagenum: 1,
        pagesize: 20
      },
      famliSorry: {
        token: null,
        balance_below: 1,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 20
      },
      // 水表详情数据接口接收数据
      famlsdata: {
        meter_count: null,
        meter_online_count: null,
        meter_owing_count: null,
        project_address: null,
        project_name: null,
        user_count: null,
        return_sum: null, // 退费总额
        water_sum: null, // 用水总量
        pay_sum: null, // 缴费总额
        water_money_sum: null // 水费总额
      },
      // 余额不足告警
      anceinfo: [],
      total: null,
      pieData: [],
      wxfans: {},
      ptfans: {},
      // 消息显示
      noties: {
        token: null
      },
      newsTable: []
    }
  },
  created () {
    console.log(this.$store.state.print.goodsForeNo, 'vuex')
    this.famliSorry.token = this.$store.state.print.goodsForeNo.token
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.project_name = this.$store.state.print.goodsForeNo.project_name

    // 判断是否是管理员
    if (this.$store.state.print.goodsForeNo.is_superuser !== false) {
      this.admin = '是'
    } else {
      this.admin = '否'
    }

    // 数据
    // this.failData()

    // 余额信息
    this.Balance()

    // 异常警告
    this.Exception()
    this.bansData()
    // 消息显示
    this.Xiaoxin()
  },
  mounted () {
    // this.failData()
    console.log('页面进入时的宽度：', document.body.clientWidth)
    if (document.body.clientWidth < 1400) {
      // this.dialogWidth = '100%'
      console.log(document.getElementsByClassName('nums-ben'), 'wwwwwwwww')
    }
  },
  methods: {
    // 消息显示
    Xiaoxin () {
      this.noties.token = this.$store.state.print.goodsForeNo.token
      this.$api.lonig.notifys(this.noties).then((res) => {
        console.log(res, '消息')
        if (res.data.status === 'success') {
          this.newsTable = res.data.data
          if (res.data.total === 0) {
            this.hide = false
          }
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 隐藏消息弹框
    Xhinbtn () {
      this.hide = false
    },
    // 财务管理
    caiwu () {
      this.$router.push({ path: '/accountMent' })
    },
    // 阀控管理
    fako () {
      this.$router.push({ path: '/Parameter' })
    },
    // 账单
    zhangdan () {
      this.$router.push({ path: '/summary' })
    },
    bansData () {
      this.$api.users.paymentProportion(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '饼图首页')
          this.famlsdata = res.data
          this.yDataArrs = res.data.per_month_flow_use // 这是月份的数据
          this.xDataArr = res.data.per_day_flow_use // 这是日份的数据
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },

    // 余额不足告警
    Balance () {
      this.$api.waterMeter.meterdatas(this.famliSorry).then((res) => {
        console.log(res, '余额信息1')
        this.anceinfo = res.data.data
        this.total = res.data.total
      })
    },

    // 异常警告数据
    Exception () {
      this.$api.users.Exceptions(this.famlis).then((res) => {
        console.log(res)
        this.tableData = res.data.data
      })
    },

    // 异常警告的查看
    handleEdit (row) {
      console.log(row)
      this.userfamDrte = row
      this.$router.push({ path: '/fileDetails', query: { project_id: this.famlis.project_id, obj: this.userfamDrte } })
    },

    // 按月份
    datr () {
      // console.log(this.$refs.datrs.$el.style, ' this.$refs.datrs')
      this.$refs.datrs.$el.style.backgroundColor = '#744DFE'
      this.$refs.datrs.$el.style.color = '#fff'
      this.$refs.daytos.$el.style.backgroundColor = '#fff'
      this.$refs.daytos.$el.style.color = '#744DFE'
      this.rrty = false
      this.ttyu = true
    },

    // 按日份
    dayto () {
      // console.log(this.$refs.daytos.$el.style, ' this.$refs.datrs')
      this.$refs.daytos.$el.style.backgroundColor = '#744DFE'
      this.$refs.daytos.$el.style.color = '#fff'
      this.$refs.datrs.$el.style.backgroundColor = '#fff'
      this.$refs.datrs.$el.style.color = '#744DFE'
      this.rrty = true
      this.ttyu = false
    },

    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famliSorry.pagesize = newSize
      this.Balance()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famliSorry.pagenum = newPage
      this.Balance()
    }

  }
}
</script>

<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  // height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    padding: 4px 0;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
    }
  }
}
// 主体区域num1
.row-bg{
  padding: 1rem 2rem;
  .num-to1{
    // width: 348px;
    height: 7.2rem;
    padding: 20px 0px;
    background-image: url(../../views/users/img/Rectangle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap ;
    color: #fff;
    font-size: 1.2rem;
    div{
      width: 40%;
      height: 50px;
      border: 1px solid #4dfeef;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      background-color: #307027;
    }
    div:nth-child(4){
      border: none;
      opacity: 0.0;
      cursor: none;
    }
  }
  .num-to2{
  //  width: 348px;
    height: 7.2rem;
  padding: 20px 0px;
    background-image: url(../../views/users/img/Rectang.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .tors{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 10px;
    }
    .torss{
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;
    }
  }
  .num-to3{
    // width: 348px;
    height: 7.2rem;
    padding: 20px 0px;
    background-image: url(../../views/users/img/Recta.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .tors{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 10px;
    }
    .torss{
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;
    }
  }
  .num-to4{
    // width: 348px;
    height: 7.2rem;
    padding: 20px 0px;
    background-image: url(../../views/users/img/Rec.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .yt{
    font-family: BENMOJingyuan;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    line-height: 29px;
    letter-spacing: 0px;
    color: #ffffff;
}
  }
}
// 主体区域num2
.num2{
  padding: 20px 70px 0px 50px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .row-num2-1{
    margin-left: 30px;
  }
  .row-num2{
  width: 100%;
  .el-col{
    height: 310px;
    border-radius: 9px;
    box-shadow: 3px 3px 12px 0px rgba(228, 228, 228, 0.5);
    border: 1px solid #f2f3f5;
    .stxbtn{
      margin-top: 10px;
      display: flex;
      justify-content: space-around;
       .el-button:nth-child(1){
            margin-right: 30px;
            // width: 90px;
            // height: 41px;
            font-size: 15px;
            color: #fff;
            background-color: #744DFE;
        }
      .el-button:nth-child(2){
            margin-right: 30px;
            // width: 90px;
            // height: 41px;
            font-size: 15px;
            color: #744DFE;
            background-color: #EDE8FE;
      }
    }
    .nums2-1{
      padding: 10px 0px 0px 10px;
    }
  }
}
  .row-num3-1{
    margin-left: 30px;
  }
  .row-num3{
    margin-top: 20px;
    width: 100%;
    .el-col{
      //  height: 240px;
      border-radius: 9px;
      box-shadow: 3px 3px 12px 0px rgba(228, 228, 228, 0.5);
      border: 1px solid #F2F3F5;
      display: flex;
      position: relative;
      .nums-zhi{
        width: 140px;
        padding: 70px 10px;
        position: absolute;
       font-size: 1rem;
        z-index: 9999;
      }
      .nums-ben{
        bottom: 0px;
        right: 0px;
        width: 9.3rem;
        // height: 3.75rem;
        line-height: 2;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        color: #364064;
        // border: 1px solid;
        font-size: 0.9rem;
        .benyue{
          width: 100%;
        }
      }
      .nums-4-1{
        width: 100%;
        .nums-4-1-0{
          padding: 10px 0px 0px 10px;
        }
      }
    }
  }
}
.news{
  width: 30%;
  height: 300px;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
  position: absolute;
  background-color: #fff;
  border: 1px solid #364064;
  cursor:pointer;/*鼠标变小手*/
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
  .NoX{
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #364064;
  }
}
// 移动端样式
.move-row{
  border-top: 1px solid #fff;
  padding: 10px 10px;
  background-color: #364064;
  .move-meis{
    display: flex;
    justify-content: space-between;
    color: #fff;
  }
}
@media (min-width: 768px) {
  .move-row{
    display: none;
  }
}
@media (max-width: 768px) {
  .news{
    width: 90%;
    position: fixed;
    right: 0px;
    bottom: 0px;
  }
  .Gnv{
    display: none;
  }
  .row-bg{
    padding: 10px 10px;
  }
  .row-bg{
    display: none;
  }
  .num2{
    padding: 10px 10px;
    .row-num2{
    display: inline;
  }
  .row-num2-1{
    margin-left: 0px;
    margin-top: 10px;
  }
  .row-num3{
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline;
    .row-num3-1{
      margin-left: 0px;
      margin-top: 10px;
    }
    .el-col .nums-ben{
      right: -35px;
    }
  }
  }
  .feye{
    width: 91%;
    .el-pagination{
      width: 90%;
    // border: 1px solid #307027;
    display: flex;
    justify-content: space-between;
    .el-pagination__jump{
      display: none;
    }
    }
}
}
</style>
