import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import getters from './getters'
import print from './modules/print'
Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  storage: window.localStorage
})

const store = new Vuex.Store({
  getters,
  plugins: [vuexLocal.plugin],
  modules: {
    print
  }
})
export default store
