<template>
    <div id="app">
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}" @selection-change="handleSelectionChange" height='350px'>
    <el-table-column  type="selection" width="60" />
    <el-table-column property="pay_id" label="缴费编号" />
    <el-table-column property="meter_id" label="水表编号" :show-overflow-tooltip="true" />
    <el-table-column property="name" label="用户姓名"  />
    <el-table-column property="phone" label="联系方式" />
    <el-table-column property="phone" label="用水类型" />
    <el-table-column property="address" label="在线状态"  :show-overflow-tooltip="true" />
    <el-table-column property="balance" label="阀门状态"  />
    <el-table-column property="pay_id" label="用户地址" />
    <el-table-column property="meter_id" label="累计流量" :show-overflow-tooltip="true" />
    <el-table-column property="name" label="余额"  />
    <el-table-column property="phone" label="启用状态" />
    <el-table-column property="address" label="证件号码"  :show-overflow-tooltip="true" />
    <el-table-column property="balance" label="外部编号"  />
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    </div>
</template>
<script>
export default {
  props: {
    ziData: Array
  },
  data () {
    return {
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      total: 0,
      famlis: {
        project_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 20
      }
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
    // this.Wchis()
    console.log(this.ziData)
  },
  methods: {
    handleSelectionChange (val) {
      console.log(val)
    },
    // table数据
    tabData () {
      this.$api.waterMeter.meterdatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.tabData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>

// <!-- table表格 -->
    .tab{
        padding: 20px 0px 0px 10px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
</style>
