import request from '../../utils/request'

// 用户档案的数据
export function accounDatas (data) {
  return request({
    method: 'get',
    url: 'meterTable',
    params: data
  })
}

// 缴费
export function accounBzhits (data) {
  return request({
    method: 'post',
    url: 'userPayment',
    data
  })
}

// 退费
export function accounRefunds (data) {
  return request({
    method: 'post',
    url: 'userRefund',
    data
  })
}

// 退费
export function getuserRefund (data) {
  return request({
    method: 'get',
    url: 'userRefund',
    params: data
  })
}

// 全局配置
export function globalConfigs (data) {
  return request({
    method: 'get',
    url: 'globalConfig',
    params: data
  })
}

// 修改
export function globalxius (data) {
  return request({
    method: 'post',
    url: 'globalConfig',
    data
  })
}

// 上传图片
export function projectLogos (data) {
  return request({
    method: 'post',
    url: 'projectLogo',
    data
  })
}
