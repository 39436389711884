import request from '../../utils/request'

// 阀门管理
export function valaeks (data) {
  return request({
    method: 'put',
    url: 'deviceCommand',
    data
  })
}

// 指令记录
export function valaezhis (data) {
  return request({
    method: 'GET',
    url: 'deviceCommand',
    params: data
  })
}

// 查询
export function valaecasi (data) {
  return request({
    method: 'GET',
    url: 'deviceCommand',
    params: data
  })
}
