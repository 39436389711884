<template>
  <div class="ext">
    <div id="main" :style="{ width: '1400px', height: '240px' }"></div>
  </div>
</template>
<script>
export default {
  props: ['message'],
  data () {
    return {
      flow_list: null,
      time_periods: null
    }
  },
  watch: {
    message (new1, new2) {
      console.log(new1.flow_list, '1', new2)
      this.flow_list = new1.flow_list
      this.time_periods = new1.time_periods
      this.drawLine()
    }
  },
  created () {
    console.log(this.message, '121')
  },
  mounted () {
    this.drawLine()
  },

  methods: {
    drawLine () {
      console.log(this.flow_list, 'flow_list')
      const main = this.$echarts.init(document.getElementById('main'))
      main.setOption({
        title: {
          subtext: '用量/m3'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // prettier-ignore
          data: this.time_periods
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          },
          axisPointer: {
            snap: true
          }
        },
        series: [
          {
            name: '立方米',
            type: 'line',
            smooth: true,
            // prettier-ignore
            data: this.flow_list
          }
        ]
      })
    }
  }
}
</script>
<style scoped>

</style>
