import request from '../../utils/request'

// 用水类型的tab数据 get请求  id是拼接式使用
export function tabdatas (data) {
  return request({
    method: 'get',
    url: 'waterType',
    params: data
  })
}

// 用水类型的添加
export function ladderadds (data) {
  return request({
    method: 'put',
    url: 'waterType',
    data
  })
}

// 用水类型的删除
export function waterDelets (data) {
  return request({
    method: 'DELETE',
    url: 'waterType',
    data
  })
}

// 用水类型的修改
export function xaterGelets (data) {
  return request({
    method: 'POST',
    url: 'waterType',
    data
  })
}
