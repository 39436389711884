<template>
    <div>
   <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>水表档案</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <addre :getname="getname" @transfer="getUser" @Building="getBuilding" @unit="getUnit" @room="getRoom" @ParData="getParData" @buildingData="getbuilding" @unitData="getunit" @roomData="getroom" @owing="getowing" @water_type_id="getwater"></addre>
        </el-form-item>
      <div class="fes">
        <el-form-item>
          <el-button @click="dialogpiliang = true">批量添加</el-button>
        </el-form-item>
        <el-form-item class="btnBinie">
           <el-button @click="dialogwate = true">单条添加</el-button>
        </el-form-item>
        <!-- //选中导出 -->
        <el-form-item class="btnBinie">
          <el-button type="primary" @click="exportexcel" >选中导出</el-button>
        </el-form-item>
        <!-- //区域导出按钮 -->
        <el-form-item class="btnBinie1">
          <el-button type="primary" @click="exporQuDao" >区域导出</el-button>
        </el-form-item>
        <!-- //批量启用 -->
        <el-form-item class="btnBinie">
          <el-button @click="BatchbleBtn">批量启用</el-button>
        </el-form-item>
        <!-- //批量停用 -->
        <el-form-item class="btnBinie">
      <el-button @click="BatchvationBtn">批量停用</el-button>
        </el-form-item>
        <!-- //添加阀控策略白名单 -->
        <el-form-item class="btnBinie1">
      <el-button @click="BiaMingTan = true">添加阀控策略白名单</el-button>
        </el-form-item>
      </div>
      </el-form>
    </div>

    <!-- 主体区域 -->
    <div class="num">
      <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="meteData" size=mini  style="width: 100%"
    highlight-current-row
    :header-cell-style = "{background:'#987AFF'}"
    @selection-change="handleSelectionChange"
    :row-class-name="rowStyle"
    @select='xz'
    @select-all='xzAll'
    height='500px'>
    <el-table-column  type="selection" width="70" />
    <el-table-column property="pay_id" label="缴费编号"  width="80" />
    <el-table-column property="meter_id" label="水表编号"  width="180" :show-overflow-tooltip="true" />
    <el-table-column property="username" label="用户姓名"  width="100" />
    <el-table-column property="phone" label="联系方式"  width="120" :show-overflow-tooltip="true"/>
    <el-table-column property="balance" label="余额" width="100"  />
    <el-table-column prop="is_use" label="启用状态"  width="130">
      <template slot-scope="scope">
        <span v-if="+scope.row.is_use === 0">停用</span>
        <span v-else-if="+scope.row.is_use === 1">启用</span>
      </template>
    </el-table-column>
    <el-table-column property="water_type_name" label="用水类型" width="190" />
    <el-table-column property="online" label="在线状态"  width="80" :show-overflow-tooltip="true" />
    <el-table-column property="valveStatus" label="阀门状态"  width="150" />
    <el-table-column
        prop="shiBaiShangChuanCiShu"
        label="上传失败次数"
        width="150"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="dingShiDongJieJianGe"
        label="定时冻结间隔"
        width="150"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="yaLiF"
        label="压力"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="shuiWenF"
        label="水温"
        show-overflow-tooltip>
        </el-table-column>
    <el-table-column property="address" label="用户地址" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="sumFlow" label="正向累计流量" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="netAccumulation" label="净累计流量" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="card_number" label="证件号码" width="150"  :show-overflow-tooltip="true" />
    <el-table-column property="zhuangTaiChaiXieZhuangTai" label="拆卸状态" />
    <el-table-column
        prop="zhuangTaiLouShui"
        label="漏水"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiFanXiangJiLiang"
        label="反向计量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="zhuangTaiLiuLiangChaoShangXian"
        label="流量超限"
        show-overflow-tooltip>
        </el-table-column>
    <el-table-column property="valve" label="操作" width="156" fixed="right" >
      <template slot-scope="scope">
        <el-button size="mini" @click="modify(scope.row)">修改</el-button>
        <el-button size="mini" @click="ficsati(scope.row)">查看</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    </div>
    <!-- http://btmswater.sdbina.com/statics/example.xlsx -->
<!-- 批量导入 -->
<div class="locationOpenvalve">
    <el-dialog title="批量添加" :visible.sync="dialogpiliang">
      <div class="spans"/>
      <el-upload
        class="upload-demo"
        drag
        action="https://btmswaterbg.sdbina.com/api/v1/meterTableImport"
        multiple
        :limit='1'
        :data='uploadHeaders'
        accept='.xls,.xlsx'
        :on-success='handleAvatarSuccess'
        :on-error='shibanerro'
        :on-change='hendleAvatarPreview'
        :on-remove='handleRemove'
        >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件,，，，，</div>
      </el-upload>
      <div @click="wjianbtn" style="color: #744dfe;">点击下载模板文件</div>
</el-dialog>
</div>
<!-- 添加 -->
<div class="locationOpenvalve">
    <el-dialog title="单条添加" :visible.sync="dialogwate">
      <div class="spans"/>
      <el-form :model="LadderData" :rules="loginFormRules">
        <el-form-item>
          <WaterAdd/>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
    <!-- 批量添加 -->
    <div class="locationOpenvalve">
    <el-dialog title="批量添加" :visible.sync="dialoBatch">
    <div class="wwoop"><span>成功条数: {{bansdata.success_count}}</span><span>失败条数: {{bansdata.error_count}}</span></div>
    <div v-for=" (item,index) in error_list " :key ='index' class="error">
      <div>错误信息：{{item}}</div>
    </div>
    </el-dialog>
  </div>
        <!-- 批量启用 -->
    <div class="locationOpenvalve">
  <el-dialog
  title="批量启用"
  :visible.sync="Batchenavais"
  >
  <div class="spans"/>
  <div class="Periodic">
      <span>当前勾选: {{lengthid}} 台设备</span>
      <el-checkbox v-model="batchRefund.regional_issued">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
  <div class="Batchinput">
     <span class="pspan">用水类型:</span>&nbsp;
    <el-select v-model="batchRefund.water_type_id" placeholder="请选择">
      <el-option
        v-for="item in withdown"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="BatchRefund"><i class="el-icon-success" />启 用</el-button>
  </span>
</el-dialog>
    </div>
      <!-- 批量停用-->
  <div class="locationOpenvalve">
  <el-dialog title="批量停用" :visible.sync="CloseVisible">
  <el-form>
    <el-form-item>
      <span>当前勾选: {{lengthid}} 台设备</span>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="batchRefund.regional_issued">选择当前区域所有设备({{total}})条</el-checkbox>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="success" size="medium" @click="OpenSubmit">停用</el-button>
  </div>
</el-dialog>
  </div>
  <!-- 修改弹框 -->、
  <div class="locationOpenvalve">
  <el-dialog title="修改" :visible.sync="CmodifyVisible">
  <el-form>
    <el-form-item>
          <modify :fa-msg="formMsg"></modify>
        </el-form-item>
      </el-form>
</el-dialog>
</div>
<!-- 添加白名单 -->
  <div class="locationOpenvalve">
  <el-dialog
  title="添加阀控策略白名单"
  :visible.sync="BiaMingTan"
  >
  <div class="Tishistext">说明：阀控策略白名单内的表将不会自动关阀</div>
  <div class="Periodic">
      <span>当前勾选: {{lengthid}} 台设备</span>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="BatchMingBty"><i class="el-icon-success" />添加</el-button>
  </span>
</el-dialog>
    </div>
<!-- //在这里将拿到的aa渲染到该表格 -->
 <table class="table" v-show="false">
  <tr style="width:200px;">
    <td>序号</td>
    <td>缴费编号</td>
    <td>水表编号</td>
    <td>用户姓名</td>
    <td>联系方式</td>
    <td>余额</td>
    <!-- <td>启用状态</td> -->
    <td>用水类型</td>
    <td>在线状态</td>
    <td>阀门状态</td>
    <td>用户地址</td>
    <td>正向累计流量</td>
    <td>净累计流量</td>
    <td>证件号码</td>
  </tr>
  <!-- 动态渲染 -->
  <tr style="text-align:center;" v-for="(item,index) in multipleSelection" :key="index">
    <td style="width:200px;text-align:center;">{{index+1}}</td>  序号
    <td style="width:200px;text-align:center;">{{item.pay_id}}</td>
    <td style="width:200px;text-align:center;">{{item.meter_id}}</td>
    <td style="width:200px;text-align:center;">{{item.username}}</td>
    <td style="width:200px;text-align:center;">{{item.phone}}</td>
    <td style="width:200px;text-align:center;">{{item.balance}}</td>
    <!-- <td style="width:200px;text-align:center;">{{item.is_use}}</td> -->
    <td style="width:200px;text-align:center;">{{item.water_type_name}}</td>
    <td style="width:200px;text-align:center;">{{item.online}}</td>
    <td style="width:200px;text-align:center;">{{item.valveStatus}}</td>
    <td style="width:200px;text-align:center;">{{item.address}}</td>
    <td style="width:200px;text-align:center;">{{item.sumFlow}}</td>
    <td style="width:200px;text-align:center;">{{item.netAccumulation}}</td>
    <td style="width:200px;text-align:center;">{{item.card_number}}</td>
    <td style="width:200px;text-align:center;">{{item.valve}}</td>
  </tr>
</table>
  </div>
</template>
<script>

import WaterAdd from '../../../components/WaterAddition' // 添加
import addre from '../../../components/waterAddress'
import modify from '../../../components/modify' // 修改
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import { dataConversionUtil } from '@/utils/data2Excel'
export default {
  components: {
    WaterAdd,
    addre,
    modify
  },
  inject: ['reload'],
  data () {
    return {
      ten_is_use: '你好',
      // 批量导出
      // 学生所有数据
      tableData: [],
      //   被选中的学生数组
      multipleSelection: [],
      //   学员状态
      options: [
        {
          value: '选项1',
          label: '启用'
        },
        {
          value: '选项2',
          label: '禁用'
        }
      ],
      student_status: '', // 选中的学生状态
      student_name: '', // 学生名字
      student_mobile: '', // 学生手机号
      pagenum: 1, // 一页多少条
      pagesize: 5, // 第几页
      //
      ids: [],
      CmodifyVisible: false,
      CloseVisible: false,
      Batchenavais: false,
      dialoBatch: false,
      loginFormRules: {
        // 验证用户名是否合法
        meter_id: [
          { required: true, message: '', trigger: 'blur' },
          { min: 12, max: 12, message: '表号必须是12个字符', trigger: 'blur' }
        ]
      },
      // 主体
      meteData: [
        {
          imei: null,
          water_type: null,
          valveStatus: null,
          cycle_flow_use: null
        }
      ],
      // 用水类型下拉框
      withdown: [{
        id: 1,
        name: null
      }],
      // 添加
      LadderData: {
        project_id: null,
        meter_id: null,
        token: null,
        is_use: null,
        enable_trace: null,
        water_type_id: null,
        meter_type: null
      },
      formLabelWidth: '5.5rem',
      // 添加弹框
      dialogwate: false,
      // 批量添加
      dialogpiliang: false,
      total: null,
      famlis: {
        project_id: null,
        token: null,
        pagesize: 20,
        pagenum: 1,
        search_content: null,
        water_type_id: null,
        owing: null
      },
      // 详情
      project_id: null,
      // 搜索
      buosDasi: {
        project_id: null,
        meter_id: null,
        token: null,
        owing: null,
        not_bind: null,
        search_content: null,
        water_type_id: null,
        online: null
      },
      uploadHeaders: {
        token: null
      },
      bansdata: {},
      error_list: [],
      // 批量启用
      batchRefund: {
        token: null,
        meter_id_list: null,
        regional_issued: null,
        room_id: null,
        unit_id: null,
        building_id: null,
        community_id: null,
        water_type_id: null,
        is_use: true
      },
      // 批量停用
      batchDeacti: {
        token: null,
        meter_id_list: null,
        regional_issued: null,
        room_id: null,
        unit_id: null,
        building_id: null,
        community_id: null,
        water_type_id: null,
        is_use: false
      },
      lengthid: 0,
      msg: null,
      formMsg: {},
      // 区域导出
      quyucan: {
        token: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        pagesize: null,
        pagenum: 1,
        owing: null
      },
      // 白名单
      BiaMingTan: false,
      TianBanDan: {
        token: null,
        meter_id_list: 'null'
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.uploadHeaders.token = this.$store.state.print.goodsForeNo.token
    // 添加
    this.LadderData.token = this.$store.state.print.goodsForeNo.token
    this.basData()
    this.waterdown()
  },
  methods: {
    xz (x, y) {
      console.log(x, y)
      this.aa = x
    },
    xzAll (x) {
      console.log(x)
      this.aa = x
    },
    // 方法
    exportexcel () {
    // 定义xlsx文件的名字

      const name = '水表用户信息表'
      console.log(name)
      // 选择要导出的表格
      var wb = XLSX.utils.table_to_book(document.querySelector('.table'))

      var wbout = XLSX.write(wb, { // excel配置
        // 文件类型
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      wbout.style = 'text-align:center'
      console.log(wbout)
      try {
        //  name+'.xlsx'表示导出的excel表格名字
        // 将原生字符串转换为Uint8Array的数组，然后再通过FileSaver保存到文件
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          name + '.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout
    },

    // 讲方法赋值到aa数组里面

    //

    // 区域导出
    exporQuDao () {
      this.quyucan.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterdatas(this.quyucan).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '区域导出水表用户信息表.xls')
          var data = res.data.data
          var tableHeader = [
            ['序号', '缴费编号', '水表编号', '用户姓名', '联系方式', '余额', '用水类型', '在线状态', '启用状态', '阀门状态', '用户地址', '正向累计流量', '净累计流量', '证件号码']
          ]
          var dataList = []
          data.forEach((item, index) => {
            console.log(item, '区域导出item')
            if (item.is_use === 0) {
              this.ten_is_use = '停用'
            } else {
              this.ten_is_use = '启用'
            }
            dataList.push([index + 1, item.pay_id, item.meter_id, item.username, item.phone, item.balance, item.water_type_name, item.online, item.ten_is_use, item.valveStatus, item.address, item.sumFlow, item.netAccumulation, item.card_number, item.valve])
          })
          dataConversionUtil.dataToExcel('区域信息表', tableHeader, dataList)
          this.$message.success('导出成功!')
          this.quyucan.community_id = null
          this.quyucan.building_id = null
          this.quyucan.unit_id = null
          this.quyucan.room_id = null
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },

    // 下载导入模板
    wjianbtn () {
      window.location.href = 'https://btmswater.sdbina.com/statics/example.xlsx'
    },
    // 主体
    basData () {
      this.$api.waterMeter.meterdatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          this.meteData = res.data.data
          console.log(this.meteData, '主题数据')
          this.total = res.data.total
          this.quyucan.pagesize = res.data.total
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // tab选中数据
    handleSelectionChange (val) {
      this.ids = val.map(item => item.pay_id)
      console.log(val)
      this.lengthid = val.length
      const arr = []
      val.forEach(function (value) {
        arr.push(value.meter_id)
        console.log(arr, 'arr')
      })
      this.batchRefund.meter_id_list = arr
      this.batchDeacti.meter_id_list = arr
      // this.batchRtss.pay_id_list = arr
      this.multipleSelection = val
      this.TianBanDan.meter_id_list = arr
    },
    // 修改
    modify (row) {
      console.log(row, '父组件点击了修改按钮')
      // this.msg = Object.assign({}, row)
      const copy = Object.assign({}, row)
      this.formMsg = copy
      console.log(this.formMsg, '1111')
      this.CmodifyVisible = true
    },
    // 添加白名单
    BatchMingBty () {
      this.TianBanDan.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.closeValveServerWhiteListPut(this.TianBanDan).then((res) => {
        console.log(res, 'sc')
        if (res.data.status === 'success') {
          this.basData()
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.BiaMingTan = false
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    shuaxin () {
      this.basData()
      this.reload()
    },
    getname (name) {
      console.log('App收到了子组件传过来的数据', name)
      this.meteData = name.data
      this.total = name.total
    },
    // 位置下拉框>>>>>小区id
    getUser (data) {
      console.log('小区子组件传递过来的值：', data)
      this.batchRefund.community_id = data
      this.batchDeacti.community_id = data
      this.quyucan.community_id = data
      this.famlis.community_id = data
      this.community_id = data
    },
    getBuilding (data) {
      console.log('楼栋子组件传递过来的值：', data)
      this.batchRefund.building_id = data
      this.batchDeacti.building_id = data
      this.quyucan.building_id = data
      this.famlis.building_id = data
      this.building_id = data
    },
    getUnit (data) {
      console.log('单元子组件传递过来的值：', data)
      this.batchRefund.unit_id = data
      this.batchDeacti.unit_id = data
      this.quyucan.unit_id = data
      this.famlis.unit_id = data
      this.unit_id = data
    },
    getRoom (data) {
      console.log('房间子组件传递过来的值：', data)
      this.batchRefund.room_id = data
      this.batchDeacti.room_id = data
      this.quyucan.room_id = data
      this.famlis.room_id = data
      this.room_id = data
    },
    getParData (data) {
      console.log(data, '选中小区刷新数据')
      this.meteData = data.data.data
      this.total = data.data.total
      this.quyucan.pagesize = data.data.total
    },
    getbuilding (data) {
      console.log(data, '选中楼栋刷新数据')
      this.meteData = data.data.data
      this.total = data.data.total
      this.quyucan.pagesize = data.data.total
    },
    getunit (data) {
      console.log(data, '选中单元刷新数据')
      this.meteData = data.data.data
      this.total = data.data.total
      this.quyucan.pagesize = data.data.total
    },
    getroom (data) {
      console.log(data, '选中单元刷新数据')
      this.meteData = data.data.data
      this.total = data.data.total
      this.quyucan.pagesize = data.data.total
    },
    getowing (data) {
      console.log(data, '选中欠费')
      this.quyucan.owing = data
      this.famlis.owing = data
      this.owing = data
    },
    getwater (data) {
      console.log(data, '用水类型')
      this.quyucan.water_type_id = data
      this.famlis.water_type_id = data
      this.water_type_id = data
    },
    // 批量启用
    BatchbleBtn () {
      this.Batchenavais = true
    },
    BatchRefund () { // 启用
      this.batchRefund.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterBchits(this.batchRefund).then((res) => {
        if (res.data.status === 'success') {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.reload()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 批量停用
    BatchvationBtn () {
      this.CloseVisible = true
    },
    // 停用
    OpenSubmit () {
      this.batchDeacti.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.meterBchits(this.batchDeacti).then((res) => {
        if (res.data.status === 'success') {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.reload()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 文件上传失败
    shibanerro (res) {
      console.log(res, '文件上传失败')
    },
    // 导入文件上传成功时的钩子
    handleAvatarSuccess (res) {
      console.log(res, '文件上传成功时的钩子')
      // this.$message(res.data, '这是一条消息提示')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.dialoBatch = true
      this.bansdata = res
      this.error_list = this.bansdata.error_list
      console.log(this.bansdata, '123')
      loading.close()
      // this.$notify({
      //   title: '提示',
      //   message: res.error_list,
      //   duration: 0
      // })
    },
    hendleAvatarPreview () {
      this.dialogpiliang = false
    },
    handleRemove (file, fileList) {
      console.log(file, fileList, 'file, fileList')
    },
    // 添加的用水类型下拉框
    waterdown () {
      this.$api.waterMeter.waterdowns(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '用水类型')
          this.withdown = res.data.data
        }
      })
    },
    // 跳转详情按钮
    ficsati (row) {
      console.log(row, 'row')
      this.$router.push({ path: '/fileDetails', query: { obj: row } })
    },
    flileAils (item) {
      console.log(item, 'item')
      this.$router.push({ path: '/fileDetails', query: { obj: item } })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.buosDasi.search_content
      this.famlis.water_type_id = this.buosDasi.water_type_id
      this.famlis.owing = this.buosDasi.owing
      this.basData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.community_id = this.community_id
      this.famlis.building_id = this.building_id
      this.famlis.unit_id = this.unit_id
      this.famlis.room_id = this.room_id
      this.famlis.owing = this.owing
      this.famlis.water_type_id = this.water_type_id
      this.basData()
    },
    rowStyle ({ row, rowIndex }) {
      var arrs = this.ids
      for (let i = 0; i < arrs.length; i++) {
        console.log(row.pay_id, arrs[i], 'row.pay_id,arrs')
        if (row.pay_id === arrs[i]) {
          return 'rowStyle'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
    /deep/.rowStyle{
      background-color: oldlace !important;
    }
    .Tishistext{
      color: brown;
      margin-bottom: 20px;
    }
.wwoop{
  display: flex;
  justify-content: space-between;
}
.error{
  margin-top: 16px;
  line-height: 70%;
}
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}

// 搜索
.suso {
  padding: 0px 0px 0px 40px;
  display: flex;
  flex-wrap: wrap;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-input {
      width: 300px;
    }
    .el-button {
      background-color: #744dfe;
      border: 0px;
      color: #ebebeb;
    }
    .el-form-item{
      margin-bottom: 9px;
    .el-select{
      width: 200px;
    }
    }
  }
}
.brnBtn{
  padding: 10px 0px 0px 30px;
  display: flex;
  .ilsi{
      cursor: pointer;
      width: 6.2rem;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 1rem;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .ili{
      cursor: pointer;
      margin-left: 20px;
      width: 6.2rem;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 1rem;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}
.caozuo{
  padding: 0px 40px;
  .el-button{
    background-color: #b6a2fe;
    color: #fff;
  }
}
// 主体区域
.num{
  width: 95%;
  margin-left: 40px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  .nums{
    cursor: pointer;
    margin-top: 15px;
    margin-left: 15px;
    width: 22rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 1px rgba(116, 77, 254, 0.5);
    border-radius: 7px;
    border: solid 2px #c6c6c6;
    .nums1{
      display: flex;
      img{
        width: 27px;
        height: 29px;
        margin-left: 17px;
        margin-top: 21px;
      }
      span{
        font-family: PingFangSC-Heavy;
        padding: 30px 20px 0px 10px;
        font-size: 22px;
        color: #474747;
        letter-spacing: 1px;
      }
      .numsbtn{
        margin-top: 30px;
        font-size: 16px;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 25px;
        background-color: #ebebeb;
        box-shadow: 1px 1px 5px 1px rgba(106, 106, 106, 0.5);
        border-radius: 3px;
        border: solid 2px #b6a2fe;
        opacity: 0.5;
      }
    }
    .nums2{
      padding: 10px 30px 0px 30px;
      width: 290px;
      // height: 60px;
      font-family: MiSans-Regular;
      font-size: 0.9rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 44px;
      letter-spacing: 1px;
      color: #6e7598;
      span:nth-child(4){
        margin-left: 25px;
      }
    }
    .nums3{
      margin-left: 30px;
      margin-bottom: 30px;
      // width: 170px;
      // height: 59px;
      font-family: MiSans-Regular;
      font-size: 0.9rem;
      font-weight: normal;
      font-stretch: normal;
      line-height: 41px;
      letter-spacing: 3px;
      color: #6e7598;
    }
  }
}
// 分页
.feye{
  background-color: rgb(247, 245, 245);
  width: 82%;
  height: 40px;
  position: fixed;
  right: 0px;
  bottom: 0;
}
// 添加
.el-dialog{
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }

      .el-form{
          display: flex;
          flex-wrap: wrap;
        .el-form-item{
          .el-input{
            width: 200px;
          }
        }
      }

      .dialog-footer{
        .el-button{
          background-color: #fff;
          border: 1px solid #744dfe;
          color: #575757;
          border-radius: 7px;
          font-size: 17px;
          span{
            i{
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
// <!-- table表格 -->
    .tab{
        padding: 20px 0px 0px 10px;
        width: 100%;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
    .locationOpenvalve{
  .Periodic{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .Batchinput{
      margin-top: 20px;
      display: flex;
        align-items: center;
        span{
          font-family: BENMOJingyuan;
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 1px;
          color: #744dfe;
        }
        .pspan{
          color: #36373d;
          font-size: 1rem;
        }
        .el-select{
          width: 150px;
          margin-left: 10px;
          margin-top: 5px;
        }
    }
    }
     .locationOpenvalve{
      .el-form{
        .el-form-item{
          // width: 51%;
          span{
            color: #A9A9A9;
            font-size: 18px;
          }
          .el-checkbox{
            ::v-deep .el-checkbox__label{
               color: #FF9D0B;
              font-size: 18px;
            }
          }
        }
      }
    }
    .btnBinie{
      margin-left: 20px;
    }
    .btnBinie1{
      margin-left: 20px;
    }
    .locationOpenvalve{
  .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
      }
    }
    }
    .fes{
      display: flex;
      flex-wrap: wrap;
    }
    // 移动端
    @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .suso{
        padding: 10px 10px 0px 10px;
      }
      .btnBinie1{
        margin-left: 0px;
      }
      .num{
        margin-left: 0px;
      }
      .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 94%;
    .el-dialog__body .upload-demo .el-upload .el-upload-dragger{
      width: 310px;
    }
    .el-dialog__body{
      padding: 20px 10px;
    }
      }
    }
    }
</style>
