<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>账务报表</el-breadcrumb-item>
        <el-breadcrumb-item>售水日报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <Billflow :getname="getname"></Billflow>
      </el-form>
      <el-form>
        <el-form-item>
          <span>总微信支付金额: </span><span>{{tabfais.total_wechat_pay_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总微信支付次数: </span><span>{{tabfais.total_wechat_pay_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总业务售水金额: </span><span>{{tabfais.total_pay_type1_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总业务售水次数: </span><span>{{tabfais.total_pay_type1_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总优惠售水金额: </span><span>{{tabfais.total_pay_type2_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总优惠售水次数: </span><span>{{tabfais.total_pay_type2_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总系统结转金额: </span><span>{{tabfais.total_pay_type3_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总系统结转次数: </span><span>{{tabfais.total_pay_type3_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总平台退费金额: </span><span>{{tabfais.total_returned_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总平台退费次数: </span><span>{{tabfais.total_returned_count}}</span>&emsp;
        </el-form-item>
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='400'>
    <el-table-column type="index" width="60" />
    <el-table-column property="wechat_pay_money" label="微信支付金额" width="" />
    <el-table-column property="wechat_pay_count" label="微信支付次数" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type1_money" label="业务售水金额" width="" />
    <el-table-column property="pay_type1_count" label="业务售水次数" width="" />
    <el-table-column property="pay_type2_money" label="优惠售水金额" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type2_count" label="优惠售水次数" width="" />
    <el-table-column property="pay_type3_money" label="系统结转金额" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type3_count" label="系统结转次数" width="" />
    <el-table-column property="returned_money" label="平台退费金额" />
    <el-table-column property="returned_count" label="平台退费次数" :show-overflow-tooltip="true" />
    <el-table-column property="pay_date" label="统计时间" width="" />
  </el-table>
      </el-card>
  </div>
    </div>
</template>
<script>
import Billflow from '../../../components/sshuiyuebao'
export default {
  components: {
    Billflow
  },
  inject: ['reload'],
  data () {
    return {
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      tabfais: {},
      total: 0,
      loading: true,
      // 登录返回的项目id
      famlis: {
        token: null
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    // 重置
    shuaxin () {
      this.reload()
    },
    getname (name) {
      console.log('App收到了子组件传过来的数据', name)
      this.tableData = name.data_list
      this.tabfais = name
      this.total = name.total
    },
    // table数据
    tabData () {
      this.$api.finaciStates.MoneyStatisticals(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data_list
          this.tabfais = res.data
          this.total = res.data.total
          this.loading = false
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      color: #fff;
      border: 1px solid #744dfe;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
  // 充值汇总
.el-dialog{
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .el-form{
          display: flex;
          flex-wrap: wrap;
          .el-form-item{
            .el-button{
              background-color: #744dfe;
              color: #fff;
            }
          }
        }
      }

    // <!-- table表格 -->
    .tabs{
      width: 100%;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
    // 移动端
  @media (max-width: 768px) {
    .Gnv{
      display: none;
    }
    .suso{
      padding: 10px 10px;
      .el-form{
        .el-form-item{
          margin-bottom: 0px;
        }
      }
    }
    .tab{
      padding: 10px 10px;
    }
  }
</style>
