<template>
  <div class="T">
    <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>水表操作</el-breadcrumb-item>
        <el-breadcrumb-item>冻结数据</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-input placeholder="请输入关键信息" v-model="cxyts.search_content" size="small"></el-input>
        </el-form-item>
        <el-form-item label="小区:" style="margin-left: 30px">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:" style="margin-left: 30px">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:" style="margin-left: 30px">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:" style="margin-left: 30px">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
            @change="selectroom(cxyts.room_id)">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button type="primary" @click="pay_search" size="small">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="shuaxin" size="small">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 按钮区域 -->
    <div class="ButtonArea">
        <div><el-button @click="ridojie">日冻结数据</el-button></div>
        <div><el-button @click="yuedata">月冻结数据</el-button></div>
    </div>
    <!-- 内容区域 -->
    <div class="tab">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="parData"
        tooltip-effect="dark"
        style="width: 100%"
        height='500'
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column
        prop="pay_id"
        label="缴费编号"
        width="100">
        </el-table-column>
        <el-table-column
        prop="meter_id"
        label="表号"
        width="150">
        </el-table-column>
        <el-table-column
        prop="user.name"
        label="用户名"
        width="140">
        </el-table-column>
        <el-table-column
        prop="user.address"
        label="位置"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="updateTime"
        label="最新上报时间"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="sumFlow"
        label="正向累积流量"
        width="140">
        </el-table-column>
        <el-table-column
        label="净累积流量"
        width="120">
        <template slot-scope="scope">{{ scope.row.netAccumulation }}</template>
        </el-table-column>
        <el-table-column
        prop="reverseAccumulation"
        label="反向累积流量"
        width="120">
        </el-table-column>
        <el-table-column
        prop="atAccumulation"
        label="瞬时流量"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="batteryVoltage"
        label="电池电压"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="signallntensity"
        label="信号强度"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerDown"
        label="掉电状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="powerLow"
        label="低压状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="valveStatus"
        label="阀门状态"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
        prop="magneticAttack"
        label="磁攻击"
        show-overflow-tooltip>
        </el-table-column>
        <el-table-column
      fixed="right"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          @click="cxunbtn(scope.row)">
          查询设置进程
        </el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
    </div>
  <!-- 查询设置进程 -->
  <!-- 编辑的弹框 -->
  <el-dialog title="查询进程" :visible.sync="dialgVsibles" width="40%">
    <div class="wwoop"><span>设备编号: {{this.ccnn}}</span><i class="el-icon-refresh-right" @click="Rt"></i></div>
     <el-table
    :data="bansdata"
    border
    :header-cell-style="{'text-align':'center'}"
    :cell-style="{'text-align':'center'}"
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      width="70"
      >
    </el-table-column>
    <el-table-column
      prop="meter_id"
      label="表具编号"
      >
    </el-table-column>
    <el-table-column
      prop="command_type"
      label="操作内容"
      width="120"
      >
    </el-table-column>
    <el-table-column
      prop="status"
      label="操作结果">
    </el-table-column>
    <el-table-column
      prop="send_time"
      label="操作时间">
    </el-table-column>
  </el-table>
    </el-dialog>
    <!-- 编辑的弹框 日冻结数据-->
  <el-dialog title="日冻结" :visible.sync="rdjVsibles" width="50%">
    <div class="block">
    <el-date-picker
      v-model="rdj"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
    <el-button>查询</el-button>
  </div>
  <div class="rdojie">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="parData"
        tooltip-effect="dark"
        style="width: 100%"
        height='200'
        size=mini
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangZhengXiangF"
        label="正向累积流量"
        width="100">
        </el-table-column>
        <el-table-column
        prop="lastYueDongJieShiJian"
        label="月冻结时间"
        width="150">
        </el-table-column>
        <el-table-column
        prop="meterId"
        label="表号"
        width="140">
        </el-table-column>
        <el-table-column
        prop="lastUploadDate"
        label="上传日期"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangF"
        label="净累积流量"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangFanXiangF"
        label="反向累积流量"
        width="140">
        </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
    </div>
    </el-dialog>
    <!-- 编辑的弹框 日冻结数据-->
  <el-dialog title="月冻结" :visible.sync="yueVsibles" width="50%">
    <div class="block">
    <el-date-picker
      type="month"
      format='hh:mm'
      v-model="rdais.month_date"
      @change='er'
      placeholder="选择月">
    </el-date-picker>
    <el-button @click="fasidata">查询</el-button>
  </div>
  <div class="rdojie">
      <el-card>
        <el-table
        ref="multipleTable"
        :data="yuedatas"
        tooltip-effect="dark"
        style="width: 100%"
        height='200'
        size=mini
        @selection-change="handleSelectionChange">
        <el-table-column
        type="selection"
        width="55">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangZhengXiangF"
        label="正向累积流量"
        width="100">
        </el-table-column>
        <el-table-column
        prop="lastYueDongJieShiJian"
        label="月冻结时间"
        width="150">
        </el-table-column>
        <el-table-column
        prop="meterId"
        label="表号"
        width="140">
        </el-table-column>
        <el-table-column
        prop="lastUploadDate"
        label="上传日期"
        width="150"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangF"
        label="净累积流量"
        width="140"
        :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
        prop="leiJiLiuLiangFanXiangF"
        label="反向累积流量"
        width="140">
        </el-table-column>
    </el-table>
      </el-card>
    </div>
    </el-dialog>
    <!-- 暂无数据 -->
    <div class="wuju" v-show="zanwu">暂无数据</div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      yueVsibles: null,
      value2: null,
      rdj: null,
      rdjVsibles: false,
      num: 10,
      zqiVsibles: false,
      isday: false,
      isyue: false,
      bansdata: [],
      fixedVsibles: false,
      dialgVsibles: false,
      TestCloseVisible: false,
      callCloseVisible: false,
      CloseVisible: false,
      // 暂无数据
      zanwu: false,
      fansd: [],
      huoers: null,
      // 添加
      bushuData: {
        address: null,
        location: null,
        meter: null,
        name: null,
        pay_id: null,
        phone: null
      },
      yuedatas: [],
      // 主体数据
      parData: [{
        address: null,
        balance: null,
        meter_id: null,
        meter_status: null,
        meter_type: null,
        name: null,
        pay_id: null,
        phone: null,
        water_type: null
      }],
      famlis: {
        // project_id: null,
        token: null,
        pagesize: 20,
        pagenum: 1
        // search_content: null,
        // location_id: null,
        // insufficient_balance: null
      },
      total: null,
      userBmfi: {
        project_id: null,
        token: null,
        pay_id: null
      },
      project_id: null,
      userfima: {
        project_id: null,
        pay_id: null,
        token: null,
        meter_id: 0
      },
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      zaisns: [{
        id: 1,
        name: null
      }],
      cxyts: {
        project_id: null,
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null,
        insufficient_balance: null,
        room_id: null
      },
      ValveControl: {
        token: null,
        regional_issued: false,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        meter_id_list: [],
        lengthid: null,
        optionId: 1,
        params: {
          d1: null
        }
      },
      ccnn: null,
      ooo: null,
      fff: null,
      ioio: null,
      ishuor: null,
      isdayst: null,
      uuu: null,
      list: [],
      cuanss: {
        token: null,
        meter_id: null
      },
      rdais: {
        token: null,
        meter_id_list: [],
        date_string: null,
        month_date: null
      },
      currentDateStart: null
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.userBmfi.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userBmfi.token = this.$store.state.print.goodsForeNo.token
    this.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userData()
    this.formatDate()
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
    // this.CxunR()
  },
  methods: {
    // er (val) {
    //   console.log(val)
    // },
    // 日期确定---开始时间
    // 标准时间转年月日
    formatDate (date) {
      console.log('data=', date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    er (data) {
      console.log('日期确定触发', data)
      this.currentDateStart = this.formatDate(data) // 将获取到的时间调用formatDate方法转换成年月日
      console.log('this.currentDateStart=', this.currentDateStart)
      this.rdais.month_date = this.currentDateStart
    //   this.valueStart = this.currentDateStart,
    //   this.valueEnd = ''
    //   this.showStart = false
    },
    fixedbtn () {},
    // 日冻结数据
    fasidata () {
      this.rdais.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getdeviceFrozenData(this.rdais).then((res) => {
        console.log(res, 'res')
        this.yuedatas = res.data.data
      })
    },
    yuedata () {
      this.yueVsibles = true
    },
    ridojie () {
      this.rdjVsibles = true
    },
    // 刷新
    Rt () {
      console.log(23)
      // this.CxunR()
    },
    // 查询设置进程
    cxunbtn (row) {
      this.cuanss.token = this.$store.state.print.goodsForeNo.token
      this.cuanss.meter_id = row.meter_id
      this.ccnn = row.meter_id
      console.log(row, 'row')
      this.dialgVsibles = true
      this.$api.waterMeter.getdeviceCommand(this.cuanss).then((res) => {
        console.log(res, '查询设置进程')
        if (res.data.status === 'success') {
          console.log(res, '查询设置进程')
          this.bansdata = res.data.data
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.bansdata = null
        }
      })
      // this.CxunR()
    },
    CxunR () {
      this.$api.waterMeter.getdeviceCommand(this.cuanss).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '查询设置进程')
          this.bansdata = res.data.data
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 全选按钮
    handleCheckedCitiesChange (val) {
      console.log(val, 'quanx')
    },
    // table选择
    handleSelectionChange (val) {
      console.log(val, 'meter_id_list')
      // 选中的条数
      this.ValveControl.meter_id_list = val
      this.ValveControl.lengthid = val.length
      this.rdais.meter_id_list = val
      // for (var i in val) {
      //   console.log(val[i].meter_id, i)
      // }
      // // 选中的数据
      // this.ValveControl.meter_id_list = val[i].meter_id
      // console.log(this.ValveControl.meter_id_list, 'this.ValveControl.meter_id_list')
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.ValveControl.community_id = val
      console.log(this.ValveControl.community_id, '给阀门控制id')
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.ValveControl.community_id = val
      console.log(this.ValveControl.community_id, '给阀门控制id')
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.ValveControl.community_id = val
      console.log(this.ValveControl.community_id, '给阀门控制id')
      this.cxyts.room_id = null
      this.room()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
      this.ValveControl.community_id = val
      console.log(this.ValveControl.community_id, '给阀门控制id')
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 查询
    pay_search () {
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res.data.data
        this.total = res.data.total
        if (this.total === 0) {
          this.zanwu = true
        }
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 主体数据
    userData () {
      this.$api.waterMeter.getmeterTable(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.parData = res.data.data
          this.total = res.data.total
          if (this.total === 0) {
            this.zanwu = true
          }
        }
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.famlis.insufficient_balance = this.cxyts.insufficient_balance
      this.userData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.famlis.insufficient_balance = this.cxyts.insufficient_balance
      this.userData()
    }
  }
}

</script>

<style lang="less" scoped>
.T{
    padding: 0;
}
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  // width: 900px;
  padding: 20px 0px 0px 74px;
  display: flex;
  // border: 1px solid #474747;
  .el-form {
    display: flex;
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    .el-form-item{
      margin-bottom: 9px;
      display: flex;
      .ilo{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      .ili{
      cursor: pointer;
      width: 102px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      font-size: 22px;
      color: #656d92;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-select{
      width: 150px;
    }
    }
  }
}
// 主体区域
.navto {
display: flex;
flex-wrap: wrap;
padding: 0px 0px 50px 57px;
.num {
    margin-top: 13px;
    margin-left: 17px;
    width: 368px;
    height: 253px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 1px rgba(116, 77, 254, 0.5);
    border-radius: 7px;
    border: solid 2px #c6c6c6;
    line-height: 31px;
  .iconfont {
    font-size: 30px;
    color: #744dfe;
  }
  .num1 {
      padding: 10px 10px;
      display: flex;
      align-items: center;
    span {
      margin-left: 20px;
      font-family: PingFangSC-Heavy;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 31px;
      letter-spacing: 2px;
      color: #474747;
    }
    .el-button {
      float: right;
      margin-left: 30px;
      background-color: #F5F5F5;
      color: #9FA2AE;
      border: 1px solid #DDD5FC;
    }
  }
  .numsop{
    font-family: PingFangSC-Heavy;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 2px;
    color: #656d92;
    padding-left: 60px;
  }
  .num2 {
    .num-1{
        padding: 0px 10px;
        .nui{
          margin-left: 19px;
        }
        span{
          font-family: MiSans-Normal;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 2px;
          color: #656d92;
        }
    }
    .nummane{
      line-height: 50px;
      padding-left: 60px;
      font-family: MiSans-Normal;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 2px;
      color: #656d92;
    }
    .num-2{
        text-align: center;
        padding: 10px 0px;
        color: #9194AF;
    }
    .num-3{
        padding: 3px 0px;
        span{
            padding: 0px 45px;
        }
    }
  }
  .numbtn{
      margin-top: 15px;
         border-top: 1px solid #DED6FF;
         padding: 5 px 0px;
         display: flex;
         justify-content: space-between;
     }
     .numbtns{
       margin-top: 10px;
        display: flex;
         justify-content: space-between;
       .el-button{
             width: 33%;
             border: none;
             border-right: 1px solid #DED6FF;
             font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 2px;
            // color: #474747;
         }
        .btnbang{
          background-color: #909399;
          color: #fff;
        }
         .el-button:nth-child(3){
             border-right: none;
         }
     }
}
}
// 分页
.feye{
  background-color: rgb(247, 245, 245);
  width: 1542px;
  height: 40px;
  position: fixed;
  right: 20px;
  bottom: 0;
}
// 暂无数据
.wuju{
  margin-left: 40%;
  margin-top: 15%;
}
// <!-- table表格 -->
    .tab{
        padding: 30px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
            ::v-deep .el-table th.el-table__cell{
              background-color: #987AFF;
            }
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            // background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
          background-color: #744dfe;
          color: #FFF;
          letter-spacing: 3PX;
        }
    }
    // 按钮区域
    .ButtonArea{
        display: flex;
        margin-left: 54px;
        margin-top: 10px;
        .el-button{
            margin-left: 20px;
            background-color: #744dfe;
            color: #fff;
            letter-spacing: 4px;
            border: 0px;
        }
    }
    // 开阀操作
    .locationOpenvalve{
      .el-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .el-form-item{
          width: 51%;
          span{
            color: #A9A9A9;
            font-size: 18px;
          }
          .el-checkbox{
            ::v-deep .el-checkbox__label{
               color: #FF9D0B;
              font-size: 18px;
            }
          }
        }
      }
    }
    // 查询进程
    .wwoop{
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      .el-icon-refresh-right{
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #928d8d;
        font-size: 16px;
      }
    }
    .dingdianfas{
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
    .zhuos{
      margin-top: 20px;
      color: brown;
    }
    // <!-- table表格 -->
    .rdojie{
      margin-top: 20px;
        // padding: 30px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
            ::v-deep .el-table th.el-table__cell{
              background-color: #987AFF;
            }
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            // background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
          background-color: #744dfe;
          color: #FFF;
          letter-spacing: 3PX;
        }
    }
</style>
