import request from '../utils/request'

// 用户列表的添加
export function Logins (data) {
  return request({
    method: 'post',
    url: 'webUserLogin',
    data
  })
}

// 退出
export function postwebUserLogout (data) {
  return request({
    method: 'post',
    url: 'webUserLogout',
    data
  })
}

// 消息
export function notifys (data) {
  return request({
    method: 'get',
    url: 'notify',
    params: data
  })
}

// 获取logio
export function projectLogo (data) {
  return request({
    method: 'get',
    url: 'projectLogo',
    params: data
  })
}
