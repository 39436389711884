<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item>阀控策略</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="topToip">
      <el-button type="text" @click="BaisData">查看阀控策略白名单</el-button>
      <!-- <el-button @click="chuanko">串口</el-button> -->
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='300'>
    <el-table-column property="name" label="名称" width="" />
    <el-table-column property="automatic_closing_valve_for_arrears" label="欠费自动关阀" width="" >
      <template slot-scope="{row: {automatic_closing_valve_for_arrears}}">
        <span v-if="automatic_closing_valve_for_arrears === true">是</span>
        <span v-else>否</span>
      </template>
    </el-table-column>
    <el-table-column property="alarm_close_valve_standard" label="报警关阀标准" width="" />
    <el-table-column property="owe_close_valve_standard" label="欠费自动关阀标准" width="" />
    <el-table-column property="valve" label="操作"  fixed="right">
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)">修改</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
  </div>
   <!-- 编辑的弹框 -->
   <div class="locationOpenvalve">
  <el-dialog title="账号编辑" :visible.sync="diaes">
  <div class="spans"/>
  <el-form>
    <el-form-item label="欠费自动关阀:" style="letter-spacing: 0px;">
              <el-select placeholder="请选择是否欠费" v-model="ficsData.automatic_closing_valve_for_arrears">
                <el-option
                  v-for="item in zaisns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
    </el-form-item>
    <el-form-item label="报警关阀剩余量:" style="margin-left:10px">
      <el-input v-model.trim="ficsData.alarm_close_valve_standard" type="number" style="width: 100px;"></el-input>&nbsp;
      <span>元</span>
    </el-form-item>
    <el-form-item label="欠费自动关阀剩余量:" style="margin-left:10px">
      <el-input v-model.trim="ficsData.owe_close_valve_standard" type="number" style="width: 100px;"></el-input>&nbsp;
      <span>元</span>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click=Biaos> <i class="el-icon-document"/>保 存</el-button>
  </span>
</el-dialog>
</div>
<!-- 查看白名单 -->
<div class="locationOpenvalve">
<el-dialog title="阀控策略白名单" :visible.sync="dialogTableVisible">
  <div class="Sousui">
    <el-input v-model="Cxions.meter_id" placeholder="请输入表号"></el-input>
    <el-button @click="SoiCanBtn">查询</el-button>
    <el-button @click="SoiXanBtn">刷新</el-button>
  </div>
  <el-table :data="gridData" highlight-current-row :header-cell-style="{'text-align':'center'}"  :cell-style="{'text-align':'center'}" height='300'>
    <el-table-column property="meter_id" label="表号"></el-table-column>
    <el-table-column
      fixed="right"
      label="操作">
      <template slot-scope="scope">
        <el-button
          @click="deleteRow(scope.row)"
          type="text"
          size="small">
          移除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</el-dialog>
</div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      // 修改保存
      ficsData: {
        token: null,
        community_id: null,
        automatic_closing_valve_for_arrears: null,
        owe_close_valve_standard: null,
        alarm_close_valve_standard: null
      },
      diaes: false,
      // tab
      tableData: [{
        name: null,
        automatic_closing_valve_for_arrears: null,
        mch_id: null,
        wechat_appid: null
      }],
      loading: true,
      // 登录返回的项目id
      famlis: {
        token: null
      },
      // 费用下拉框
      zaisns: [{
        id: true,
        name: '是'
      },
      {
        id: false,
        name: '否'
      }],
      // 白名单
      gridData: [],
      dialogTableVisible: false,
      // 移除白名单
      Yichu: {
        token: null,
        meter_id: null
      },
      // 查询白名单
      Cxions: {
        token: null,
        meter_id: null
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
  },
  methods: {
    async chuanko () {
      // 提示用户选择一个串口。
      const port = await navigator.serial.requestPort()
      console.log(port)
      // 等待串口打开
      await port.open({ baudRate: 57600 })
      // 异步监听串口数据
      while (port.readable) {
        const reader = port.readable.getReader()
        console.log(reader, 'reader')
        try {
          while (true) {
            const { value, done } = await reader.read()
            console.log(value, done, 'valiedone')
            if (done) {
              // 接收完毕，串口解锁
              reader.releaseLock()
              break
            }
            if (value) {
              console.log(value)
            }
          }
        } catch (error) {
          // 错误处理
        }
      }
    },
    // table数据
    tabData () {
      this.$api.userProfile.communities(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res.data.data, '全局配置tab')
          this.tableData = res.data.data
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 编辑
    ficsati (row) {
      console.log(row, '修改')
      this.ficsData.automatic_closing_valve_for_arrears = row.automatic_closing_valve_for_arrears
      this.ficsData.owe_close_valve_standard = row.owe_close_valve_standard
      this.ficsData.alarm_close_valve_standard = row.alarm_close_valve_standard
      console.log(this.ficsData)
      this.ficsData.community_id = row.id
      this.diaes = true
    },
    // 修改保存
    Biaos () {
      this.ficsData.token = this.$store.state.print.goodsForeNo.token
      this.$api.Location.postcommunities(this.ficsData).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改成功值')
          this.diaes = false
          this.reload()
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 白名单获取
    BaisData () {
      this.dialogTableVisible = true
      this.$api.waterMeter.closeValveServerWhiteListGet(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '获取白名单数据')
          this.gridData = res.data.white_list
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 查询白名单
    SoiCanBtn () {
      this.Cxions.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.closeValveServerWhiteListGet(this.Cxions).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '获取白名单数据')
          this.gridData = res.data.white_list
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 刷新白名单
    SoiXanBtn () {
      this.BaisData()
      this.Cxions.meter_id = null
    },
    // 白名单移除
    deleteRow (row) {
      console.log(row, '移除白名单')
      this.Yichu.token = this.$store.state.print.goodsForeNo.token
      this.Yichu.meter_id = row.meter_id
      this.$api.waterMeter.closeValveServerWhiteListDel(this.Yichu).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '移除百名但')
          this.BaisData()
          this.$message({
            message: res.data.message,
            type: 'success'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        } else if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 按钮区域
.topToip{
  margin: 20px 70px;
  .el-button{
    background-color: #744dfe;
    color: #fff;
    padding: 10px 10px;
  }
}
// <!-- table表格 -->
    .tab{
        padding: 20px 30px 0px 70px;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
    // 修改弹框
    .el-dialog{
      .spans{
        width: 3px;
        height: 15px;
        background-color: #744dfe;
        display: inline-block;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .el-form{
        display: flex;
        flex-wrap: wrap;
        .el-form-item{
          display: flex;
          .el-input{
            width: 200px;
          }
          .el-select{
            width: 180px;
          }
        }
      }
      .dialog-footer{
        .el-button{
          background-color: #fff;
          border: 1px solid #744dfe;
          color: #575757;
          border-radius: 10px;
          font-size: 17px;
          span{
            i{
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .Sousui{
      display: flex;
    }
    .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
  }
}
        // 移动端
        @media (max-width: 768px) {
      .Gnv{
        display: none;
      }
      .topToip{
        margin: 10px 10px;
      }
      .tab{
        padding: 10px 10px;
      }
      .locationOpenvalve .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 94%;
  }
}
    }
</style>
