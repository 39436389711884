import request from '../../utils/request'

// 用户档案的数据
export function detailsDates (data) {
  return request({
    method: 'get',
    url: 'userPayment',
    params: data
  })
}

// 账单查询
export function detabills (data) {
  return request({
    method: 'get',
    url: 'bills',
    params: data
  })
}

// 撤销
export function deletebills (data) {
  return request({
    method: 'DELETE',
    url: 'bills',
    data
  })
}

// 日报
export function MoneyStatisticals (data) {
  return request({
    method: 'post',
    url: 'projectMoneyStatistical',
    data
  })
}
