import request from '../../utils/request'

// 首页的数据
export function dwggetos (data) {
  return request({
    method: 'post',
    url: 'statisticalInformation',
    data
  })
}

// 首页的余额信息
export function informations (data) {
  return request({
    method: 'post',
    url: 'projectMoneyStatistical',
    data
  })
}

// 异常警告数据
export function Exceptions (data) {
  return request({
    method: 'post',
    url: 'meterWarning',
    data
  })
}

// 异常警告数据
export function paymentProportion (data) {
  return request({
    method: 'post',
    url: 'statisticalInformation',
    data
  })
}
