<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>账务报表</el-breadcrumb-item>
        <el-breadcrumb-item>售水月报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <el-date-picker
            v-model="time"
            type="monthrange"
            range-separator="至"
           start-placeholder="开始月份"
           end-placeholder="结束月份"
           value-format="yyyy-MM">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="小区:">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
            @change="selectroom(cxyts.room_id)">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="pay_search">查 询</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button type="primary" @click="shuaxin">重 置</el-button>
        </el-form-item>
      </el-form>
      <el-form style="display: flex; flex-wrap: wrap;">
        <el-form-item>
          <span>总微信支付金额: </span><span>{{tabfais.total_wechat_pay_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总微信支付次数: </span><span>{{tabfais.total_wechat_pay_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总业务售水金额: </span><span>{{tabfais.total_pay_type1_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总业务售水次数: </span><span>{{tabfais.total_pay_type1_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总优惠售水金额: </span><span>{{tabfais.total_pay_type2_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总优惠售水次数: </span><span>{{tabfais.total_pay_type2_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总系统结转金额: </span><span>{{tabfais.total_pay_type3_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总系统结转次数: </span><span>{{tabfais.total_pay_type3_count}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总平台退费金额: </span><span>{{tabfais.total_returned_money}}</span>&emsp;
        </el-form-item>
        <el-form-item>
          <span>总平台退费次数: </span><span>{{tabfais.total_returned_count}}</span>&emsp;
        </el-form-item>
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini :header-cell-style = "{background:'#987AFF'}" height='500'>
    <el-table-column type="index" width="60" />
    <el-table-column property="wechat_pay_money" label="微信支付金额" width="" />
    <el-table-column property="wechat_pay_count" label="微信支付次数" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type1_money" label="业务售水金额" width="" />
    <el-table-column property="pay_type1_count" label="业务售水次数" width="" />
    <el-table-column property="pay_type2_money" label="优惠售水金额" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type2_count" label="优惠售水次数" width="" />
    <el-table-column property="pay_type3_money" label="系统结转金额" width="" :show-overflow-tooltip="true" />
    <el-table-column property="pay_type3_count" label="系统结转次数" width="" />
    <el-table-column property="returned_money" label="平台退费金额" />
    <el-table-column property="returned_count" label="平台退费次数" :show-overflow-tooltip="true" />
    <el-table-column property="pay_date" label="统计时间" width="" />
  </el-table>
      </el-card>
  </div>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      // tab
      tableData: [],
      tabfais: {},
      total: 0,
      famlis: {
        token: null,
        start_time: null,
        end_time: null
      },
      time: [],
      cxyts: {
        token: null,
        search_content: null,
        start_time: null,
        end_time: null
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
  },
  methods: {
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 查询
    pay_search () {
      console.log(this.time, '123')
      console.log(this.time[0], 'start_time ')
      this.cxyts.start_time = this.time[0]
      this.cxyts.end_time = this.time[1]
      this.famlis.start_time = this.time[0]
      this.famlis.end_time = this.time[1]
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.MoneyStatisticals(this.cxyts).then((res) => {
        if (this.cxyts.end_time === undefined) {
          this.$message.error('请选择时间')
        } else {
          console.log(res, '查询')
          this.tableData = res.data.data_list
          this.tabfais = res.data
          console.log(this.cxyts.end_time)
          if (res.data.status === 'success') {
            console.log(111111111111111)
            // table数据
            this.$api.finaciStates.MoneyStatisticals(this.famlis).then((res) => {
              if (res.data.status === 'success') {
                console.log(res, 'res')
                this.tableData = res.data.data_list
                this.tabfais = res.data
                this.total = res.data.total
                // this.loading = false
              } else if (res.data.status === 'error' && res.data.message === '用户在别处登录') {
                this.$message.error(res.data.message)
                this.$router.push('/', this.fasinull)
              }
            })
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
          if (res.data.message === '用户在别处登录') {
            this.$router.push('/', this.fasinull)
          }
        }
      })
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.cxyts.room_id = null
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.tableData = res
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 20px 0px 0px 74px;
  .el-form{
      display: flex;
      flex-wrap: wrap;
      .el-input {
      border-radius: 6px;
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    }
  .el-form-item{
      display: flex;
      margin-top: 20px;
    .el-select{
      width: 150px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
  // 充值汇总
.el-dialog{
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .el-form{
          display: flex;
          flex-wrap: wrap;
          .el-form-item{
            .el-button{
              background-color: #744dfe;
              color: #fff;
            }
          }
        }
      }

    // <!-- table表格 -->
    .tabs{
      width: 100%;
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
     // 移动端
  @media (max-width: 768px) {
    .Gnv{
      display: none;
    }
    .suso{
      padding: 10px 10px;
      .el-form{
        .el-form-item{
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
    .tab{
      padding: 10px 10px;
    }
  }
</style>
