import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login' // 登录页面
import Users from '../views/users' // 项目首页
import WaterType from '../views/basicData/WaterType' // 用水类型
import PositionTion from '../views/basicData/positionTion' // 位置信息
import WaterMeterFile from '../views/basicData/waterMeterFile' // 水表档案
import FileDetails from '../views/basicData/waterMeterFile/fileDetails.vue' // 水表档案详情
import UserProfile from '../views/basicData/userProfile' // 用户档案
import UserDetails from '../views/basicData/userProfile/userDetails.vue' // 用户档案详情
import UserAdd from '../views/basicData/userProfile/userAdd' // 用户档案添加
import UserBinDing from '../views/basicData/userProfile/userBinding' // 用户档案换表
import UserBin from '../views/basicData/userProfile/userWaterBin' // 用户档案绑定水表
import TableRecord from '../views/basicData/userProfile/TableRecord' // 换表记录
import Strategy from '../views/basicData/strategy' // 阀控策略
import Gaugetype from '../views/basicData/Gaugetype' // 表具类型
// --------------------------------------------------------------------------------------------------------------------------------------------------------------
// 财务管理
import accountMent from '../views/financialManagement/accountMent' // 交款/退款
// --------------------------------------------------------------------------------------------------------------------------------------------------------------
// 财务报表
import Details from '../views/financialStatements/details' // 充值记录
import Summary from '../views/financialStatements/summary' // 账单查询
import sshuiyuebao from '../views/financialStatements/sshuiyuebao' // 售水日报
import Sshuiyuebaos from '../views/financialStatements/sshuiyuebaos' // 售水月报
import Ssuinianbao from '../views/financialStatements/ssuinianbao' // 售水年报
// --------------------------------------------------------------------------------------------------------------------------------------------------------------
// 阀控管理
import ValveMent from '../views/ValveControl/valveMent' // 阀控管理
import lnstruction from '../views/ValveControl/Instruction' // 指令记录
// --------------------------------------------------------------------------------------------------------------------------------------------------------------
// 用水分析
import realtimeData from '../views/WaterAnalysis/realtimeData' // 实时数据
import historicalData from '../views/WaterAnalysis/historicalData' // 历史查询
// 系统管理
import userGement from '../views/Gement/userGement' // 用户管理
import globalRation from '../views/Gement/globalRation' // 全局配置
import Alertpush from '../views/Gement/Alertpush'

// 位置管理
import Community from '../views/Location/community' // 小区信息
import Building from '../views/Location/building' // 楼栋信息
import Unit from '../views/Location/unit' // 小区信息
import Room from '../views/Location/room' // 楼栋信息
// 水表操作
import Parameter from '../views/WatermeterOperation/Parameter/index.vue' // 参数设置
import Operation from '../views/WatermeterOperation/Operation/index.vue' // 操作日志
import Freeze from '../views/WatermeterOperation/Freeze/index.vue' // 操作日志
// 组件
import WaterAddition from '../components/WaterAddition' // 水表档案单条添加组件
import address from '../components/address' // 地址组件
import tableAssembly from '../views/basicData/waterMeterFile/tableAssembly'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/Users',
    children: [
      {
        path: '/users',
        meta: {
          requireAuth: true
        },
        component: Users
      }, // 项目首页
      { path: '/waterType', meta: { requireAuth: true }, component: WaterType }, // 用水类型
      { path: '/positionTion', meta: { requireAuth: true }, component: PositionTion }, // 位置信息
      { path: '/waterMeterFile', meta: { requireAuth: true }, component: WaterMeterFile }, // 水表档案
      { path: '/fileDetails', meta: { requireAuth: true }, component: FileDetails }, // 水表档案详情
      { path: '/userProfile', meta: { requireAuth: true }, component: UserProfile }, // 用户档案
      { path: '/strategy', meta: { requireAuth: true }, component: Strategy }, // 阀控策略
      { path: '/Gaugetype', meta: { requireAuth: true }, component: Gaugetype },
      { path: '/userDetails', meta: { requireAuth: true }, component: UserDetails }, // 用户档案详情
      { path: '/userAdd', meta: { requireAuth: true }, component: UserAdd }, // 用户档案添加
      { path: '/userBinDing', meta: { requireAuth: true }, component: UserBinDing }, // 用户档案换表
      { path: '/userBin', meta: { requireAuth: true }, component: UserBin }, // 用户档案绑定水表
      { path: '/tableRecord', meta: { requireAuth: true }, component: TableRecord }, // 换表记录
      // ---------------------------------------------------------------------
      { path: '/accountMent', meta: { requireAuth: true }, component: accountMent }, // 交款/退款
      { path: '/details', meta: { requireAuth: true }, component: Details }, // 充值记录
      { path: '/summary', meta: { requireAuth: true }, component: Summary }, // 账单查询
      { path: '/sshuiyuebao', meta: { requireAuth: true }, component: sshuiyuebao }, // 账单查询
      { path: '/Sshuiyuebaos', meta: { requireAuth: true }, component: Sshuiyuebaos }, // 账单查询
      { path: '/ssuinianbao', meta: { requireAuth: true }, component: Ssuinianbao }, // 账单查询
      // 阀控管理
      { path: '/valveMent', meta: { requireAuth: true }, component: ValveMent }, // 阀控管理
      { path: '/instruction', meta: { requireAuth: true }, component: lnstruction }, // 指令记录
      // 用水分析
      { path: '/realTime', meta: { requireAuth: true }, component: realtimeData }, // 实时数据
      { path: '/dailyMption', meta: { requireAuth: true }, component: historicalData }, // 历史数据
      // 系统管理
      { path: '/userGement', meta: { requireAuth: true }, component: userGement }, // 用户管理
      { path: '/globalRation', meta: { requireAuth: true }, component: globalRation }, // 全局配置
      { path: '/Alertpush', meta: { requireAuth: true }, component: Alertpush }, // 全局配置
      // 位置管理
      { path: '/Community', meta: { requireAuth: true }, component: Community }, // 小区
      { path: '/Building', meta: { requireAuth: true }, component: Building }, // 楼栋
      { path: '/unit', meta: { requireAuth: true }, component: Unit }, // 位置
      { path: '/room', meta: { requireAuth: true }, component: Room }, // 房间
      // 水表操作
      { path: '/Parameter', meta: { requireAuth: true }, component: Parameter }, // 参数设置
      { path: '/Operation', meta: { requireAuth: true }, component: Operation }, // 操作日志
      { path: '/UserAdd', meta: { requireAuth: true }, component: Freeze }, // 冻结数据
      // 组件
      { path: '/WaterAddition', meta: { requireAuth: true }, component: WaterAddition }, // 水表档案单条添加组件
      { path: '/address', meta: { requireAuth: true }, component: address }, // 地址组件
      { path: '/tableAssembly', meta: { requireAuth: true }, component: tableAssembly }
    ]
  }
]

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}

// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

// var head = document.getElementsByTagName('head')
// var meta = document.createElement('meta')
// meta.name = 'referrer'
// meta.content = 'no-referrer'
// head[0].appendChild(meta)

// router.beforeEach((to, from, next) => {
//   if (to.path === '/') {
//     return next()
//   }
//   const tokenStr = window.sessionStorage.setItem('token')
//   if (!tokenStr) {
//     return next('/')
//   } else {
//     return next()
//   }
// })

// 没有token跳转到登录页
router.beforeEach((to, from, next) => {
  console.log(to, 'to')
  console.log(from, 'from')
  next()
  if (to.meta.requireAuth) {
    console.log(sessionStorage, 'sessionStorage.getItem')
    next()
    if (sessionStorage.getItem('token')) {
    } else {
      next({
        path: '/'
      })
    }
  } else {
    next()
  }
})

export default router
