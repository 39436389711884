<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务报表</el-breadcrumb-item>
        <el-breadcrumb-item>账单流水</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form style="display: flex;">
        <el-form-item>
        <Billflow :getname="getname" @getValue="getUser" @start="getstart" @end="getend"
         @transfer="getransfer" @Building="getBuilding" @unit="getUnit" @room="getRoom"
         @ParData="commitData" @buildingData="buildData" @unitData="uniData" @roomData="rooData"></Billflow>
        <el-button @click="quyuDaochu">区域导出</el-button>
      </el-form-item>
      </el-form>
    </div>
    <!-- 主体区域 -->
    <div class="num">
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini highlight-current-row
    :header-cell-style = "{background:'#987AFF'}"  v-loading="loading" height='500'
    @selection-change="handleSelectionChange"
    :row-class-name="rowStyle"
    @select='xz'
    @select-all='xzAll'>
    <!-- <el-table-column  type="selection" width="70" /> -->
    <el-table-column type="index" width="70" />
    <el-table-column property="pay_id" label="缴费编号" width="80" />
    <el-table-column property="meter_id" label="水表编号" width="180" :show-overflow-tooltip="true" />
    <el-table-column property="username" label="用户姓名" width="100" />
    <el-table-column property="create_time" label="时间" width="190" :show-overflow-tooltip="true"  />
    <el-table-column property="amount" label="本次金额" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="address" label="用户地址" width="150" :show-overflow-tooltip="true" />
    <el-table-column property="flow_use" label="结算水量" width="150" />
    <el-table-column property="current_sum_flow" label="表码数" width="150" />
    <el-table-column property="aft_money" label="余额" width="150" />
    <el-table-column property="type" label="账单类型" width="100" />
    <el-table-column property="is_reversed" label="状态"/>
    <el-table-column property="note" label="备注" width="130" :show-overflow-tooltip="true" />
    <el-table-column property="valve" label="操作" width="106"  fixed="right">
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)">撤销</el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    </div>
    <div class="cycle">
  <el-dialog
  title="撤销"
  :visible.sync="diaes"
  >
  <div class="spans"/>
<div class="diaoninput">
      <span>备&nbsp;注: </span>
      <el-input type="text" v-model="deletefimfs.note" size='small '/>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="ficsBhci"><i class="el-icon-success" />确 定</el-button>
  </span>
</el-dialog>
</div>
<!-- //在这里将拿到的aa渲染到该表格 -->
<table class="table" v-show="false">
  <tr style="width:200px;">
    <td>序号</td>
    <td>缴费编号</td>
    <td>水表编号</td>
    <td>用户姓名</td>
    <td>联系方式</td>
    <td>余额</td>
    <!-- <td>启用状态</td> -->
    <td>用水类型</td>
    <td>在线状态</td>
    <td>阀门状态</td>
    <td>用户地址</td>
    <td>正向累计流量</td>
    <td>净累计流量</td>
    <td>证件号码</td>
  </tr>
  <!-- 动态渲染 -->
  <tr style="text-align:center;" v-for="(item,index) in multipleSelection" :key="index">
    <td style="width:200px;text-align:center;">{{index+1}}</td>  序号
    <td style="width:200px;text-align:center;">{{item.pay_id}}</td>
    <td style="width:200px;text-align:center;">{{item.meter_id}}</td>
    <td style="width:200px;text-align:center;">{{item.username}}</td>
    <td style="width:200px;text-align:center;">{{item.phone}}</td>
    <td style="width:200px;text-align:center;">{{item.balance}}</td>
    <!-- <td style="width:200px;text-align:center;">{{item.is_use}}</td> -->
    <td style="width:200px;text-align:center;">{{item.water_type_name}}</td>
    <td style="width:200px;text-align:center;">{{item.online}}</td>
    <td style="width:200px;text-align:center;">{{item.valveStatus}}</td>
    <td style="width:200px;text-align:center;">{{item.address}}</td>
    <td style="width:200px;text-align:center;">{{item.sumFlow}}</td>
    <td style="width:200px;text-align:center;">{{item.netAccumulation}}</td>
    <td style="width:200px;text-align:center;">{{item.card_number}}</td>
    <td style="width:200px;text-align:center;">{{item.valve}}</td>
  </tr>
</table>
    </div>
</template>
<script>
import Billflow from '../../../components/Billflow'
// import XLSX from 'xlsx'
// import FileSaver from 'file-saver'
import { dataConversionUtil } from '@/utils/data2Excel'
export default {
  components: {
    Billflow
  },
  inject: ['reload'],
  data () {
    return {
      // 选中导出
      multipleSelection: [],
      // 选中高亮
      ids: [],
      diaes: false,
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      formLabelWidth: '100px',
      // 下拉框
      seldata: [],
      options: [
        {
          name: '金湖'
        }
      ],
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      loading: true,
      // 登录返回的项目id
      famlis: {
        token: null,
        pagenum: 1,
        pagesize: 20,
        search_content: null,
        start_time: null,
        end_time: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null
      },
      tableSize: null,
      // 撤销
      deletefimfs: {
        project_id: null,
        token: null,
        bill_id: null,
        operate_user_id: null
      },
      // time: [],
      // cxyts: {
      //   token: null,
      //   search_content: null,
      //   start_time: null,
      //   end_time: null
      // },
      total: null,
      TableNo: null,
      start: null,
      end: null,
      qudaoCans: {
        token: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        pagesize: null,
        pagenum: 1
      }
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
    console.log(this.tableData, 'this.tableData')
  },
  methods: {
    getname (name) {
      console.log('App收到了子组件传过来的数据', name)
      this.tableData = name.data
      this.total = name.total
    },
    // // 查询
    // pay_search () {
    //   console.log(this.time, 'time')
    //   this.cxyts.start_time = this.time[0]
    //   this.cxyts.end_time = this.time[1]
    //   this.cxyts.token = this.$store.state.print.goodsForeNo.token
    //   this.$api.finaciStates.detabills(this.cxyts).then((res) => {
    //     console.log(res, '查询')
    //     this.tableData = res.data.data
    //     this.total = res.data.total
    //   })
    // },
    // 撤销
    ficsati (row) {
      this.diaes = true
      console.log(row, '撤销')
      this.deletefimfs.token = this.$store.state.print.goodsForeNo.token
      this.deletefimfs.operate_user_id = this.$store.state.print.goodsForeNo.id
      this.deletefimfs.bill_id = row.id
    },
    ficsBhci () {
      this.$api.finaciStates.deletebills(this.deletefimfs).then((res) => {
        console.log(res)
        if (res.data.status === 'success') {
          this.tabData()
          this.$notify({
            title: '成功',
            message: '撤销成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.diaes = false
    },
    // table数据
    tabData () {
      this.$api.finaciStates.detabills(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.total = res.data.total
          this.qudaoCans.pagesize = res.data.total
          this.loading = false
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    xz (x, y) {
      console.log(x, y)
      this.aa = x
    },
    xzAll (x) {
      console.log(x)
      this.aa = x
    },
    // 方法
    // exportexcel () {
    // // 定义xlsx文件的名字
    //   const name = '水表用户信息表'
    //   console.log(name)
    //   // 选择要导出的表格
    //   var wb = XLSX.utils.table_to_book(document.querySelector('.table'))

    //   var wbout = XLSX.write(wb, { // excel配置
    //     // 文件类型
    //     bookType: 'xlsx',
    //     bookSST: true,
    //     type: 'array'
    //   })
    //   wbout.style = 'text-align:center'
    //   console.log(wbout)
    //   try {
    //     //  name+'.xlsx'表示导出的excel表格名字
    //     // 将原生字符串转换为Uint8Array的数组，然后再通过FileSaver保存到文件
    //     FileSaver.saveAs(
    //       new Blob([wbout], { type: 'application/octet-stream' }),
    //       name + '.xlsx'
    //     )
    //   } catch (e) {
    //     if (typeof console !== 'undefined') console.log(e, wbout)
    //   }
    //   return wbout
    // },
    // 区域导出
    quyuDaochu () {
      this.qudaoCans.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detabills(this.qudaoCans).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '账单流水区域导出数据')
          var data = res.data.data
          debugger
          var tableHeader = [
            ['序号', '缴费编号', '水表编号', '用户姓名', '时间', '本次金额', '用户地址', '结算水量', '表码数', '余额', '账单类型', '状态', '备注']
          ]
          var dataList = []
          data.forEach((item, index) => {
            dataList.push([index + 1, item.pay_id, item.meter_id, item.username, item.create_time, item.amount, item.address, item.flow_use, item.current_sum_flow, item.aft_money, item.type, item.is_reversed, item.card_number, item.note])
          })
          dataConversionUtil.dataToExcel('账单流水区域信息表', tableHeader, dataList)
          this.$message.success('导出成功!')
          this.qudaoCans.community_id = null
          this.qudaoCans.building_id = null
          this.qudaoCans.unit_id = null
          this.qudaoCans.room_id = null
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
    // tab选中的数据
    handleSelectionChange (val) {
      console.log(val, 'tab选中的数据')
      this.ids = val.map(item => item.pay_id) // 选中高亮
      this.multipleSelection = val // 选中导出
    },
    // tab选中高亮效果
    rowStyle ({ row, rowIndex }) {
      var arrs = this.ids
      for (let i = 0; i < arrs.length; i++) {
        console.log(row.pay_id, arrs[i], 'row.pay_id,arrs')
        if (row.pay_id === arrs[i]) {
          return 'rowStyle'
        }
      }
    },
    // 输入框
    getUser (val) {
      console.log('子组件传过来的input输入的内容', val)
      this.TableNo = val
    },
    // 时间日期----开始
    getstart (val) {
      console.log('子组件传过来的开始选择的内容', val)
      this.start = val
      this.qudaoCans.start_time = val
    },
    // 结束
    getend (val) {
      console.log('子组件传过来的解释选择的内容', val)
      this.end = val
      this.qudaoCans.end_time = val
    },
    // 小区id
    getransfer (val) {
      console.log('小区子组件传过来的结束选择的内容', val)
      this.ransfer = val
      this.qudaoCans.community_id = val
    },
    // 小区数据
    commitData (val) {
      console.log(val, '小区数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    // 楼栋id
    getBuilding (val) {
      console.log('楼栋子组件传递过来的值：', val)
      this.Building = val
      this.qudaoCans.building_id = val
    },
    // 楼栋数据
    buildData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    // 单元id
    getUnit (val) {
      console.log('单元子组件传递过来的值：', val)
      this.Unit = val
      this.qudaoCans.unit_id = val
    },
    // 单元数据
    uniData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    // 房间id
    getRoom (val) {
      console.log('房间子组件传递过来的值：', val)
      this.Room = val
      this.qudaoCans.room_id = val
    },
    // 房间数据
    rooData (val) {
      console.log(val, '楼栋数据')
      this.tableData = val.data.data
      this.total = val.data.total
      this.qudaoCans.pagesize = val.data.total
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.famlis.search_content = this.TableNo
      this.famlis.start_time = this.start_time
      this.famlis.end_time = this.end_time
      this.famlis.community_id = this.ransfer
      this.famlis.building_id = this.Building
      this.famlis.unit_id = this.Unit
      this.famlis.room_id = this.Room
      this.tabData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage)
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.TableNo
      this.famlis.start_time = this.start
      this.famlis.end_time = this.end
      this.famlis.community_id = this.ransfer
      this.famlis.building_id = this.Building
      this.famlis.unit_id = this.Unit
      this.famlis.room_id = this.Room
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.rowStyle{
      background-color: oldlace !important;
    }
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 0px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
      color: #ffffff;
    }
    .sele {
      width: 400px;
      margin-left: 50px;
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
        ::v-deep .el-table__cell gutter{
              background-color: #744dfe;
            }
    }
    // 缴费提示
  .el-dialog{
    .el-dialog__header{
      span.dialog__title{
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
    }
    }
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 60px;
      }
      .diaoninput{
        display: flex;
        align-items: center;
        span{
            font-family: XinPingHeiTC-W2;
            font-size: 19px;
            // font-weight: bold;
            letter-spacing: 4px;
            color: #484848;
          }
      }
      .dialog-footer{
        .el-button{
          font-size: 20px;
          i{
            margin-right: 10px;
            font-size: 24px;
          }
        }
      }
  }
  .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 20%;
    }
    }
    }
  // 移动端
  @media (max-width: 768px) {
    .Gnv{
      display: none;
    }
    .suso{
      padding: 10px 10px;
    }
    .tab{
      padding: 10px 10px;
    }
    .cycle{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 92%;
    }
    }
    }
  }
</style>
