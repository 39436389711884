<template>
    <div>
        <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userProfile' }">用户档案</el-breadcrumb-item>
        <el-breadcrumb-item>添加信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 主体区域 -->
    <div class="num">
    <!-- 解绑水表 -->
    <div class="numa">
        <div class="numj">用户档案  <div class="vi" /></div>
        <div class="numx" />
        <div class="numf">
            <el-form v-model="userFile" label-width="80px" class="demo-form-inline">
                <el-form-item label="姓名:">
                    <el-input v-model.trim="userFile.name" placeholder="请输入姓名" size="small"></el-input>
                </el-form-item>&emsp;&emsp;
                <el-form-item label="联系电话:">
                    <el-input v-model.trim="userFile.phone" placeholder="请输入电话" size="small"></el-input>
                </el-form-item>
                <div class="ioxo">
                <el-form-item label="小区:">
                    <el-select v-model="userFile.quarters_id" placeholder="请选择小区" size="small"
                     @change="selectQuarters(userFile.quarters_id)">
                      <el-option
                        v-for="item in quarters"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-button size="mini" icon="el-icon-plus" @click="btnquars"/>
                </div>
                <div class="ioxo">
                <el-form-item label="楼栋:">
                    <el-select v-model="userFile.Buildings_id" placeholder="请选择楼栋" size="small"
                    @change="selectBuildings(userFile.Buildings_id)">
                      <el-option
                        v-for="item in Buildings"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-button size="mini" icon="el-icon-plus" @click="btnBuils"/>
                </div>
                <div class="ioxo">
                <el-form-item label="单元:">
                    <el-select v-model="userFile.units_id" placeholder="请选择单元" size="small"
                    @change="selectunit(userFile.units_id)">
                      <el-option
                        v-for="item in units"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-button size="mini" icon="el-icon-plus" @click="btnunits"/>
                </div>
                <div class="ioxo">
                <el-form-item label="房间:">
                    <el-select v-model="userFile.room_id" placeholder="请选择房间" size="small">
                      <el-option
                        v-for="item in rooms"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>
                <el-button size="mini" icon="el-icon-plus" @click="btnrooms"/>
                </div>
                <!-- <el-form-item label="位置信息:">
                    <el-input v-model="userFile.address" size="small"  placeholder="请输入位置" style="width: 550px"></el-input>
                </el-form-item> -->
                <el-form-item label="表具编号:">
                    <el-input v-model.trim="userFile.meter_id" size="small" disabled placeholder="当前未绑定水表" style="width: 550px"></el-input>
                </el-form-item>
                <!-- <div class="numbtn">
                    <el-button @click="fimaBtn"><i class="el-icon-circle-check" />确认绑定</el-button>
                </div> -->
            </el-form>
        </div>
    </div>
    <!-- 绑定水表 -->
    <div class="numb">
        <div class="numj">绑定水表<div class="vi" />
        <div class="ilo" @click="userAss">
                <span>返回</span>
                <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
            </div>
        </div>
        <div class="numx" />
        <div class="numbfai">
          <el-input size="small" placeholder="请搜索" v-model.trim="tabfams.search_content"></el-input>
          <el-button size="small" @click="sosuobtn">搜索</el-button>
        </div>
        <div>
        <el-card>
    <el-table :data="tableData" size=mini highlight-current-row v-loading="loading" :header-cell-style = "{background:'#987AFF'}" @current-change="handleCurrentChange" height='570' element-loading-text="拼命加载中">
    <el-table-column type="index" width="100" />
    <el-table-column property="meter_id" label="表具编号" width="170" />
    <el-table-column property="water_type_name" label="用水类型" width="140" :show-overflow-tooltip="true" />
    <el-table-column property="is_use" label="使用状态" width="140" :show-overflow-tooltip="true">
      <template slot-scope="{row: {is_use}}">
        <span v-if="+is_use === 0">未启用</span>
        <span v-else>启用</span>
      </template>
    </el-table-column>
    <el-table-column property="name" label="操作" width="150" >
        <template slot-scope="scope">
        <el-button size="mini" @click="userband(scope.row)">绑定</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
    </div>
    <div class="numbtn">
        <el-button style="small" @click="fimaBtn"><i class="el-icon-document" />保存</el-button>
    </div>
    </div>
    <!-- 添加弹框 -->
      <div>
        <!-- 添加小区弹框 -->
        <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogQuarters" width="21%">
        <el-form>
          <el-form-item label="小区名称: " :label-width="formLabelWidth">
            <el-input autocomplete="off" v-model.trim="addition.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="btnQuarters"><i class="el-icon-document" />保存</el-button>
        </div>
        </el-dialog>
        </div>
        <!-- 添加楼栋弹框 -->
        <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogBuildings" width="21%">
        <el-form>
          <el-form-item label="楼栋名称 : " :label-width="formLabelWidth">
            <el-input autocomplete="off" v-model.trim="addBuildings.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="btnBuildings"><i class="el-icon-document" />保存</el-button>
        </div>
        </el-dialog>
        </div>
        <!-- 添加单元弹框 -->
        <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogUnits" width="21%">
        <el-form>
          <el-form-item label="单元名称 : " :label-width="formLabelWidth">
            <el-input autocomplete="off" v-model.trim="adddialogUnits.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="btndialogUnits"><i class="el-icon-document" />保存</el-button>
        </div>
        </el-dialog>
        </div>
        <!-- 添加房间弹框 -->
        <div class="locationAdd">
        <el-dialog title="信息编辑" :visible.sync="dialogrooms" width="21%">
        <el-form>
          <el-form-item label="房间名称 : " :label-width="formLabelWidth">
            <el-input autocomplete="off" v-model.trim="adddialogrooms.name"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" @click="btndialogrooms"><i class="el-icon-document" />保存</el-button>
        </div>
        </el-dialog>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  inject: ['reload'],
  data () {
    return {
      formLabelWidth: '90px',
      // 解绑
      userFile: {
        name: null,
        project_id: null,
        token: null,
        address: null,
        phone: null,
        location_id: null,
        meter_id: null,
        Buildings_id: null,
        room_id: null
      },
      value: null,
      // 下拉框
      // 获取小区
      dialogQuarters: false,
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      dialogBuildings: false,
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      dialogUnits: false,
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      dialogrooms: false,
      rooms: [{
        id: null,
        name: null
      }],
      // 添加小区
      addition: {
        token: null,
        name: null
      },
      // 添加楼栋
      addBuildings: {
        token: null,
        community_id: null,
        name: null
      },
      // 添加单元
      adddialogUnits: {
        token: null,
        building_id: null,
        name: null
      },
      // 添加房间
      adddialogrooms: {
        token: null,
        unit_id: null,
        name: null
      },
      // table数据
      tableData: [{
        project_id: null,
        not_bind: null,
        meter_id: null,
        water_type: null,
        is_use: null
      }],
      // tab数据加载转圈
      loading: false,
      famlis: {
        project_id: null,
        token: null
      },
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      // tab
      tabfams: {
        project_id: null,
        not_bind: 1
      },
      basidata: {}
    }
  },
  created () {
    this.userFile.project_id = this.$store.state.print.goodsForeNo.project_id
    this.userFile.token = this.$store.state.print.goodsForeNo.token
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabfams.project_id = this.$store.state.print.goodsForeNo.project_id
    this.tabfams.token = this.$store.state.print.goodsForeNo.token
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
    this.userDate()
  },
  methods: {
    // 搜索
    sosuobtn () {
      this.$api.userProfile.userTabs(this.tabfams).then((res) => {
        console.log(res, '搜索')
        this.userDate()
      })
    },
    userAss () {
      this.$router.push('/userProfile')
    },
    // 绑定
    userband (row) {
      console.log(row.meter_id)
      this.userFile.meter_id = row.meter_id
    },
    // table
    userDate () {
      this.$api.userProfile.userTabs(this.tabfams).then((res) => {
        console.log(res, '右侧菜单栏')
        this.tableData = res.data.data
      })
    },
    // 添加
    fimaBtn () {
      this.$api.userProfile.userMfty(this.userFile).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '添加')
          this.basidata = res.data.data
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.userDate()
          this.reload()
          this.$router.push({ path: '/userProfile', query: { project_id: this.project_id, obj: this.basidata } })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.userFile.Buildings_id = null
      this.userFile.room_id = null
      this.userFile.units_id = null
      this.Building()
      this.room()
      this.unit()
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.userFile.room_id = null
      this.userFile.units_id = null
      this.unit()
      this.room()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.userFile.room_id = null
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.rooms = res.data.data
      })
    },
    // 添加小区按钮
    btnquars () {
      console.log(123, '2')
      this.dialogQuarters = true
    },
    // 添加小区保存按钮
    btnQuarters () {
      this.addition.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.putcommunities(this.addition).then((res) => {
        console.log(res, '添加小区')
        if (res.data.status === 'success') {
          this.residential()
          this.$notify({
            title: '成功',
            message: '添加小区成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        this.dialogQuarters = false
        this.addition.name = null
      })
    },
    // 添加楼栋按钮
    btnBuils () {
      this.dialogBuildings = true
      console.log('楼栋添加')
    },
    // 添加楼栋保存按钮
    btnBuildings () {
      this.addBuildings.token = this.$store.state.print.goodsForeNo.token
      this.addBuildings.community_id = this.munity
      this.$api.userProfile.putbuildings(this.addBuildings).then((res) => {
        console.log(res, '添加楼栋')
        if (res.data.status === 'success') {
          this.Building()
          this.$notify({
            title: '成功',
            message: '添加楼栋成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        this.dialogBuildings = false
        this.addBuildings.name = null
      })
    },
    // 添加单元按钮
    btnunits () {
      this.dialogUnits = true
    },
    // 添加单元保存按钮
    btndialogUnits () {
      this.adddialogUnits.token = this.$store.state.print.goodsForeNo.token
      this.adddialogUnits.building_id = this.unis
      this.$api.userProfile.putunits(this.adddialogUnits).then((res) => {
        console.log(res, '添加单元')
        if (res.data.status === 'success') {
          this.unit()
          this.$notify({
            title: '成功',
            message: '添加单元成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        this.dialogUnits = false
        this.adddialogUnits.name = null
      })
    },
    // 添加房间按钮
    btnrooms () {
      this.dialogrooms = true
    },
    // 添加房间保存按钮
    btndialogrooms () {
      this.adddialogrooms.token = this.$store.state.print.goodsForeNo.token
      this.adddialogrooms.unit_id = this.rom
      this.$api.userProfile.putrooms(this.adddialogrooms).then((res) => {
        console.log(res, '添加房间')
        if (res.data.status === 'success') {
          this.room()
          this.$notify({
            title: '成功',
            message: '添加楼房间成功',
            type: 'success'
          })
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        this.dialogrooms = false
        this.adddialogrooms.name = null
      })
    },
    // tab组件
    handleCurrentChange (val) {
    }
  }
}
</script>

<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 主题区域
.num{
    display: flex;
}
// 解绑水表
.numa{
    margin: 37px 0px 0px 73px;
    width: 701px;
    height: 500px;
    // border-right: 1px solid;
    .numj{
        // width: 133px;
        // height: 30px;
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
        display: flex;
        align-items: center;
        .vi{
        width: 6px;
        height: 23px;
        background-color: #744dfe;
        margin-left: 15px;
        }
    }
    .numx{
        margin-top: 23px;
        width: 701px;
        height: 2px;
        background-color: #dad2f4;
    }
    .numf{
        margin-top: 34px;
        .el-form{
            display: flex;
            flex-wrap: wrap;
            .el-form-item{
                .el-input{
                    width: 220px;
                }
            }
        }
        .numbtn{
                margin-left: 530px;
                .el-button{
                    border: 1px solid #744dfe;
                    i{
                        margin-right: 10px;
                        font-size: 15px;
                    }
                }
            }
            .ioxo{
              display: flex;
              .el-form-item{
                .el-select{
                    width: 180px;
                }
            }
            .el-button{
              margin-top: 4px;
              margin-left: 16px;
              background-color: #987AFF;
              height: 35px;
              color: #fff;
            }
            }
    }
}
// 绑定水表
.numb{
    margin-left: 50px;
    margin-top: 30px;
    width: 702px;
    // height: 500px;
    // border: 1px solid;
    .numj{
        // width: 133px;
        // height: 30px;
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
        display: flex;
        align-items: center;
        .vi{
        z-index: 9999;
        width: 6px;
        height: 23px;
        background-color: #744dfe;
        margin-left: 15px;
        }
        .ilo{
          cursor: pointer;
            margin-left: 450px;
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
            span{
              font-family: XinPingHeiTC-W2;
              font-weight: bold;
              font-stretch: normal;
              letter-spacing: 3px;
              color: #484848;
            }
            img{
              width: 27px;
              height: 23px;
            }
        }
    }
    .numx{
        margin-top: 17px;
        margin-bottom: 8px;
        width: 701px;
        height: 2px;
        background-color: #dad2f4;
    }
    .numbfai{
      display: flex;
      padding: 10px 0px;
      .el-input{
        width: 300px;
        border: 1px solid #744dfe;
        border-radius: 6px;
      }
      .el-button{
        margin-left: 20px;
      }
    }
    .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    .numbtn{
        float: right;
        margin-top: 27px;
        .el-button{
            i{
                margin-right: 10px;
            }
        }
    }
}
// 添加提示
.locationAdd{
  .el-dialog{
      .el-input{
        width: 240px;
      }
      .el-button{
        background-color: #fff;
        border: 1px solid #744dfe;
        color: #000;
        border-radius: 7px;
        i{
          margin-right: 10px;
        }
      }
  }
}
</style>
