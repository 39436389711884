<template>
    <div id="app">
     <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>交款退款</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>交款/退款</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
        <el-form-item>
          <addre :getname="getname" @transfer="getUser" @Building="getBuilding" @unit="getUnit" @room="getRoom" @ParData="getParData" @buildingData="getbuilding" @unitData="getunit" @roomData="getroom"></addre>
        </el-form-item>
      </el-form>
      <div class="mosbtn">
        <el-button @click="BatchpayBtn">批量缴款</el-button>
        <el-button @click="BatchrefBtn">批量退款</el-button>
      </div>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="tableData" size=mini
    highlight-current-row
    :header-cell-style = "{background:'#987AFF'}" v-loading="loading"
    @selection-change="handleSelectionChange" :row-class-name="rowStyle" height='350px'>
    <el-table-column  type="selection" width="60" />
    <el-table-column property="pay_id" width="80" label="缴费编号" />
    <el-table-column property="meter_id" label="水表编号" :show-overflow-tooltip="true" />
    <el-table-column property="username" label="用户姓名"  />
    <el-table-column property="phone" label="联系方式" />
    <el-table-column property="address" label="地址" />
    <el-table-column property="balance" label="余额"  />
    <el-table-column property="valve" label="操作" width="246" >
      <template slot-scope="scope">
        <el-button size="mini" @click="ficsati(scope.row)" v-show="scope.row.meter_id">缴款</el-button>
        <el-button size="mini" disabled v-show="!scope.row.meter_id">缴款</el-button>
        <el-button size="mini" @click="opens(scope.row)" v-show="scope.row.meter_id">退款</el-button>
        <el-button size="mini" disabled v-show="!scope.row.meter_id">退款</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="famlis.pagenum"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="famlis.pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </el-card>
  </div>
    <!-- 批量缴费弹框 -->
    <div class="locationOpenvalve">
  <el-dialog
  title="水费交款"
  :visible.sync="Batchvais"
  >
  <div class="spans"/>
  <div class="Periodic">
      <span>当前勾选: {{lengthid}} 台设备</span>
      <el-checkbox v-model="batchRtss.regional_issued">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
  <div class="Batchinput">
    <span>交&nbsp;&nbsp;&nbsp;款: </span>
    <el-input v-model.trim="batchRtss.money" placeholder="请输入金额"></el-input>
  </div>
  <div class="xuanxiang">
  <div v-for="(list,index) in payconfigEntities" :key="index" v-bind:class="{colorChange:index==dynamic}" direction="column" @click="getMoney(index)">
    <el-button>{{list.effect}}</el-button>
  </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="batchBhci"><i class="el-icon-success" />确 定</el-button>
  </span>
</el-dialog>
</div>
  <!-- 缴费弹框 -->
  <div class="locationOpenvalve">
  <el-dialog
  title="水费交款"
  :visible.sync="diaes"
  >
  <div class="spans"/>
  <div class="diaonum">
    <div class="diaonsums">
      <span>水表编号: </span>
      <span>{{ficsData.meter_id}}</span>
    </div>
    <div class="diaonsums">
      <span>用户姓名: </span>
      <span>{{ficsData.username}}</span>
    </div>
    <div class="diaonsums">
      <span>余&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;额: </span>
      <span>{{ficsData.balance}}</span>
    </div>
  </div>
  <div class="diaoninput">
      <span>备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注: </span>
      <el-input type="text" v-model="ficsData.note" size='small '/>
  </div>
  <div class="diaoinput">
    <span>交&nbsp;&nbsp;&nbsp;款: </span>
    <el-input v-model.trim="accounRtss.money" placeholder="请输入金额"></el-input>
  </div>
  <div class="xuanxiang">
  <div v-for="(list,index) in payconfigEntities" :key="index" v-bind:class="{colorChange:index==dynamic}" direction="column" @click="getMoney(index)">
    <el-button>{{list.effect}}</el-button>
  </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="ficsBhci"><i class="el-icon-success" />确 定</el-button>
  </span>
</el-dialog>
</div>
    <!-- 批量退款弹框 -->
    <div class="locationOpenvalve">
  <el-dialog
  title="水费退款"
  :visible.sync="Batchredvais"
  >
  <div class="spans"/>
  <div class="Periodic">
      <span>当前勾选: {{lengthid}} 台设备</span>
      <el-checkbox v-model="batchRefund.regional_issued">选择当前区域所有设备({{total}})条</el-checkbox>
    </div>
  <div class="Batchinput">
    <span>退&nbsp;&nbsp;&nbsp;款: </span>
    <el-input v-model.trim="batchRefund.money" placeholder="请输入金额"></el-input>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="BatchRefund"><i class="el-icon-success" />确 定</el-button>
  </span>
</el-dialog>
</div>
<!-- 退费弹框 -->
<div class="locationOpenvalve">
  <el-dialog
  title="水费退款"
  :visible.sync="diatui"
  >
  <div class="spans"/>
  <div class="diaonum">
    <div class="diaonsums">
      <span>水表编号: </span>
      <span>{{ficsnData.meter_id}}</span>
    </div>
    <div class="diaonsums">
      <span>用户姓名: </span>
      <span>{{ficsnData.username}}</span>
    </div>
    <div class="diaonsums">
      <span>余&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;额: </span>
      <span>{{ficsnData.balance}}</span>
    </div>
    <div class="diaonsums">
      <span>可退款金额: </span>
      <span>{{return_money_balance}}</span>
    </div>
  </div>
  <div class="diaoninput">
      <span>备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注: </span>
      <el-input type="text" v-model="ficsnData.note" size='small '/>
  </div>
  <div class="diaoinput">
    <span>退&nbsp;&nbsp;&nbsp;款: </span>
    <el-input v-model.trim="accounRefss.money" placeholder="请输入金额"></el-input>
  </div>
  <div class="sssccc">注：退费金额=余额-其它收费金额</div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="diuayt"><i class="el-icon-success" />确 定</el-button>
  </span>
</el-dialog>
    </div>
<!-- 打印凭据 -->
<div class="locationOpenvalve">
<el-dialog
  title="充值凭证"
  :visible.sync="dayinpingz"
  >
  <div class="spans"/>
  <div id="printMe">
  <div class="pingzs">充值凭证</div>
  <div class="dayinum">
    <div>订单编号:{{dayin.id}}</div>
    <div>收款日期:{{dayin.create_time}}</div>
  </div>
  <div class="dayininput">
    <div class="inputs">
      <span>缴费编号:{{dayin.pay_id}}</span>
      <span>设备编号:{{dayin.meter_id}}</span>
    </div>
    <div class="inputs">
      <span>用户名:{{dayin.username}}</span>
      <span>当前表码:{{dayin.current_sum_flow}}</span>
    </div>
    <div class="inputs">
      <span style="width: 100%">地址信息:{{dayin.address}}</span>
    </div>
    <div class="inputs">
      <span>充值金额:{{dayin.amount}}</span>
      <span>账户余额:{{dayin.aft_money}}</span>
    </div>
  </div>
  <div class="dayincao">操作人员:{{dayin.operate_user}}</div>
  </div>
  <span slot="footer" class="dayinbtn">
    <span class="spaois">手机可以截个图作为凭证,</span>
    <el-button class="spanbtn" type="info" size="mini" v-print="printObj">打印</el-button>
    <el-button type="info" size="mini" @click="prinqxio">取消</el-button>
  </span>
</el-dialog>
</div>
<!-- <div v-if="list.length === 0 && emptyFlag === 1">
    <el-empty description="暂无内容"></el-empty>
</div> -->
    </div>
</template>
<script>
import addre from '../../../components/address'
export default {
  components: { addre },
  inject: ['reload'],
  data () {
    return {
      payconfigEntities: [
        { effect: '业务售水' },
        { effect: '优惠售水' },
        { effect: '系统结转' }
      ],
      dynamic: -1,
      printObj: {
        id: 'printMe', // 打印区域 Dom ID
        popTitle: '',
        extraCss: 'https://www.google.com,https://www.google.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printMe { height: auto !important; } <style>' //  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      },
      ficsData: {},
      Batchredvais: false,
      Batchvais: false,
      diatui: false,
      diaes: false,
      dayinpingz: false,
      formLabelWidth: '100px',
      // 下拉框
      seldata: [],
      options: [
        {
          name: '金湖'
        }
      ],
      // tab
      tableData: [
        {
          project_name: null,
          name: null,
          project_address: null,
          username: null,
          valve: null
        }
      ],
      total: 0,
      loading: true,
      // 登录返回的项目id
      famlis: {
        project_id: null,
        token: null,
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 20
      },
      tableSize: null,
      // 批量缴费batchRtss.pay_id_list
      batchRtss: {
        token: null,
        pay_id_list: null,
        regional_issued: null,
        money: null,
        charge_type: null
      },
      // 缴费
      accounRtss: {
        token: null,
        pay_id: null,
        money: null,
        charge_type: null
      },
      // 打印
      dayin: {},
      // 批量退款
      batchRefund: {
        token: null,
        pay_id_list: null,
        money: null,
        regional_issued: null
      },
      // 退费
      ficsnData: {},
      accounRefss: {
        project_id: null,
        token: null,
        pay_id: null,
        return_money_balance: 1,
        operate_user_id: null,
        money: null
      },
      refund: {
        token: null,
        pay_id: null,
        return_money_balance: 1
      },
      zaisns: [{
        id: 1,
        name: null
      }],
      cxyts: {
        project_id: null,
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null
      },
      lengthid: null,
      emptyFlag: 0, // 0表示接口调用前，1表示接口调用后
      return_money_balance: null,
      ids: []
    }
  },
  created () {
    // console.log(this.$store.state.print.goodsForeNo.id, '当前登录的id')
    // tab
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.tabData()
    this.refund.token = this.$store.state.print.goodsForeNo.token
    // this.Wchis()
  },
  methods: {
    getMoney: function (index) {
      // 点击添加字体颜色，其他的删除class名称
      this.dynamic = index
      console.log(this.dynamic, 'console.log(dynamic)')
      this.accounRtss.charge_type = this.dynamic
      this.batchRtss.charge_type = this.dynamic
    },
    getname (name) {
      console.log('App收到了子组件传过来的数据', name)
      this.tableData = name.data
      this.total = name.total
    },
    // 位置下拉框
    getUser (data) {
      console.log('小区id：', data)
      this.batchRtss.community_id = data
      this.batchRefund.community_id = data
      this.community_id = data
    },
    getBuilding (data) {
      console.log('楼栋id：', data)
      this.batchRtss.building_id = data
      this.batchRefund.building_id = data
      this.building_id = data
    },
    getUnit (data) {
      console.log('单元子id：', data)
      this.batchRtss.unit_id = data
      this.batchRefund.unit_id = data
      this.unit_id = data
    },
    getRoom (data) {
      console.log('房间id：', data)
      this.batchRtss.room_id = data
      this.batchRefund.room_id = data
      this.room_id = data
    },
    getParData (data) {
      console.log(data, '选中单元刷新数据')
      this.tableData = data.data.data
      this.total = data.data.total
    },
    getbuilding (data) {
      console.log(data, '选中楼栋刷新数据')
      this.tableData = data.data.data
      this.total = data.data.total
    },
    getunit (data) {
      console.log(data, '选中单元刷新数据')
      this.tableData = data.data.data
      this.total = data.data.total
    },
    getroom (data) {
      console.log(data, '选中单元刷新数据')
      this.tableData = data.data.data
      this.total = data.data.total
    },
    handleSelectionChange (val) {
      this.ids = val.map(item => item.pay_id)
      console.log(this.ids, 'this.ids')
      this.lengthid = val.length
      const arr = []
      val.forEach(function (value) {
        arr.push(value.pay_id)
      })
      this.batchRtss.pay_id_list = arr
      this.batchRefund.pay_id_list = arr
    },
    rowStyle ({ row, rowIndex }) {
      var arrs = this.ids
      for (let i = 0; i < arrs.length; i++) {
        console.log(row.pay_id, arrs[i], 'row.pay_id,arrs')
        if (row.pay_id === arrs[i]) {
          return 'rowStyle'
        }
      }
      // console.log(row, rowIndex)
      // if (rowIndex === 1) {
      //   return 'warning-row'
      // } else if (rowIndex === 3) {
      //   return 'success-row'
      // }
      // return ''
    },
    prinqxio () {
      this.dayinpingz = false
    },
    // 查询
    pay_search () {
      this.cxyts.project_id = this.$store.state.print.goodsForeNo.project_id
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.Czhixios(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 批量退款
    BatchrefBtn () {
      this.Batchredvais = true
    },
    BatchRefund () {
      this.batchRefund.token = this.$store.state.print.goodsForeNo.token
      this.$api.accountMent.accounRefunds(this.batchRefund).then((res) => {
        console.log(res, '批量缴费')
        if (res.data.status === 'success') {
          this.tabData()
          // this.reload()
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.$notify({
            title: res.data.message,
            message: res.data.error_list,
            duration: 0
          })
          this.tabData()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.Batchredvais = false
    },
    BatchpayBtn () {
      this.Batchvais = true
    },
    // 批量缴费
    batchBhci () {
      this.batchRtss.token = this.$store.state.print.goodsForeNo.token
      // loading缓存中
      // this.loading.show()
      // 接口调用前标识
      this.emptyFlag = 0
      this.$api.accountMent.accounBzhits(this.batchRtss).then((res) => {
        console.log(res, '批量缴费')
        if (res.data.status === 'success') {
          // 接口调用完毕标识
          this.emptyFlag = 1
          // 关闭loading缓存
          // this.loading.hide()
          this.tabData()
          this.$message({
            type: 'success',
            message: '缴费成功!'
          })
          this.tabData()
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.Batchvais = false
    },
    // 缴费
    ficsBhci () {
      this.$confirm('是否要对' + this.ficsData.meter_id + '表进行缴费', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.accounRtss.token = this.$store.state.print.goodsForeNo.token
        this.$api.accountMent.accounBzhits(this.accounRtss).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '缴费')
            this.dayin = res.data.data
            this.tabData()
            // this.reload()
            this.$message({
              type: 'success',
              message: '缴费成功!'
            })
            this.dayinpingz = true
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          if (res.data.message === '用户在别处登录') {
            this.$router.push('/', this.fasinull)
          }
        })
        this.diaes = false
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消缴费'
        })
      })
    },
    // 单条缴费
    ficsati (row) {
      console.log(row)
      this.ficsData = row
      this.accounRtss.pay_id = row.pay_id
      this.diaes = true
    },
    // 单条退费
    diuayt () {
      this.accounRefss.token = this.$store.state.print.goodsForeNo.token
      this.accounRefss.operate_user_id = this.$store.state.print.goodsForeNo.id
      this.$api.accountMent.accounRefunds(this.accounRefss).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '退费')
          this.tabData()
          this.reload()
          this.$message({
            type: 'success',
            message: '退费成功!'
          })
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
      this.diatui = false
      this.ficsnData.note = null
      this.accounRefss.money = null
    },
    // 退费
    opens (row) {
      console.log(row)
      this.ficsnData = row
      this.accounRefss.pay_id = row.pay_id
      this.refund.pay_id = row.pay_id
      this.diatui = true
      this.$api.accountMent.getuserRefund(this.refund).then((res) => {
        console.log(res.data.data.return_money_balance, '获取可退款金额')
        this.return_money_balance = res.data.data.return_money_balance
      })
    },
    // 获取可退款金额接口
    // table数据
    tabData () {
      this.$api.accountMent.accounDatas(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'res')
          this.tableData = res.data.data
          this.loading = false
          this.total = res.data.total
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 监听 pagesize 改变的事件
    handleSizeChange (newSize) {
      console.log(newSize, 'q')
      this.famlis.pagesize = newSize
      this.tabData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange (newPage) {
      console.log(newPage, '123')
      this.famlis.pagenum = newPage
      this.famlis.search_content = this.cxyts.search_content
      this.famlis.location_id = this.cxyts.location_id
      this.famlis.community_id = this.community_id
      this.famlis.building_id = this.building_id
      this.famlis.unit_id = this.unit_id
      this.famlis.room_id = this.room_id
      this.tabData()
    }
  }
}
</script>
<style lang="less" scoped>
 .colorChange {  .el-button{
     background-color: #987AFF;
     color: #fff;
  }
 }
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 搜索
.suso {
  padding: 0px 0px 0px 74px;
  .el-form {
    display: flex;
    .el-input {
      border-radius: 6px;
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    .sele {
      width: 100px;
      margin-left: 50px;
    }
  }
  .mosbtn .el-button{
        background-color: #744dfe;
        color: #fff;
    }
}
// <!-- table表格 -->
    .tab{
        padding: 20px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
// 添加提示
// .locationAdd{
//   .el-dialog{
//       .el-input{
//         width: 240px;
//       }
//       .el-button{
//         background-color: #fff;
//         border: 1px solid #744dfe;
//         color: #000;
//         border-radius: 7px;
//         i{
//           margin-right: 10px;
//         }
//       }
//   }
// }

// 缴费提示
  .el-dialog{
    width: 30%;
    .el-dialog__header{
      span.dialog__title{
        font-family: SimHei;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 9px;
        letter-spacing: 5px;
        color: #030000;
    }
    }
    .spans {
        width: 3px;
        height: 15px;
        display: inline-block;
        background: #977AFF;
        margin-right: 20px;
        position: absolute;
        top: 23px;
        left: 100px;
      }
      .diaonum{
        margin-left: 100px;
        // width: 324px;
        // height: 123px;
        font-family: XinPingHeiTC-W2;
        font-size: 19px;
        // font-weight: bold;
        font-stretch: normal;
        line-height: 45px;
        letter-spacing: 4px;
        color: #484848;
        // border: 1px solid;
        .diaonsums{
          font-family: XinPingHeiTC-W2;
          font-size: 19px;
          // font-weight: bold;
          letter-spacing: 10px;
          color: #484848;
          span{
            font-family: XinPingHeiTC-W2;
            font-size: 19px;
            // font-weight: bold;
            letter-spacing: 4px;
            color: #484848;
          }
        }
      }
      .diaoninput{
         margin-left: 100px;
        display: flex;
        align-items: center;
        span{
            font-family: XinPingHeiTC-W2;
            font-size: 19px;
            // font-weight: bold;
            letter-spacing: 4px;
            color: #484848;
          }
      }
      .diaoinput{
        margin: 20px 0px 0px 100px;
        display: flex;
        align-items: center;
        span{
          font-family: BENMOJingyuan;
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 1px;
          color: #744dfe;
        }
        .el-input{
          width: 100px;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
      .sssccc{
        margin: 20px 0px 0px 100px;
        display: flex;
        align-items: center;
        color: brown;
      }
      .dialog-footer{
        .el-button{
          font-size: 20px;
          i{
            margin-right: 10px;
            font-size: 24px;
          }
        }
      }
  }
  .xuanxiang{
    margin-top: 20px;
    display: flex;
  }
  // 充值打印
  #printMe{
    border: 1px solid;
    padding: 10px 0px;
  .pingzs{
    width: 90%;
    height: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dayinum{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dayininput{
    width: 96%;
    height: 130px;
    border: 1px solid;
    line-height: 32px;
    margin: 10px auto;
  }
  .inputs{
    padding: 0px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    span{
      width: 50%;
    }
  }
  .dayincao{
    margin-left: 400px;
    width: 200px;
  }
  .dayinbtn{
    button{
      font-size: 14px;
    }
  }
  }

.locationOpenvalve{
  .el-dialog__wrapper{
  ::v-deep .el-dialog{
    width: 30%;
  }
}
  .Periodic{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .Batchinput{
      margin-top: 20px;
      display: flex;
        align-items: center;
        span{
          font-family: BENMOJingyuan;
          font-size: 30px;
          font-weight: normal;
          letter-spacing: 1px;
          color: #744dfe;
        }
        .el-input{
          width: 100px;
          margin-left: 10px;
          margin-top: 5px;
        }
    }
    }
    /deep/.rowStyle{
      background-color: oldlace !important;
    }
    // 移动端
    @media (min-width: 768px) {
      .spaois{
        display: none;
      }
    }
    @media (max-width: 768px) {
      .spaois{
      font-size: 12px;
      color: brown;
    }
    .spanbtn{
      display: none;
    }
      .Gnv{
        display: none;
      }
      .suso{
        padding: 10px;
        .el-form{
          .el-form-item{
            margin-bottom: 10px;
          }
        }
      }
      .tab{
        padding: 10px;
      }
      .feye{
    width: 91%;
    .el-pagination{
      width: 90%;
    // border: 1px solid #307027;
    display: flex;
    justify-content: space-between;
    .el-pagination__jump{
      display: none;
    }
    }
    }
    .mosbtn{
      padding: 0px 10px;
      .el-button{
        background-color: #744dfe;
        color: #fff;
      }
    }
    .el-dialog{
      width: 92%;
    }
.locationOpenvalve{
  .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 92%;
    }
  }
}
::v-deep .el-dialog__body{
  padding: 10px 10px;
}
.el-dialog .diaonum{
  margin-left: 6px;
  }
  .el-dialog .diaoninput{
  margin-left: 6px;
  }
  .el-dialog .diaoinput{
  margin-left: 6px;
  }
  .el-dialog .sssccc{
    margin: 20px 0px;
  }
    }
</style>
