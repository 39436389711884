<template>
  <div class="T">
    <!-- 搜索 -->
      <el-form>
        <el-form-item>
          <el-input placeholder="请搜索" v-model.trim="cxyts.search_content" size="small" @change="SelectinputBtn(cxyts.search_content)"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="time"
            size="small"
            @change="DateSelection(time)"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="小区:">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
            @change="selectroom(cxyts.room_id)">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="pay_search">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="shuaxin">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      time: [],
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      zaisns: [{
        id: 1,
        name: null
      }],
      cxyts: {
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null,
        insufficient_balance: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        start_time: null,
        end_time: null
      },
      parData: [],
      tableData: []
    }
  },
  created () {
    // 下拉框
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
  },
  props: ['getname'],
  methods: {
    DateSelection (val) {
      console.log(val)
      this.$emit('start', val[0])
      this.$emit('end', val[1])
    },
    SelectinputBtn (val) {
      console.log(val)
      this.$emit('getValue', val)
    },
    // 查询
    pay_search () {
      console.log(this.time, 'time')
      this.cxyts.start_time = this.time[0]
      this.cxyts.end_time = this.time[1]
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
        this.tableData = res.data
        console.log(this.tableData, '查询')
        // this.tableData = res.data.data
        // this.total = res.data.total
        this.getname(this.tableData)
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.$emit('transfer', this.munity)
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('ParData', this.parData)
      })
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.$emit('Building', this.unis)
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('buildingData', this.parData)
      })
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.$emit('unit', this.rom)
      this.cxyts.room_id = null
      this.room()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('unitData', this.parData)
      })
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
      this.$emit('room', val)
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.finaciStates.detailsDates(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('roomData', this.parData)
      })
    }
  }
}

</script>

<style lang="less" scoped>

// 搜索
    .el-form{
      display: flex;
      flex-wrap: wrap;
      .el-input {
      border-radius: 6px;
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
    }
  .el-form-item{
      display: flex;
      margin-top: 20px;
    .el-select{
      width: 150px;
    }
  }
  .el-form-item{
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    .T{
      .el-form{
        .el-form-item{
          margin-top: 4px;
          margin-bottom: 4px;
          .el-form-item__content{
            .el-range-editor--small.el-input__inner{
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
