<template>
  <div>
      <!-- 面包屑 -->
    <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>位置管理</el-breadcrumb-item>
        <el-breadcrumb-item>房间信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 搜索 -->
    <div class="suso">
      <el-form>
          <el-form-item label="选择小区:">
          <el-select v-model="comities.community_id" clearable placeholder="请选择小区" size="small"
          @change="selectQuarters(comities.community_id)">
            <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="楼栋:" style="margin-left: 30px">
            <el-select v-model="buings.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(buings.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:" style="margin-left: 30px">
            <el-select v-model="unistfs.unit_id" placeholder="请选择楼栋" size="small"
            @change="selectunit(unistfs.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <div class="commbtn">
        <el-button @click="commvisble">添加房间</el-button>
      </div>
      </el-form>
    </div>
    <!-- table表格 -->
    <div class="tab">
      <el-card>
    <el-table :data="rooms" size=mini highlight-current-row :header-cell-style = "{background:'#987AFF'}" height='500'>
    <el-table-column type="index" width="40" />
    <el-table-column property="unit_name" label="单元名称" :show-overflow-tooltip="true" />
    <el-table-column property="name" label="房间名称" :show-overflow-tooltip="true" />
    <el-table-column property="valve" label="操作" >
      <template slot-scope="scope">
        <el-button size="mini" @click="ficati(scope.row)">修改</el-button>
        <el-button size="mini" @click="opens(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
      </el-card>
    </div>
   <!-- 添加弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息编辑" :visible.sync="dialogFormVisible" width="20%">
  <el-form :model="Locatw">
    <el-form-item label="房间名称 : " :label-width="formLabelWidth">
      <el-input v-model="Locatw.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Locationt"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
</div>
<!-- 修改弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="commfication" width="20%">
  <el-form :model="modify">
     <el-form-item label="选择小区:">
          <el-select v-model="modify.community_id" clearable placeholder="请选择小区"
          @change="modifyselectQuarters(modify.community_id)">
            <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="楼栋名称:">
            <el-select v-model="modify.building_id" placeholder="请选择楼栋"
            @change="modifyselectBuildings(modify.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元名称:">
            <el-select v-model="modify.unit_id" placeholder="请选择楼栋"
            @change="modifyselectunit(modify.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
    <el-form-item label="房间名称: ">
      <el-input v-model="modify.name" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="Locaficat"><i class="el-icon-document" />保存</el-button>
  </div>
</el-dialog>
</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      formLabelWidth: '100px',
      dialogFormVisible: false,
      commfication: false,
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      munity: null,
      //   获取小区参数
      comities: {
        token: null,
        community_id: null
      },
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      rooms: [],
      roomfs: {
        token: null,
        unit_id: null
      },
      // 添加
      Locatw: {
        token: null,
        name: null,
        unit_id: null
      },
      // 修改
      modify: {
        token: null,
        unit_id: null,
        name: null
      },
      // 删除
      deleteloca: {
        token: null,
        room_id: null
      }
    }
  },
  created () {
    this.residential()
    this.Building()
    this.unit()
    this.room()
  },
  methods: {
    // 添加
    commvisble () {
      this.dialogFormVisible = true
    },
    // 添加保存
    Locationt () {
      this.Locatw.token = this.$store.state.print.goodsForeNo.token
      this.Locatw.unit_id = this.rom
      this.$api.Location.putrooms(this.Locatw).then((res) => {
        console.log(res, '添加')
        if (res.data.status === 'success') {
          this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
          })
          this.room()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.dialogFormVisible = false
      this.Locatw.name = null
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.buings.building_id = null
      this.units = null
      this.unistfs.unit_id = null
      this.Building()
      this.room()
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.unistfs.unit_id = null
      this.unit()
      this.room()
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.room()
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.rooms = res.data.data
      })
    },
    // 修改
    ficati (row) {
      console.log(row, 'row')
      this.modify.unit_id = row.unit_id
      this.modify.room_id = row.id
      this.commfication = true
    },
    // 修改小区
    modifyselectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.Building()
    },
    // 修改楼栋
    modifyselectBuildings (val) {
      console.log(val, 'val')
      this.unis = val
      this.unit()
    },
    // 单元
    modifyselectunit (val) {
      console.log(val, 'val')
      this.modify.unit_id = val
    },
    // 修改保存
    Locaficat () {
      this.modify.token = this.$store.state.print.goodsForeNo.token
      // this.modify.building_id = this.ooo
      this.$api.Location.postrooms(this.modify).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '修改')
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          })
          this.room()
        } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
      })
      this.commfication = false
      this.modify.name = null
    },
    // 删除
    opens (row) {
      console.log(row, '删除')
      this.deleteloca.token = this.$store.state.print.goodsForeNo.token
      this.deleteloca.room_id = row.id
      this.$confirm('此操作将永久删除该位置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.Location.deleterooms(this.deleteloca).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '删除')
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
            this.room()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
/* // 面包屑 */
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// <!-- table表格 -->
    .tab{
        padding: 0px 30px 0px 74px;
        // 分页
        .el-pagination{
          ::v-deep .el-pagination__rightwrapper{
            float: left;
            margin-left: 20px;
          }
        }
        .el-card{
          border-radius: 10px;
          ::v-deep .el-card__body{
            padding: 0px;
          }
          ::v-deep .el-table__header-wrapper{
            background-color: #987AFF;
              width: 100%;
          }
          ::v-deep .el-table tr{
            background-color: #F9F9F9;
          }
          ::v-deep .el-table thead{
            color: #fff;
            font-weight: 300;
          }
         ::v-deep .cell{
           font-family:myFont;
          }
        }
        ::v-deep .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          text-align: center;
        }
       ::v-deep .el-table__header{
         width: 0;
          width: 100%;
        }
        .el-button{
          border: 1px solid #744dfe;
        }
    }
    // 搜索
.suso {
  padding: 20px 0px 0px 74px;
  display: flex;
  .el-form {
    display: flex;
    .el-form-item{
        display: flex;
    }
    .el-input {
      width: 300px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
    }
  }
}
// 添加按钮
.commbtn{
    padding: 0px 0px 0px 74px;
    .el-button {
      background-color: #744dfe;
      border: 0px;
      color: #fff;
    }
}
.locationAdd{
  .el-form{
    .el-form-item{
      .el-input{
        width: 200px;
      }
    }
  }
}
</style>>
