<template>
  <div class="T">
    <!-- 搜索 -->
      <el-form>
        <el-form-item>
          <el-input placeholder="请搜索" v-model.trim="cxyts.search_content" size="small"></el-input>
          <el-button class="formBtn" type="primary" @click="pay_search">查询</el-button>
          <el-button class="formBtn" type="primary" @click="shuaxin">重选</el-button>
        </el-form-item>
        <el-form-item>
          <span>用水类型:</span>&nbsp;&nbsp;
              <el-select placeholder="请选择用水类型" @change="WaterusedBtn(cxyts.water_type_id)" v-model="cxyts.water_type_id" size="small">
                <el-option
                  v-for="item in withdown"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item>
          <span>费用状态:</span>&nbsp;&nbsp;
              <el-select placeholder="请选择是否欠费" @change="ArrearsBtn(cxyts.owing)" v-model="cxyts.owing" size="small">
                <el-option
                  v-for="item in zaisns"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="小区:">
            <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
            @change="selectQuarters(cxyts.community_id)">
              <el-option
                v-for="item in quarters"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="楼栋:">
            <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
            @change="selectBuildings(cxyts.building_id)">
              <el-option
                v-for="item in Buildings"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="单元:">
            <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
            @change="selectunit(cxyts.unit_id)">
              <el-option
                v-for="item in units"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="房间:">
            <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
            @change="selectroom(cxyts.room_id)">
              <el-option
                v-for="item in roomsfas"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
          <el-form-item style="margin-left: 0px;">
          <el-button class="elfomobtn" type="primary" @click="pay_search">查 询</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 0px;">
          <el-button class="elfomobtn" type="primary" @click="shuaxin">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      famlis: {
        token: null
      },
      // 费用下拉框
      zaisns: [{
        id: 0,
        name: '不欠费'
      },
      {
        id: 1,
        name: '欠费'
      }],
      // 用水类型下拉框
      withdown: [{
        id: null,
        name: null
      }],
      // 下拉框
      // 获取小区
      quarters: [{
        id: null,
        name: null
      }],
      // 获取楼栋
      Buildings: [{
        id: null,
        name: null
      }],
      // 获取单元
      units: [{
        id: null,
        name: null
      }],
      // 获取房间
      roomsfas: [{
        id: null,
        name: null
      }],
      // 小区参数
      comities: {
        token: null
      },
      munity: null,
      // 楼栋参数
      buings: {
        token: null,
        community_id: null
      },
      unis: null,
      // 单元参数
      unistfs: {
        token: null,
        building_id: null
      },
      rom: null,
      // 房间参数
      roomfs: {
        token: null,
        unit_id: null
      },
      cxyts: {
        project_id: null,
        token: null,
        pay_id: null,
        search_content: null,
        location_id: null,
        insufficient_balance: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null
      },
      parData: [],
      tableData: []
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    // 下拉框
    // 用水类型
    this.waterdown()
    // 获取小区
    this.residential()
    // 获取楼栋
    this.Building()
    // 单元
    this.unit()
    // 房间
    this.room()
  },
  props: ['getname'],
  methods: {
    // 添加的用水类型下拉框
    waterdown () {
      this.$api.waterMeter.waterdowns(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '用水类型')
          this.withdown = res.data.data
        }
      })
    },
    // 查询
    pay_search () {
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.Czhixios(this.cxyts).then((res) => {
        this.tableData = res.data
        console.log(this.tableData, '查询')
        // this.tableData = res.data.data
        // this.total = res.data.total
        this.getname(this.tableData)
      })
    },
    // 刷新
    shuaxin () {
      this.reload()
    },
    // 欠费
    ArrearsBtn (val) {
      console.log(val)
      this.$emit('owing', val)
    },
    // 用水类型
    WaterusedBtn (val) {
      console.log(val)
      this.$emit('water_type_id', val)
    },
    // 获取小区
    residential () {
      this.comities.token = this.$store.state.print.goodsForeNo.token
      this.$api.userProfile.communities(this.comities).then((res) => {
        console.log(res.data.data, '获取小区')
        this.quarters = res.data.data
      })
    },
    selectQuarters (val) {
      console.log(val, 'val')
      this.munity = val
      this.$emit('transfer', this.munity)
      this.cxyts.building_id = null
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.Building()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('ParData', this.parData)
      })
    },
    // 获取楼栋
    Building () {
      this.buings.token = this.$store.state.print.goodsForeNo.token
      this.buings.community_id = this.munity
      this.$api.userProfile.buildings(this.buings).then((res) => {
        console.log(res.data.data, '获取楼栋')
        this.Buildings = res.data.data
      })
    },
    selectBuildings (val) {
      console.log(val, 'val2')
      this.unis = val
      this.$emit('Building', this.unis)
      this.cxyts.unit_id = null
      this.cxyts.room_id = null
      this.unit()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('buildingData', this.parData)
      })
    },
    // 单元
    unit () {
      this.unistfs.token = this.$store.state.print.goodsForeNo.token
      this.unistfs.building_id = this.unis
      this.$api.userProfile.units(this.unistfs).then((res) => {
        console.log(res.data.data, '获取单元')
        this.units = res.data.data
      })
    },
    selectunit (val) {
      console.log(val, 'val3')
      this.rom = val
      this.$emit('unit', this.rom)
      this.cxyts.room_id = null
      this.room()
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('unitData', this.parData)
      })
    },
    // 房间
    room () {
      this.roomfs.token = this.$store.state.print.goodsForeNo.token
      this.roomfs.unit_id = this.rom
      this.$api.userProfile.rooms(this.roomfs).then((res) => {
        console.log(res.data.data, '获取房间')
        this.roomsfas = res.data.data
      })
    },
    selectroom (val) {
      console.log(val, '房间id')
      this.$emit('room', val)
      this.cxyts.token = this.$store.state.print.goodsForeNo.token
      this.$api.waterMeter.getmeterTable(this.cxyts).then((res) => {
        console.log(res, '查询')
        this.parData = res
        this.$emit('roomData', this.parData)
      })
    }
  }
}

</script>

<style lang="less" scoped>

// 搜索
    .el-form{
      display: flex;
      flex-wrap: wrap;
      .el-input {
      border-radius: 6px;
      width: 200px;
    }
    .el-button {
      margin-left: 10px;
      background-color: #744dfe;
      border: 0px;
      color: aliceblue;
    }
    }
  .el-form-item{
      display: flex;
      margin-top: 20px;
    .el-select{
      width: 150px;
    }
  }
      // 移动端
  @media (min-width: 768px) {
    .formBtn{
      display: none;
    }
    }
      @media (max-width: 768px) {
        .T .el-form .el-form-item{
          margin-top: 0px;
        }
        .elfomobtn{
          display: none;
        }
      }
</style>
