<template>
    <div class="ext"><div id="main" :style="{width: '100%', height: '240px'}"></div></div>
</template>
<script>
export default {
  // props: ['pieData'],
  data () {
    return {
      famlis: {
        token: null
      },
      ipos: {},
      pieData: []
    }
  },
  created () {
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.bansData()
  },
  mounted () {
    // console.log(this)
    this.drawLine()
  },
  watch: {
    pieData: {
      handler () {
        this.drawLine()
      },
      deep: true
    }
  },
  methods: {
    bansData () {
      this.$api.users.paymentProportion(this.famlis).then((res) => {
        console.log(res, '饼图')
        // this.ipos = res.data
        // console.log(this.ipos, 'ipos')
        this.pieData = res.data.pie_data
        console.log(this.pieData, '饼图pieData')
      })
    },
    drawLine () {
      const main = this.$echarts.init(document.getElementById('main'))
      main.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '0%',
          left: 'center'
        },
        color: ['#5470c6', '#91cc75', '#fac858', '#ea7ccc', '#73c0de', '#3ba272', '#fc8452', '#9a60b4'],
        series: [
          {
            name: '本月',
            type: 'pie',
            radius: ['40%', '80%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            center: ['50%', '55%'],
            data: this.pieData
          }
        ]
      })
    }
  }
}
</script>
<style scoped>
.ext{
  width: 100%;
  height: 240px;
}
</style>
