<template>
    <div class="ext"><div id="myChart" :style="{width: '100%', height: '245px',}"></div></div>
</template>
<script>
export default {
  props: ['hDataArrs'],
  data () {
    return {
      xDataArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      yDataArr: []
    }
  },
  mounted () {
    this.drawLine()
  },
  created () {
    console.log(this.hDataArrs, '这是按日份的数据')
    this.yDataArr = this.hDataArrs
  },
  methods: {
    drawLine () {
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      //   const colors = ['#744DFE', '#744DFE', '#744DFE', '#744DFE']
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis',
          //   triggerOn: 'click',
          //   formatter: '{b}月的数据是{c}'
          formatter: function (arg) {
            // console.log(arg)
            return arg[0].name + '号的数据是：' + arg[0].data
          }
        },
        grid: {
          top: '10%',
          right: '5%',
          bottom: '15%'
        },
        xAxis: {
          data: this.xDataArr
        },
        yAxis: {
          type: 'value',
          axisLine: {
            // lineStyle: {
            //   color: colors[0]
            // }
          }
        },
        series: [{
          name: '用水',
          type: 'bar',
          data: this.yDataArr,
          itemStyle: {
            barWidth: 10,
            color: '#744DFE',
            barBorderRadius: 20
          },
          barWidth: 10
        }]
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
