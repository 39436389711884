<template>
<div>
  <!-- 面包屑 -->
  <div class="Gnv">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <div class="yai"></div>
        <el-breadcrumb-item :to="{ path: '/USERS' }">项目首页</el-breadcrumb-item>
        <el-breadcrumb-item>基础资料</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/waterMeterFile' }">水表档案</el-breadcrumb-item>
        <el-breadcrumb-item>档案详情</el-breadcrumb-item>
      </el-breadcrumb>
  </div>

  <!-- 主体外层盒子 -->
  <div class="flima">
    <!-- 左边 -->
    <div class="fam1">
      <!-- 档案详情文字 -->
      <div class="famtext">
        <div class="famtexts">
          <span>档案详情</span>
          <span class="fuiow" @click="userAss">返回</span>
        </div>
        <div class="famtextss">
          <span>水表编号</span>
          <div class="famdi"><span>{{meterBata.meter_id}}</span></div>
        </div>
      </div>
      <!-- 详情信息 -->
      <div class="famation">
        <div class="ation-1">详情信息</div>
        <!-- 内容 -->
        <div class="ation-2">
          <!-- 用水类型div -->
          <div class="ations">
            <div class="ationsetms">
              <span>用水类型:</span>&nbsp;
              <div class="ations-sele">
                <el-select v-model="meterBata.water_type_id" placeholder="请选择" size="mini">
                  <el-option
                    v-for="item in withdown"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="ationsetms">
              <span>累计流量:</span>&nbsp;
              <span style="color: #987AFF">{{meterBata.sumFlow}}</span>
              <span>m<sup>3</sup></span>
              <div style="margin-left: 30px">
              <span>余额:</span>&nbsp;
              <span style="color: #987AFF">{{meterBata.balance}}</span>&nbsp;
              <span>元</span>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>在线状态:</span>&nbsp;
                <span>{{meterBata.online}}</span>
              </div>
              <div style="margin-left: 30px">
                <span>阀门状态:</span>&nbsp;&nbsp;
                <span>{{meterBata.valveStatus}}</span>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>表具类型:</span>&nbsp;
                <span>{{meterBata.meter_type}}</span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <!-- <div>
                <span>型号:</span>&nbsp;
                <span>{{meterBata.meter_type}}</span>
              </div> -->
            </div>
            <div class="ationsetms">
              <span>水表状态:</span>&nbsp;
              <div class="ations-sele">
                <el-select v-model="meterBata.is_use" placeholder="请选择" size="mini">
                  <el-option
                    v-for="item in optionst"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>净累积流量:</span>&nbsp;
                <span>{{meterBata.netAccumulation}}</span>
                <span>m<sup>3</sup></span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <span>反向累积流量:</span>&nbsp;
                <span>{{meterBata.reverseAccumulation}}</span>
                <span>m<sup>3</sup></span>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>瞬时流量:</span>&nbsp;
                <span>{{meterBata.atAccumulation}}</span>
                <span>m<sup>3</sup></span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <span>电池电压:</span>&nbsp;
                <span>{{meterBata.batteryVoltage}}</span>
                <span>V</span>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>信号强度:</span>&nbsp;
                <span>{{meterBata.signallntensity}}</span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <span>掉电状态:</span>&nbsp;
                <span>{{meterBata.powerDown}}</span>
              </div>
            </div>
            <div class="ationsetms">
              <div>
                <span>低压状态:</span>&nbsp;
                <span>{{meterBata.powerLow}}</span>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div>
                <span>磁攻击:</span>&nbsp;
                <span>{{meterBata.magneticAttack}}</span>
              </div>
            </div>
          </div>
          <!-- 线 -->
          <div class="ationx"/>
          <!-- 注 -->
          <div class="ationxz">
            <span>注：水表状态启用指水表正常使用，开始扣费。停用指水表正常使用，停止扣费。</span>
          </div>
          <!-- 保存 -->
          <div class="ationxb">
            <el-button size="mini" @click="sumCcit()"><i class="el-icon-delete" />删 除</el-button>
            <el-button size="mini" @click="numBcit()"><i class="el-icon-document" />保 存</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 右边 -->
    <div class="fam2">
      <div class="waiido">
      <div class="ilo" @click="userAss">
        <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
          <span>返回</span>
      </div>
      <!-- <div class="ilos" @click="yserAss">
        <img src="@/views/basicData/userProfile/TableRecord/img/fanhui.png" alt="">
          <span>用户档案</span>
      </div> -->
      </div>
      <div class="famont">
        <!-- 点击切换 -->
        <div class="fambtn">
          <span style="cursor:pointer; color: #987AFF" @click="Billing()" ref="cecords">账单流水</span>
          <span style="margin-left: 20px; cursor:pointer;" @click="ers()" ref="meting">抄表记录</span>
          <span style="margin-left: 20px; cursor:pointer" @click="tch()" ref="offvale">开关阀记录</span>
        </div>
        <!-- table数据 -->
        <div class="tab">
          <Switchs v-show="tabtch"/>
          <Meters v-show="tabers"/>
          <records v-show="secords"/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Switchs from '@/views/basicData/waterMeterFile/switch.vue'
import Meters from '@/views/basicData/waterMeterFile/meter.vue'
import records from '@/views/basicData/waterMeterFile/records.vue'
export default {
  components: { Switchs, Meters, records },
  data () {
    return {
      // 抄表
      tabers: false,
      // 开关阀
      tabtch: false,
      secords: true,
      // 用水类型下拉框
      withdown: [{
        id: null,
        name: null
      }],
      // 水表状态下拉框
      optionst: [{
        id: 0,
        name: '停用'
      }, {
        id: 1,
        name: '启用'
      }],
      value: '',
      // faieDatanam: null,
      famlis: {
        project_id: null,
        token: null
      },
      // 保存
      meterBata: {
        project_id: null,
        meter_id: null,
        token: null,
        is_use: null,
        enable_trace: null,
        balance: null,
        water_type_id: null
      },
      // 删除
      Locadelet: {
        project_id: null,
        meter_id: null,
        token: null
      },
      meterMaid: null
    }
  },
  created () {
    this.famlis.project_id = this.$store.state.print.goodsForeNo.project_id
    this.famlis.token = this.$store.state.print.goodsForeNo.token
    this.meterBata = this.$route.query.obj
    this.meterMaid = this.$route.query.obj.meter_id
    console.log(this.meterBata, 'this.$route.query.obj')
    this.waterdown()
  },
  methods: {
    userAss () {
      this.$router.push('/waterMeterFile')
    },
    yserAss () {
      this.$router.push('/userProfile')
    },
    // 删除
    sumCcit () {
      this.Locadelet.project_id = this.$store.state.print.goodsForeNo.project_id
      this.Locadelet.token = this.$store.state.print.goodsForeNo.token
      this.Locadelet.meter_id = this.$route.query.obj.meter_id
      this.$confirm(`当前水表余额为 ${this.meterBata.balance} 是否删除水表档案？(请自行决定是否在删除水表档案前平账)`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.position.SodeCets(this.Locadelet).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            console.log(res)
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            })
            this.$router.push('/waterMeterFile')
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
            this.$router.push('/', this.fasinull)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 保存
    numBcit () {
      this.meterBata.token = this.$store.state.print.goodsForeNo.token
      this.meterBata.meter_id = this.$route.query.obj.meter_id
      this.$api.waterMeter.meterBchits(this.meterBata).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, 'ewew')
          this.$notify({
            title: '成功',
            message: '更改成功',
            type: 'success'
          })
          this.$router.push('/waterMeterFile')
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 添加的用水类型下拉框
    waterdown () {
      this.$api.waterMeter.waterdowns(this.famlis).then((res) => {
        if (res.data.status === 'success') {
          // console.log(res, '用水类型下拉框')
          this.withdown = res.data.data
          console.log(this.withdown, 'this.withdown')
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
        if (res.data.message === '用户在别处登录') {
          this.$router.push('/', this.fasinull)
        }
      })
    },
    // 账单记录
    Billing () {
      console.log(this.$refs.meting, 'this.$refs.meting')
      this.$refs.cecords.style.color = '#987AFF'
      this.$refs.meting.style.color = '#494949'
      this.$refs.offvale.style.color = '#494949'
      this.secords = true
      this.tabers = false
      this.tabtch = false
    },
    // 抄表按钮
    ers () {
      console.log(this.$refs.meting, 'this.$refs.meting')
      this.$refs.meting.style.color = '#987AFF'
      this.$refs.offvale.style.color = '#494949'
      this.$refs.cecords.style.color = '#494949'
      this.tabers = true
      this.tabtch = false
      this.secords = false
    },
    // 开关阀按钮
    tch () {
      console.log(this.$refs.meting, 'this.$refs.meting')
      this.$refs.offvale.style.color = '#987AFF'
      this.$refs.meting.style.color = '#494949'
      this.$refs.cecords.style.color = '#494949'
      this.tabtch = true
      this.tabers = false
      this.secords = false
    }
  }
}
</script>
<style lang="less" scoped>
// 面包屑
.Gnv {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  font-size: 19px;
  letter-spacing: 1px;
  //   font-family: myFont;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 75px;
    .yai{
      width: 5px;
      height: 17px;
      background-color: #987AFF;
      margin-right: 18px;
    }
    ::v-deep .el-breadcrumb__item{
      margin-left: 0px;
      .el-breadcrumb__inner.is-link{
        font-weight: 100;
        color: #656d92;
  }
    }
  }
}
// 主体外层盒子
.flima{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  // 左边
  .fam1{
    width: 32%;
    // width: 558px;
    // height: 790px;

    // 搜索
    .suso {
      padding: 25px 0px 0px 74px;
      display: flex;
      .el-form {
        display: flex;
        .el-input {
          width: 300px;
        }
        .el-button {
          margin-left: 10px;
          background-color: #744dfe;
          border: 0px;
        }
        .el-form-item{
          margin-bottom: 9px;
          .ili{
          cursor: pointer;
          margin-left: 1057px;
          width: 102px;
          height: 33px;
          border-radius: 4px;
          border: solid 1px #977aff;
          font-size: 22px;
          color: #656d92;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        }
      }
    }

    // 档案详情文字
    .famtext{
      padding: 20px 0px 0px 40px;
      // width: 208px;
      // height: 75px;
      font-family: MiSans-Medium;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 60px;
      letter-spacing: 1px;
      color: #494949;
      // border: 1px solid;
      .famtexts{
        font-family: MiSans-Medium;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #494949;
      }
      .famtextss{
        display: flex;
        font-family: MiSans-Medium;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 1px;
        color: #494949;
        .famdi{
          margin-top: 10px;
          margin-left: 15px;
          width: 198px;
          height: 34px;
          font-size: 18px;
          font-family: PingFangSC-Heavy;
          background-color: #977aff;
          display: flex;
          justify-content: center;
          align-items: center;
          .span{
            display: flex;
            font-family: PingFangSC-Heavy;
            font-weight: bold;
            font-stretch: normal;
            line-height: 9px;
            letter-spacing: 1px;
            color: #474747;
          }
        }
      }
    }

    // 详情信息
    .famation{
      padding: 20px 0px 0px 40px;
      .ation-1{
        margin-bottom: 20px;
        font-family: MiSans-Normal;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 69px;
        letter-spacing: 1px;
        color: #494949;
      }

      .ation-2{
        width: 358px;
      height: 530px;
      border-radius: 8px;
      border: solid 2px #977aff;
      // 主
        .ations{
          margin: 25px 0px 0px 30px;
          // width: 301px;
          // height: 236px;
          font-family: MiSans-Normal;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 1px;
          color: #494949;
          // border: 1px solid;
          .ationsetms{
            display: flex;
            align-items: center;
            .ations-sele{
              .el-select{
                width: 150px;
              }
            }
          }
       }
      //  线
        .ationx{
         margin: 10px 0px 0px 32px;
         width: 278px;
        height: 2px;
        background-color: #cabbff;
       }
      //  注
       .ationxz{
        margin: 10px 0px 0px 32px;
        width: 260px;
        height: 36px;
        font-family: MiSans-Normal;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #7b7b7b;
       }
       // 保存
       .ationxb{
         margin-top: 25px;
         float: right;
         margin-right: 20px;
         .el-button{
           box-shadow: 2px 1px 3px 0px rgba(26, 25, 26, 0.5);
           border-radius: 5px;
           border: solid 1px #744dfe;
           color: #515151;
           i{
             margin-right: 10px;
           }
         }
       }
      }
    }
  }

  // 右边
  .fam2{
    width: 63%;
    // width: 1088px;
    // height: 790px;
    .waiido{
      display: flex;
      justify-content: flex-end;
    .ilo{
      margin-top: 20px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 33px;
      border-radius: 4px;
      border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 20px;
        height: 15px;
        margin-right: 10px;
      }
    }
    }
    .ilos{
      margin-top: 20px;
          cursor: pointer;
            margin-left: 50px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 33px;
            border-radius: 4px;
            border: solid 1px #977aff;
      span{
        font-family: XinPingHeiTC-W2;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #484848;
      }
      img{
        width: 20px;
        height: 15px;
        margin-right: 10px;
      }
    }
    // 里面盒子
    .famont{
      margin: 20px 0px 0px 70px;
      // height: 700px;
      // 按钮切换
      .fambtn{
        padding-left: 20px;
        font-family: MiSans-Medium;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #494949;
      }
      // table数据
      .tab{
        padding: 30px 0px 0px 0px;
    }
    }
  }
}
@media (min-width: 768px) {
    .fuiow{
      display: none;
    }
  }
  // 移动端
  @media (max-width: 768px) {
    .Gnv{
      display: none;
    }
    .flima{
      .fam1{
        width: 100%;
        .famtext{
          padding: 0px 0px 0px 10px;
        }
        .famation{
          padding: 0px 0px 0px 10px;
        }
      }
      .fam2{
      width: 100%;
      .waiido{
        display: none;
      }
      .famont{
        margin: 0px;
        padding: 20px 10px;
        .tab{
          padding: 10px 0px;
        }
      }
    }
    }
    .famtexts{
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      .fuiow{
        margin-top: 10px;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #977aff;
      }
    }
    }
</style>
